import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container } from 'reactstrap'
import Swal from 'sweetalert2';
import { APP_NAME } from '../../../../helpers/Constants';
import { DeleteOutline, Edit,FilterList } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../helpers/MaterialIcons';
import { useDispatch, useSelector } from 'react-redux';

import LockIcon from '@material-ui/icons/Lock';
import Unlock from "@material-ui/icons/LockOpen"
import axios from 'axios';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
import Error from '../../../../helpers/Error';
// import Moments from '../../../../helpers/Moment';
// import moment from 'moment';
import { AddAccessGroup, DeleteAccessGroup, EditAccessGroup } from '../../../../store/accessGroups';
import AddAccessGroups from './AddAccessGroup';
import EditAccessGroups from './EditAccessGroup';


export default function AcessGroups () {
   
    const [data,setData] = useState([])
   
    const [addModalShow,setAddModalShow] = useState(false)
    const [editModalShow,setEditModalShow] = useState(false)
    // const [addTimingModalShow,setAddTimingModalShow] = useState(false)
    const [itemToBeEdited,setItemToBeEdited] = useState(null)
    const [loader,setLoader] = useState(true)
    const AcessGroups=useSelector((state) => state.AccessGroupSlice.accessGroup)
    const dispatch = useDispatch()
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "Access Groups", link: "#" },
        ],
      });

      const addModalToggle = () => {
        setAddModalShow(!addModalShow)
      }
      const editModalToggle = () => {
        setEditModalShow(!editModalShow)
      }
  
    useEffect(() => {
      const abortController = new AbortController();
      const url='/workspace_app/access_group'
      axios.get(url)
      .then((response) => {
        dispatch(AddAccessGroup(response.data))
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
      })

      // dispatch(AddSubscriber([{id:1,operator_id:8765,company_name:'Noux',emp_id:1001,extn_no:593,first_name:"David",last_name:"Jacobs",designation:"Officer",email:"david@email.com",phone_no:"0501231000",User_type:"Admin",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"},{id:2,operator_id:2678,emp_id:1002,company_name:'Element 8',extn_no:574,first_name:"Akhil",last_name:"Prem",designation:"Accountant",email:"Akhil@gmail.com",phone_no:"0501231001",User_type:"Admin",address:"789 Fort Myres,Monreo Avenue,Florida",status:"Active"},{id:3,operator_id:3567,emp_id:1003,company_name:'Rad',extn_no:567,first_name:"Sharon",last_name:"VArghese",designation:"Chief Officer",email:"Sharon@email.com",phone_no:"05012310021",User_type:"User",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"}]))
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Access Groups", link: "#" },
              ],
            }  
          )
          return()=>{
            abortController.abort();
          }

           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
    if(AcessGroups !== null){
      const temp=AcessGroups.map((data) => ({...data,
       scheduler_name:data?.scheduler?.scheduler_name ? data.scheduler.scheduler_name : 'No Data'
      }))
      setData(temp)
    }
    }, [AcessGroups])
    

    const columns =
  [
   
    
    { title:'Group Name', field:'group_name' ,
   
  },
    { title:'Scheduler', field:'scheduler_name',
   
  },
    { title: "Status", field: "status",}
  
  ]

      // for handling delete activate deactivate
 const UpdateHandler = (id,method) =>{

  if(method==="Deleted"){
  // console.log(id)
  const url=`/workspace_app/access_group/${id}`
  axios.patch(url,{status:method})
        .then((response) =>{
          SuccessAlert("Access Group Deleted Successfully!")
          dispatch(DeleteAccessGroup(id))
        })
        .catch((error) =>{
          const err_msg=Error(error)
          ErrorAlert(err_msg)
         
        })
      }
      else{
        if(method === 'Disabled' || method === 'Active'){
        const url=`/workspace_app/access_group/${id}`
        axios.patch(url,{status:method})
        .then((response) =>{
          SuccessAlert("Access Group Updated Successfully!")
          dispatch(EditAccessGroup(response.data))
        })
        .catch((error) =>{
          const err_msg=Error(error)
          ErrorAlert(err_msg)
         
        })
      }
     
      }
 }

    
    
  return (
    <React.Fragment>

         {addModalShow &&
        <AddAccessGroups
        isOpen={addModalShow}
        toggle={addModalToggle}
        title={'Add Access Group'}
        />
        }

        {editModalShow &&
        <EditAccessGroups
        isOpen={editModalShow}
        toggle={editModalToggle}
        title={'Add Timing'}
        item={itemToBeEdited}
        />
        }

          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Access Groups"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
               <div
                className="disappearing-button-container-page-top-right" style={{marginRight:"-80px"}}>
                
                <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={addModalToggle}
                >
                  Add Access Group
                </Button>
                  </div>
               
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={columns}
                        data={data}
                        isLoading={loader}

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },

                          
                          // {
                          //   icon: VisibilityOutlinedIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                          // },
                         
                          {
                            icon: Edit,
                            tooltip: 'Edit AccessGroup',
                            onClick: (event, rowData) => {
                              // console.log(rowData)
                              // setEditClientModalShow(true)
                              setItemToBeEdited(rowData)
                              editModalToggle()
                            }
                          },
                         
                          rowData => ({
                            icon: Unlock,
                            tooltip: 'Enable AccessGroup',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Enable it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,"Active")
                                  // deleteClientData(rowData?.id)

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden: rowData.status === "Active"
                          }),
                          rowData => ({
                            icon: LockIcon,
                            tooltip: 'Disable AccessGroup',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Disable it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,"Disabled")
                                  // deleteClientData(rowData?.id)

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden: rowData.status === "Disabled"
                          }),

                          {
                            icon: DeleteOutline,
                            tooltip: 'Delete AccessGroup',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: "You won't be able to revert this!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, delete it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,'Deleted')

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                           
                          },

                        ]}
                        options={{
                          exportFileName:'user_list',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
                
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

