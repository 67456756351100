import React, { useEffect, useState } from 'react'
import Modals from '../../../../components/Modal';
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Row, Spinner} from 'reactstrap';
// import MaterialTable from 'material-table';
// import { tableIcons } from '../../../../helpers/MaterialIcons';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import { IconButton } from '@material-ui/core';
// import Tooltip from '@mui/material/Tooltip';
// import { authAxios } from '../../../../../axiosInstance';
// import GraphLoader from '../../../../../components/GraphLoader/GraphLoader';
// import { Toastr } from '../../../../helpers/Alert';

// import { useDispatch } from 'react-redux';
// import { Schedule } from '@material-ui/icons';
import axios from 'axios';
import Error from '../../../../helpers/Error';
import { Toastr } from '../../../../helpers/Alert';
// import { editCategory, editEntityCategory } from '../../../../../store/entity';
// import axios from 'axios';
// import Error from '../../../../helpers/Error';



function AddTiming(props) {
     const Days = ['Monday','Tuesday','Wenesday','Thursday','Friday','Saturday','Sunday'];
    const [scheduler,setScheduler] = useState([])
    // const dispatch = useDispatch()
    // const [datas,setData] = useState([{Monday:[{'shift1':{from:'',to:''}},{'shift2':{from:'',to:''}}]},
    //                                   {Tuesday:[{'shift1':{from:'',to:''}},{'shift2':{from:'',to:''}}]},
    //                                   {Wensday:[{'shift1':{from:'',to:''}},{'shift2':{from:'',to:''}}]},
    //                                   {Thurday:[{'shift1':{from:'',to:''}},{'shift2':{from:'',to:''}}]}, 
    //                                   {Friday:[{'shift1':{from:'',to:''}},{'shift2':{from:'',to:''}}]},
    //                                   {Saturday:[{'shift1':{from:'',to:''}},{'shift2':{from:'',to:''}}]},
    //                                   {Sunday:[{'shift1':{from:'',to:''}},{'shift2':{from:'',to:''}}]}]
    //                                   )
    const [loader,setLoader]=useState(true)
    // const [testData,setTestData]=useState()
    
    const [waitingForAxios,setWaitingForAxios]=useState(false)
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm();

    useEffect(() => {
      const url="/workspace_app/scheduler"
      
      axios.get(url)
        .then((response) => {
          setScheduler(response.data)
          setLoader(false)
        })
        .catch((error) =>{
          setLoader(false)
        })
    }, [])
    

    const [checkedState, setCheckedState] = useState(
      new Array(Days.length).fill(true)
    );
  
      // useEffect(() => {
      //  //console.log(datas)
      // }, [datas])
      
    const handleOnChange = (position) => {

      // const shiftstart = 'shift1start_time'+position
      

      reset({shiftstart : ''})
      // reset({
      //   scheduler_name: ''
      // })
      // reset({
      //   {"to" + postion} : '' ,
      // })
      const updatedCheckedState = checkedState.map((item, index) =>
        index === position ? !item : item
      );
  
      setCheckedState(updatedCheckedState);
    }
    
    // useEffect(() => {
    //   console.log("test",testData)
    // }, [testData])
    

   

      // const handleOnInputChange = (value, type,day,which,shift) => {

      //   // const days=day
      //   // datas.days.map((data) => console.log(data))
      
      //   // const temp=[]
      //   // console.log('typ',type)
      // //   if(which=='from'){
      // //   const key=shift
      // // var obj  = {}
      // // obj[key] = {from:value}
          
      // //   }
      // //   else{
      // //     const key=shift
      // // var obj  = {}
      // // obj[key] = {to:value}
      // //   }
      // // // temp.push(obj)

      // // // setTestData((state) => ({...state,obj}))
      
      // //   setData({
      // //     ...datas,
      // //     [day]:obj
      // //   }) 
      //   // let week_day=''
      //   // let start_time=''
      //   // let end_time=''
      //   let temp=''
      //   const arr=[]
      //   switch (day) {
      //     case 'Monday':
      //       temp=datas[0]
      //       // console.log('wrked')
      //        if(shift === 'shift1'){
      //         if(which=== 'from'){
      //          const test=temp.Monday.map((data) => 
      //           // data.shift1 ?:{...data.shift1,from:value}
      //           data.shift1 ? {shift1:{...data.shift1,from:value}} : data

      //           ) 
      //           setData({
      //                 ...datas,
      //                 [0]:{Monday:test}
      //               }) 
      //         }
      //         else{
      //           const test=temp.Monday.map((data) => 
      //           // data.shift1 ?:{...data.shift1,from:value}
      //           data.shift1 ? {shift1:{...data.shift1,to:value}} : data

      //           ) 
      //             setData({
      //                   ...datas,
      //                   [0]:{Monday:test}
      //                 }) 

      //         }
      //        }
      //        else{
      //         if(which=== 'from'){
      //           const test=temp.Monday.map((data) => 
      //            // data.shift1 ?:{...data.shift1,from:value}
      //            data.shift2 ? {shift2:{...data.shift2,from:value}} : data
 
      //            ) 
      //            setData({
      //                  ...datas,
      //                  [0]:{Monday:test}
      //                }) 
      //          }
      //          else{
      //            const test=temp.Monday.map((data) => 
      //            // data.shift1 ?:{...data.shift1,from:value}
      //            data.shift2 ? {shift2:{...data.shift2,to:value}} : data
 
      //            ) 
      //              setData({
      //                    ...datas,
      //                    [0]:{Monday:test}
      //                  }) 
 
      //          }
      //        }
      //       break;
      //     case 'Tuesday':
      //      // console.log('wrked t')
      //       break;
      //     case 'Wenesday':
      //       //console.log('wrked W')
      //       break;
      //     case 'Thursday':
      //       //console.log('wrked THu')
      //       break;
      //     case 'Friday':
      //       //console.log('wrked FRi')
      //       break;
      //     case 'Saturday':
      //       //console.log('wrked Sat')
      //       break;
      //     case 'Sunday':
      //       //console.log('wrked SUN')
      //   }

      //   // console.log(test)
      // }

    const onSubmit = (data) =>{

      // console.log(d)
      setWaitingForAxios(true)
      
      const url="/workspace_app/timing"
      // axios.post(url,{timing_data:[datas[0]],scheduler:data.scheduler})
      axios.post(url)
        .then((response) =>{
          
          Toastr("success","New Schedule Added Succesfully")
          //console.log(response.data)
          setWaitingForAxios(false)
        })
        .catch((error)=>{
          const err_msg=Error(error)
          Toastr("error",err_msg)
          setWaitingForAxios(false)
        }) 
    }

  return (
    <div>
      
      <Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} size={'xl'} loader={loader}>
        <Form onSubmit={handleSubmit(onSubmit)} className="form-horizontal text-center pt-4">
              
<FormGroup row style={{ marginBottom: "20px",marginLeft:'22px' }}>
    <Label for="Scheduler" sm={2}>
        Scheduler
    </Label>
    <Col sm={9}>
    <select className="form-control"
                  {...register("scheduler", {
                    // required: true,
                    
                  })}>
 <option value="">      --Select Scheduler--</option>
                  {scheduler.map((item,index) => {
                    return <option key={index} value={item.id}>{item.scheduler_name}</option>
                  })}
    </select>
    
        {errors.scheduler?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
        <div className='timing__Heading'>
        <h5>SHIFT 1</h5>
        <h5 style={{marginRight:'-150px'}}>SHIFT 2</h5>
        </div>
        {Days.map((data,index) => 
<FormGroup row style={{ marginBottom: "10px" }}>
<Row>
<Col style={{marginTop:'8px'}}>

<input
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name={data}
                    value={data}
                    checked={checkedState[index]}
                    onChange={() => handleOnChange(index)}
                  />
                        <label htmlFor="acceptTerms" className="form-check-label" style={{marginLeft:'6px'}}>{data}</label>
                        {errors.acceptTerms?.type === "required" && (
                              <span>Please Click The CheckBox</span>
                            )}
                  
                  </Col>
      
                  <Col>
                 
        <input
            placeholder="Google Location"
            id={`custom-input-${index}`}
            type="time"
            disabled={!checkedState[index]}
            className="form-control"
            // onChange={(event) => handleOnInputChange(event.target.value,event.target.id,data,'from','shift1')}

            {...register(`${data}-shift1-start_time`, {
              // required: true,
              // onChange:(event) => handleOnInputChange(event.target.value,event.target.id,data,'from','shift1')
          })}
        />
      
        </Col>
       
        <Col  >
        
        <input
            placeholder="Co-ordinates"
            id={`custom-input-${index}`}
            type="time"
            disabled={!checkedState[index]}
            className="form-control"
            // onChange={(event) => handleOnInputChange(event.target.value,event.target.id,data,'to','shift1')}

            {...register(`${data}-shift1-end_time`, {
              // required: true,
              // onChange:(event) => handleOnInputChange(event.target.value,event.target.id,data,'to','shift1')


          })}
        
        />
      
        </Col>
        <Col>
        <input
            placeholder="Google Location"
            id={`custom-input-${index}`}
            type="time"
            disabled={!checkedState[index]}
            className="form-control"
            // onChange={(event) => handleOnInputChange(event.target.value,event.target.id,data,'from','shift2')}

            {...register(`${data}-shift2-start_time`, {
              // required: true,
              // onChange:(event) => handleOnInputChange(event.target.value,event.target.id,data,'from','shift2')
          })}

        />
      
        </Col>
       
        <Col  >
       
        <input
            placeholder="Co-ordinates"
            id={`custom-input-${index}`}
            type="time"
            disabled={!checkedState[index]}
            className="form-control"
            // onChange={(event) => handleOnInputChange(event.target.value,event.target.id,data,'to','shift2')}

            {...register(`${data}-shift2-endtime`, {
              // required: true,
              // onChange:(event) => handleOnInputChange(event.target.value,event.target.id,data,'to','shift2')
          })}
        />
      
        </Col>
      </Row>
</FormGroup>
        )}
<ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>


</ModalFooter>
</Form>
        </Modals></div>
  )
}

export default AddTiming