import React, { useState } from 'react'
import Modals from '../../../../components/Modal'
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
// import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
// import Error from '../../../../helpers/Error';
import axios from 'axios';
import Error from '../../../../helpers/Error';
import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
import { AddNewSubscriberStaff } from '../../../../store/subscriber';


function AddCompanyStaff(props) {

    const profile = useSelector((state) => state.authSlice.profile)

    const [waitingForAxios,setwaitingForAxios] = useState(false)
    // const [company,setCompany] = useState([])
    // const [loader,setLoader] = useState(true)
    const dispatch=useDispatch();
    const {
        register,
        handleSubmit,
       
        formState: { errors },
      } = useForm();
      
    const onSubmit = (data) =>{
        // console.log(data)
        setwaitingForAxios(true)
        
        const url='/workspace_app/staffs'

        axios.post(url,{...data,company:profile.link_id})
        .then((response) =>{
            // let msg=response.data.message
            setwaitingForAxios(false)
            dispatch(AddNewSubscriberStaff(response.data))
            SuccessAlert('Staff Added Succesfully')
            props.toggle();
        })
        .catch((error) => {
            setwaitingForAxios(false)
            if(error.response?.data?.email[0]){
                ErrorAlert(error.response?.data?.email[0])

            }
           
        else{
                let error_msg=Error(error)
                ErrorAlert(error_msg)
       }
        })
       
    }

  return (
    <div><Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>


        <FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Reg No" sm={3}>
        Reg No <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Reg No"
            type="text"
            className="form-control"
            {...register("reg_no", {
                required: true,
            })}
        />
        {errors.reg_no?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="First Name" sm={3}>
        First Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="First Name"
            type="text"
            className="form-control"
            {...register("first_name", {
                required: true,
            })}
        />
        {errors.first_name?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Last Name" sm={3}>
        Last Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Last Name"
            type="text"
            className="form-control"
            {...register("last_name", {
                required: true,
            })}
        />
        {errors.last_name?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Contact Number" sm={3}>
        Contact Number <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Contact Number"
            type="text"
            className="form-control"
            {...register("contact_number", {
                required: true,
            })}
        />
        {errors.contact_number?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Email Id" sm={3}>
        Email Id <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Email Id"
            type="text"
            className="form-control"
            {...register("email", {
                required: true,
            })}
        />
        {errors.email?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>


</ModalFooter>
</Form>
        </Modals></div>
  )
}

export default AddCompanyStaff