import React from 'react';
import errorImg from "../src/assets/images/error-img.png";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <div className="text-center" style={{position:"relative",top:"150px"}}>
     
      <div className="text-center my-5">
        <h1 className="fw-bold text-error">
          ERR{" "}
          <span className="error-text">
            O<img src={errorImg} alt="" className="error-img" />
          </span>{" "}
          R!
        </h1>
        <h3 className="text-uppercase" style={{fontSize:"26px"}}>Loading failed! Please reload.</h3>
        </div>
       
        </div>
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
