import React from "react";

// import Home from "../containers/Pages/Home";
import Dashboard from "../containers/Pages/Dashboard";
import SubscriptionPlans from "../containers/Pages/Masterdata/SubscriptionPlan/SubscriptionPlans";
import DoorAccessReport from "../containers/Reports/DoorAccessDetails";
import LoginDetails from '../containers/Reports/LoginDetails'
import Settings from "../containers/Pages/Settings";
import ClientUsers from "../containers/Pages/UserManagement/HiliteUsers/ClientUsers";
import Subscribers from "../containers/Pages/UserManagement/Subscribers/AllSubscribers/Subscribers";
import Login from "../containers/Pages/Authentication/Login";
// import Staffs from "../containers/Pages/UserManagement/Subscribers/SubscriberStaffs/SubscriberStaffs";
import SubscribersWorkStations from "../containers/Pages/UserManagement/Subscribers/SubscriberWorkStations/SubscriberWorkStations";
import Register from "../containers/Pages/Registration/Register";
import ForgetPassword from "../containers/Pages/Authentication/ForgetPassword";
import SubscriberDetail from "../containers/Pages/UserManagement/Subscribers/AllSubscribers/SubscriberDetail/SubscriberDetail";
import WorkStations from "../containers/Pages/Masterdata/WorkStation/WorkStation";
import Cabin from "../containers/Pages/Masterdata/Rooms/Cabin";
import SubscriberStaffs from "../containers/Pages/UserManagement/Subscribers/SubscriberStaffs/SubscriberStaffs";
import Staff from "../containers/Pages/Company/StaffManagment/Staffs";
import ManageRfidCard from "../containers/Pages/RfidCard/ManageRfidCard";
import ContactUs from '../containers/Pages/FooterLinks/ContactUs'
import { ServicePricing } from "../containers/Pages/FooterLinks/ServicePricing";
import AboutUs from "../containers/Pages/FooterLinks/AboutUs";
import { TermsAndConditions } from "../containers/Pages/FooterLinks/TermsAndConditions";
import { PrivacyPolicy } from "../containers/Pages/FooterLinks/PrivacyPolicy";
import RefundPolicy from "../containers/Pages/FooterLinks/RefundPolicy";
import Doors from "../containers/Pages/DoorAccessManagment/Doors/Door";
import Timing from "../containers/Pages/DoorAccessManagment/Timing/Timing";
import AcessGroups from "../containers/Pages/DoorAccessManagment/AccessGroups/AccessGroups";
import Scheduler from "../containers/Pages/DoorAccessManagment/Scheduler/Scheduler";
import SchedulerDetails from "../containers/Pages/DoorAccessManagment/Scheduler/SchedulerDetails";
import PaymentSuccess from "../containers/Pages/Payment/PaymentSuccess";
import { PaymentFailed } from "../containers/Pages/Payment/PaymentFailed";
 import Payment from "../containers/Pages/Payment/Payment";
import Invoices from "../containers/Pages/Invoices/InvoiceListForHilite";
import InvoicesCompany from "../containers/Pages/Invoices/InvoiceListForCompany";
import SubscriberUsers from "../containers/Pages/UserManagement/Subscribers/SubscriberUsers";




const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard/> ,allowedTo: ['Hilite','Subscriber']},
  { path: "/clientusers", component: <ClientUsers/> ,allowedTo: ['Hilite']},
  { path: "/subscribers", component: <Subscribers/> ,allowedTo: ['Hilite']},
  { path: "/logindetails", component: <LoginDetails/> ,allowedTo: ['Hilite']},
  { path: "/dooraccessdetails", component: <DoorAccessReport/> ,allowedTo: ['Hilite']},
  { path: "/workstations", component: <WorkStations/> ,allowedTo: ['Hilite']},
  { path: "/cabin", component: <Cabin/> ,allowedTo: ['Hilite']},
  { path: "/subscriptionplans", component: <SubscriptionPlans/> ,allowedTo: ['Hilite']},
  { path: "/managerfidcard", component: <ManageRfidCard/> ,allowedTo: ['Hilite']},
  { path: "/settings", component: <Settings/> ,allowedTo: ['Hilite','Subscriber']},
  { path: "/subscriberstaffs", component: <SubscriberStaffs/> ,allowedTo: ['Hilite']},
  { path: "/subscriberworkstations", component: <SubscribersWorkStations/>,allowedTo: ['Hilite'] },
  // { path: "/subscriberdetails/:id/:messege", component: <SubscriberDetail/> },
  { path: "/subscriberdetails", component: <SubscriberDetail/> ,allowedTo: ['Subscriber']},
  { path: "/subscriberdetails/:id", component: <SubscriberDetail/> ,allowedTo: ['Hilite']},
  { path: "/staffs", component: <Staff/> ,allowedTo: ['Subscriber']},
  { path: "/doors", component: <Doors/> ,allowedTo: ['Hilite']},
  { path: "/timing", component: <Timing/> ,allowedTo: ['Hilite']},
  { path: "/accessgroups", component: <AcessGroups/> ,allowedTo: ['Hilite']},
  { path: "/scheduler", component: <Scheduler/> ,allowedTo: ['Hilite']},
  { path: "/schedulerdetails/:id", component: <SchedulerDetails/> ,allowedTo: ['Hilite']},
  { path: "/payment/success", component: <PaymentSuccess/> ,allowedTo: ['Hilite','Subscriber']},
  { path: "/payment/failed", component: <PaymentFailed/> ,allowedTo: ['Hilite','Subscriber']},
  { path: "/invoicelist", component: <Invoices/> ,allowedTo: ['Hilite']},
  { path: "/invoices", component: <InvoicesCompany/> ,allowedTo: ['Subscriber']},
  { path: "/payment", component: <Payment/> ,allowedTo: ['Hilite']},
  { path: "/subscriberusers", component: <SubscriberUsers/> ,allowedTo: ['Hilite']},








];

const publicRoutes = [

  { path: "/", component: <Login/> },
  { path: "/login", component: <Login/> },
  { path: "/register/:string", component: <Register/> },
  { path: "/forgotpassword", component: <ForgetPassword />},
  { path: "/contactus", component: <ContactUs />},
  { path: "/aboutus", component: <AboutUs />},
  { path: "/termsandconditions", component: <TermsAndConditions/>},
  { path: "/servicepricing", component: <ServicePricing/>},
  { path: "/privacypolicy", component: <PrivacyPolicy />},
  { path: "/refundpolicy", component: <RefundPolicy />},
  
  


];

export { authProtectedRoutes, publicRoutes };
