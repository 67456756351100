const baseURLFunc = () => {
    let url=window.location.origin.split(':3000')[0]
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return url+':8000'
    } else {
        return url
    }
}

export const baseURL = baseURLFunc()
// export const baseURL = 'http://65.108.58.249:8000/'