import React, { useEffect, useState } from 'react'
import Modals from '../../../../components/Modal'
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch } from 'react-redux';
import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
import Error from '../../../../helpers/Error';
import axios from 'axios';
import { EditClientUser } from '../../../../store/user';

function EditUser(props) {

    const [waitingForAxios,setwaitingForAxios] = useState(false)
    // const [designation,setDesignation] = useState([])
    // const [loader,setLoader] = useState(true)
    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();

      useEffect(() => {
        
        const temp={...props.item}
        reset(temp)
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.item])

    const onSubmit = (data) =>{

       
        let formData = new FormData(); //formdata object
      formData.append("user_type", data.user_type);
      formData.append("last_name", data.last_name);
      formData.append("first_name", data.first_name);
      formData.append("address", data.address);
      formData.append("contact_number", data.contact_number);

      formData.append("email", data.email);
        setwaitingForAxios(true)
        
        const url=`/users_api/userdetails/${props.item.id}`

        axios.patch(url,formData)
        .then((response) =>{
            // let msg=response.data.message
            setwaitingForAxios(false)
            dispatch(EditClientUser(response.data))
            SuccessAlert('User Edited Succesfully')
            props.toggle();
        })
        .catch((error) => {
            setwaitingForAxios(false)
            if(error.response.data.message){
                ErrorAlert(error.response.data.message)  
            }
           else{
            let error_msg=Error(error)
            ErrorAlert(error_msg)
           }
        })
       
    }

  return (
    <div><Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="First Name" sm={3}>
        First Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="First Name"
            type="text"
            className="form-control"
            {...register("first_name", {
                required: true,
            })}
        />
        {errors.first_name?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Last Name" sm={3}>
        Last Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Last Name"
            type="text"
            className="form-control"
            {...register("last_name", {
                required: true,
            })}
        />
        {errors.last_name?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Address" sm={3}>
        Address <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Address"
            type="text"
            className="form-control"
            {...register("address", {
                required: true,
            })}
        />
        {errors.address?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Email Id" sm={3}>
        Email Id <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Email Id"
            type="text"
            className="form-control"
            {...register("email", {
                required: true,
            })}
        />
        {errors.email?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Phone No" sm={3}>
        Phone No <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Phone No"
            type="text"
            className="form-control"
            {...register("contact_number", {
                required: true,
            })}
        />
        {errors.phoneno?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="User Type" sm={3}>
        User Type <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
    <select className="form-control"
                  {...register("user_type", {
                    required: true,
                  })}>
     <option value="">-- Select User Type --</option>
                  <option value="Admin">Admin</option>
                  <option value="User">User</option>
                  
    </select>
        {errors.area?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
    
</FormGroup>

<ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>


</ModalFooter>
</Form>
        </Modals></div>
  )
}

export default EditUser