
import React, { useState, useEffect } from "react";
import { Row, Col, Spinner, Button, Container, Label } from "reactstrap";

// Redux

import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
// availity-reactstrap-validation
// import { AvForm, AvField } from "availity-reactstrap-validation";

// action


// import images
import logodark from "../../../assets/images/THE-WORKBOOK-LOGO.png";
import publicAxios from "../../../axiosInstance";
// import withReactContent from 'sweetalert2-react-content';
// import Swal from 'sweetalert2'
import { ErrorAlert, SuccessAlert, Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error"; 
import { APP_NAME } from "../../../helpers/Constants"; 
export default function ForgetPassword() {
let navigateto=useNavigate();

    const [waitingForEmailAxios, setWaitingForEmailAxios] = useState(false);
    const [waitingForOTPAxios, setWaitingForOTPAxios] = useState(false);
    const [waitingForNewPasswordAxios, setWaitingForNewPasswordAxios] = useState(false);
    const [navigate, setNavigate] = useState({ otp: false, password: false });
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [allClear, setallClear] = useState(false);
    const [registrationkey, setRegistrationkey] = useState(null);
    const [errorMessage, seterrorMessage] = useState(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();

    useEffect(() => {
        reset({password:"", password1:""})

          // eslint-disable-next-line react-hooks/exhaustive-deps
      },[navigate.password])

    useEffect(() => {
        const re = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$`);
        const isOk = re.test(newPassword);

        if (isOk) {
            seterrorMessage("Passwords should match");
            if (newPassword === confirmNewPassword) {
                seterrorMessage(null);

                setallClear(true);

            } else {
                setallClear(false);
            }
        } else {
            seterrorMessage(
                "The password should contain minimum 8 characters with a mix of alphanumeric,atleast 1 Uppercase letter and special characters"
                );
        }
    }, [newPassword,confirmNewPassword]);
    // email verification handler
    const forgetPasswordSubmitHandler = (data) => {
        reset({
            otp: ''

        })
        // setNavigate({ otp: true, password: false })
        setWaitingForEmailAxios(true)
        // console.log(data)
        const url = 'users_api/send_forgot_password_otp';
        publicAxios.post(url, data).then((res) => {
            setNavigate({ otp: true, password: false })
            setWaitingForEmailAxios(false)
            SuccessAlert("Check your email for the OTP!")
        }).catch((error) => {
            setWaitingForEmailAxios(false)
            const error_msg = Error(error)
            ErrorAlert(error_msg)
        })
    }
    // OTP verification handler
    const sentOtpHandler = (data) => {
        setWaitingForOTPAxios(true)
        // console.log(data)
        const url = 'users_api/validate_forgot_password_otp';
        publicAxios.post(url, data).then((res) => {
            // console.log('key', res.data.registration_key)
            setRegistrationkey(res.data.registration_key)
            setNavigate({ otp: true, password: true })
            setWaitingForOTPAxios(false)
            SuccessAlert("OTP Verified!!!")

        }).catch((error) => {
            setWaitingForOTPAxios(false)
            const error_msg = Error(error)
            ErrorAlert(error_msg)
        })
    }
    const passwordSubmitHandler = () => {
        setWaitingForNewPasswordAxios(true);
        const data = {
            registration_key: registrationkey,
            password: newPassword,
        }
        // console.log(data)
        publicAxios
            .post("users_api/update_new_password", data)
            .then(() => {
                setWaitingForNewPasswordAxios(false);
                navigateto('/login');
                Toastr("success","Password has been updated Successfully!")
            })
            .catch((error) => {
                setWaitingForNewPasswordAxios(false);
                const error_msg = Error(error)
                ErrorAlert(error_msg)
            });
    }
    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                      <Col lg={9}>
                                            <div>
                                                {navigate.otp && !(navigate.password) ? <>
                                                    <>
                                                        <div className="text-center">
                                                            <div>
                                                                <Link to="/" className="logo"><img src={logodark} height="18" alt="logo" /></Link>
                                                            </div>

                                                            <h4 className="font-size-18 mt-5">Email Verification</h4>
                                                            <p className="text-muted">Please check your email and enter the code here.</p>
                                                        </div>

                                                        <div className="p-2 mt-5">
                                                            {/* {this.props.forgetError && this.props.forgetError ?
                                                        <Alert color="danger" className="mb-4">{this.props.forgetError}</Alert> : null}
                                                    {
                                                        this.props.message ?
                                                            <Alert color="success" className="mb-4">{this.props.message}</Alert> : null
                                                    } */}

                                                            <form
                                                                className="form-horizontal"
                                                                onSubmit={handleSubmit(sentOtpHandler)}
                                                            >
                                                                <div className="auth-form-group-custom mb-4">
                                                                    <i className="ri-mail-line auti-custom-input-icon"></i>
                                                                    <Label htmlFor="useremail">Please Enter the Otp here </Label>
                                                                    <input
                                                                        className="form-control"
                                                                        placeholder="Enter the Otp"
                                                                        {...register("otp", {
                                                                            required: true,

                                                                        })}
                                                                    />


                                                                </div>
                                                                <div className="mt-4 text-center">
                                                                    {/* <Button color="primary" className="w-md waves-effect waves-light" type="submit">{this.props.loading ? "Loading..." : "Reset"}</Button> */}
                                                                    <Button color="primary" className="w-md waves-effect waves-light" type="submit">    {waitingForOTPAxios ? (
                                                                        <Spinner animation="grow" size="sm" />
                                                                    ) : (
                                                                        "Submit"
                                                                    )}</Button>

                                                                </div>
                                                            </form>

                                                        </div></>
                                                </> : navigate.otp && navigate.password ?
                                                    <>
                                                        <div className="text-center">
                                                            <div>
                                                                <Link to="/" className="logo"><img src={logodark} height="18" alt="logo" /></Link>
                                                            </div>

                                                            <h4 className="font-size-18 mt-4">Reset Password</h4>
                                                            <p className="text-muted">Reset your password to Workbook.</p>
                                                        </div>

                                                        <div className="p-2 mt-5">
                                                            {/* {this.props.forgetError && this.props.forgetError ?
<Alert color="danger" className="mb-4">{this.props.forgetError}</Alert> : null}
{
this.props.message ?
<Alert color="success" className="mb-4">{this.props.message}</Alert> : null
} */}

                                                            <form
                                                                className="form-horizontal"
                                                                onSubmit={handleSubmit(passwordSubmitHandler)}
                                                            >
                                                                <div className="auth-form-group-custom mb-4">
                                                                    <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                    <Label htmlFor="New Password">New Password</Label>
                                                                    <input
                                                                        className="form-control"
                                                                        disabled={waitingForNewPasswordAxios}
                                                                        placeholder="Enter New Password"
                                                                        type="password"
                                                                        {...register("password", {
                                                                            required: true,
                                                                            onChange : (event) => {setNewPassword(event.target.value)}
                                                                        })}
                                                                    />
                                                                    

                                                                </div>
                                                                <div className="auth-form-group-custom mb-4">
                                                                    <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                    <Label htmlFor="Confirm password">Confirm password</Label>
                                                                    <input
                                                                        className="form-control"
                                                                        disabled={waitingForNewPasswordAxios}
                                                                        placeholder="Confirm Password"
                                                                        type="password"
                                                                        {...register("password2", {
                                                                            required: true,
                                                                            onChange : (event) => {setConfirmNewPassword(event.target.value)}
                                                                        })}
                                                                    />
                                                                   
                                                                    </div>
                                                                    <span style={{ color: "red",display:"block"}}>{errorMessage ? errorMessage : null}</span>

                                                                
                                                                
                                                                <div className="mt-4 text-center">
                                                                    {/* <Button color="primary" className="w-md waves-effect waves-light" type="submit">{this.props.loading ? "Loading..." : "Reset"}</Button> */}
                                                                    <Button color="primary" className="w-md waves-effect waves-light" type="submit" disabled={!allClear}>    {waitingForNewPasswordAxios ? (
                                                                        <Spinner animation="grow" size="sm" />
                                                                    ) : (
                                                                        "Submit"
                                                                    )}</Button>

                                                                </div>
                                                            </form>

                                                        </div>
                                                    </> :
                                                    <>
                                                        <div className="text-center">
                                                            <div>
                                                                <Link to="/" className="logo"><img src={logodark} height="18" alt="logo" /></Link>
                                                            </div>

                                                            <h4 className="font-size-18 mt-4">Reset Password</h4>
                                                            <p className="text-muted">Reset your password to Workbook.</p>
                                                        </div>

                                                        <div className="p-2 mt-5">
                                                            {/* {this.props.forgetError && this.props.forgetError ?
                                               <Alert color="danger" className="mb-4">{this.props.forgetError}</Alert> : null}
                                           {
                                               this.props.message ?
                                                   <Alert color="success" className="mb-4">{this.props.message}</Alert> : null
                                           } */}

                                                            <form
                                                                className="form-horizontal"
                                                                onSubmit={handleSubmit(forgetPasswordSubmitHandler)}
                                                            >
                                                                <div className="auth-form-group-custom mb-4">
                                                                    <i className="ri-mail-line auti-custom-input-icon"></i>
                                                                    <Label htmlFor="useremail">Email</Label>
                                                                    <input
                                                                        className="form-control"
                                                                        placeholder="Enter username"
                                                                        {...register("email", {
                                                                            required: true,
                                                                            pattern:
                                                                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                        })}
                                                                    />
                                                                    {errors.email?.type === "required" && (
                                                                        <span
                                                                            style={{
                                                                                color: "#bf3d48",
                                                                            }}
                                                                        >
                                                                            This field is required
                                                                        </span>
                                                                    )}
                                                                    {errors.email?.type === "pattern" && (
                                                                        <span
                                                                            style={{
                                                                                color: "#bf3d48",
                                                                            }}
                                                                        >
                                                                            Please provide a valid email address
                                                                        </span>
                                                                    )}

                                                                </div>
                                                                <div className="mt-4 text-center">
                                                                    {/* <Button color="primary" className="w-md waves-effect waves-light" type="submit">{this.props.loading ? "Loading..." : "Reset"}</Button> */}
                                                                    <Button className="w-md waves-effect waves-light" type="submit">    {waitingForEmailAxios ? (
                                                                        <Spinner animation="grow" size="sm" />
                                                                    ) : (
                                                                        "Reset"
                                                                    )}</Button>

                                                                </div>
                                                            </form>

                                                        </div>
                                                    </>}
                                                <div className="mt-5 text-center">
                                                    <p>Don't have an account ? <Link to="/login" className="fw-medium text-primary"> Log in </Link> </p>
                                                    <p>  © 2022 {APP_NAME}. </p>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">
                                <div className="bg-overlay"></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}



