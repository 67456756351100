import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container } from 'reactstrap'
import Swal from 'sweetalert2';
import Breadcrumbs from '../../../../../components/Common/Breadcrumb'
import { APP_NAME } from '../../../../../helpers/Constants';
import { DeleteOutline, Edit,FilterList } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../../helpers/MaterialIcons';
import { useDispatch, useSelector } from 'react-redux';
// import LockIcon from '@material-ui/icons/Lock';
// import Unlock from "@material-ui/icons/LockOpen"
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {  AddSubscriberStaff, DeleteSubscriberStaff} from '../../../../../store/subscriber';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import AddStaff from './AddStaff';
import EditStaff from './EditStaff';
import Error from '../../../../../helpers/Error';
import { ErrorAlert, SuccessAlert } from '../../../../../helpers/Alert';


export default function SubscriberStaffs () {
    const navigate=useNavigate();
    const [data,setData] = useState([])
    const [loader,setLoader] = useState(true)
    const [addModalShow,setAddModalShow] = useState(false)
    const [editModalShow,setEditModalShow] = useState(false)
    const [deletedData,setDeletedData] = useState(false)
    const [itemToBeEdited,setItemToBeEdited] = useState(null)
    const [value,setValue] = useState(1)
    const staff=useSelector((state) => state.SubscriberSlice.subscriberStaff)
    const profile = useSelector((state) => state.authSlice.profile)
    const dispatch = useDispatch()
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "Staffs", link: "#" },
        ],
      });

      const addModalToggle = () => {
        setAddModalShow(!addModalShow)
      }
      const editModalToggle = () => {
        setEditModalShow(!editModalShow)
      }
  
    useEffect(() => {
      const abortController = new AbortController();

      const url='/workspace_app/staffs'
      axios.get(url)
      .then((response) => {
        dispatch(AddSubscriberStaff(response.data))
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
      })

      // dispatch(AddSubscriberStaff([{id:1,operator_id:8765,company_name:'Noux',emp_id:1001,extn_no:593,first_name:"David",last_name:"Jacobs",designation:"Officer",email:"david@email.com",phone_no:"0501231000",User_type:"Admin",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"},{id:2,operator_id:2678,emp_id:1002,company_name:'Element 8',extn_no:574,first_name:"Akhil",last_name:"Prem",designation:"Accountant",email:"Akhil@gmail.com",phone_no:"0501231001",User_type:"Admin",address:"789 Fort Myres,Monreo Avenue,Florida",status:"Active"},{id:3,operator_id:3567,emp_id:1003,company_name:'Rad',extn_no:567,first_name:"Sharon",last_name:"VArghese",designation:"Chief Officer",email:"Sharon@email.com",phone_no:"05012310021",User_type:"User",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"}]))
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Staffs", link: "#" },
              ],
            }  
          )

          return () => {
            abortController.abort();
            setData([]); 
          }
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
      

    
 const staffHandler=()=>{
  setLoader(true)
  if(deletedData === false){
    
    const url=`/workspace_app/deleted_staffs/1`
    //   const url='/workspace_app/staffs_subscriber/16'
      axios.get(url,)
      .then((response) => {
        dispatch(AddSubscriberStaff(response.data))
        setDeletedData(true)
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
      })


  }
  else{
     setLoader(true)
    const url='/workspace_app/staffs'
    //   const url='/workspace_app/staffs_subscriber/16'
      axios.get(url)
      .then((response) => {
        dispatch(AddSubscriberStaff(response.data))
        setLoader(false)
        setDeletedData(false)
      })
      .catch((error) => {
        setLoader(false)
      })
  }


 }





    useEffect(() => {
    if(staff !== null){
      const tempData = staff.map((data) => ({
        ...data,
        company_name:data?.company?.company_name ? data.company.company_name : 'No Data'
      }))
      setData(tempData)
    }
    }, [staff])
    

    const columns =
  [
   
    
    { title:'Reg No', field:'reg_no',
  render: rowData => <>{rowData.reg_no ? rowData.reg_no : "No Data"}</>
  },
    { title:'Company Name', field:'company_name'},
    { title:'First Name', field:'first_name'},
    { title:'Last Name', field:'last_name'},

    
    
    {title:'Contact No' , field:'contact_number',
    render: rowData => <>{rowData.contact_number ? rowData.contact_number : "No Data"}</>

  },
   
    {title:'Email' , field:'email'},
    {title:'Status' , field:'status'},

    // { title: "Contract Start", field: "contract_start"},
    // { title: "Contract End", field: "contract_end"},
  ]
     // for handling delete activate deactivate
 const UpdateHandler = (id,method) =>{

  if(method==="Deleted"){
  // console.log(id)
  const url=`/workspace_app/staffs/${id}`
  axios.patch(url,{status:method})
        .then((response) =>{
          SuccessAlert("Staff Deleted Successfully!")
          dispatch(DeleteSubscriberStaff(id))
        })
        .catch((error) =>{
          const err_msg=Error(error)
          ErrorAlert(err_msg)
         
        })
      }
      // else{
      //   if(method === 'Active' || method === 'Disabled'){
      //   const url=`/workspace_app/staffs/${id}`
      //   axios.patch(url,{status:method})
      //   .then((response) =>{
      //     SuccessAlert("Staff Updated Successfully!")
      //     dispatch(EditSubscriberStaff(response.data))
      //   })
      //   .catch((error) =>{
      //     const err_msg=Error(error)
      //     ErrorAlert(err_msg)
         
      //   })
      // }
      // }
 }

 
  const handleChange = (event, newValue) => {
    setValue(newValue)
    if(newValue === 0){
      navigate('/subscribers')
    }
    else if(newValue === 2){
      navigate('/subscriberworkstations')
    }
  };
    
    
  return (
    <React.Fragment>

      
{addModalShow &&
      <AddStaff
      isOpen={addModalShow}
      toggle={addModalToggle}
      title={'Add Staff'}
        />
      }
       {editModalShow &&
      <EditStaff
      isOpen={editModalShow}
      toggle={editModalToggle}
      title={'Edit Staff'}
      item={itemToBeEdited}
        />
      }

          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Staffs"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
              {profile.user_type === "Admin" ?<>
               <div
                className="disappearing-button-container-page-top-right" style={{marginRight:"-140px"}}>
                 <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={addModalToggle}
                >
                  Add Staff
                </Button>
                  </div>
                  </>:null}
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
    <Tab label="Subscribers" wrapped/>
    <Tab label="Staffs" wrapped/>
    <Tab label="WorkStations" wrapped/>
  </Tabs>
</Box>
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={columns}
                        data={data}
                        isLoading={loader}

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },

                          
                          // {
                          //   icon: VisibilityOutlinedIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                          // },
                          rowData => ({
                            icon: Edit,
                            tooltip: 'Edit Staff',
                            onClick: (event, rowData) => {
                              // console.log(rowData)
                              // setEditClientModalShow(true)
                              setItemToBeEdited(rowData)
                              editModalToggle();
                            },
                            hidden:profile.user_type ==="User" || rowData.company.status === 'Deactivated'|| rowData.company.status === 'Disallocated'
                          }),
                          
                          //  rowData => ({
                          //   icon: Unlock,
                          //   tooltip: 'Enable Staff',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                                
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, Enable it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,"Active")
                          //         // deleteClientData(rowData?.id)

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   hidden: profile.user_type ==="User"|| rowData.status === "Invited"  || rowData.status === "Active"
                          // }),
                          // rowData => ({
                          //   icon: LockIcon,
                          //   tooltip: 'Disable Staff',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, Disable it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,"Disabled")
                          //         // deleteClientData(rowData?.id)

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   hidden:profile.user_type ==="User"|| rowData.status === "Disabled" || rowData.status === "Invited" || rowData.status === "Active"
                          // }),
                          

                          rowData => ({

                            icon: DeleteOutline,
                            tooltip: 'Delete Staff',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: "You won't be able to revert this!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, delete it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,'Deleted')

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden:profile.user_type ==="User"||rowData.status==="Deleted"
                           
                          }),
                          {
                            icon: deletedData === false ?VisibilityOutlinedIcon:VisibilityOff ,
                            tooltip:deletedData === false ?'View Deteted Staffs':'View Active Staffs ',
                            isFreeAction: true,
                            onClick: (event) => {
                            
                              staffHandler();
                            }
                            
                          },
                          

                        ]}
                        options={{
                          exportFileName:'Staff List',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
                
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

