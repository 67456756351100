import React from "react";
// import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";

//Import images
import errorImg from "../../../assets/images/error-img.png";
import { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";

export default function Error(props) {
  const [splittedString, setSplittedString] = useState();
  const [errorMessage, setErrorMessage] = useState("Sorry, page not found");

  useEffect(() => {
  // console.log(props)
  const splitted = props.error?.toString().split('');
    
  if(splitted[0] === '0'){
   
    setSplittedString([4,0,4])
  }
  else{
  setSplittedString(splitted);
  }
  
 
  

  if (props.error === 500) {
    setErrorMessage("Internal Server Error");
  }
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  


  return (
    <React.Fragment>
      <div className="text-center"  style={{position:"relative",top:"150px"}}>
            <div className="text-center my-5" >
                <h1 className="fw-bold text-error">
                  {splittedString && splittedString[0]}
                  <span className="error-text">
                    {splittedString && splittedString[1]}
                    <img src={errorImg} alt="" className="error-img" />
                  </span>{" "}
                  {splittedString && splittedString[2]}
                </h1>
                <h3 className="text-uppercase">{errorMessage}</h3>
                <div className="mt-5 text-center">
                  <Link
                    to="/"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Refresh
                  </Link>
                </div>
              </div>
           
      </div>
    </React.Fragment>
  );
}
