import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedIn: null,
  profile: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin(state, action) {
      state.loggedIn = true;
      state.profile = action.payload.user
    },
    setLogOut(state) {
      state.loggedIn = false;
      state.profile = null
    },
    setProfile(state, action) {
  
      state.profile = action.payload;
    },
  },
});

export const { setLogin, setLogOut , setProfile } =
  authSlice.actions;
export default authSlice.reducer;
