import React, { useEffect, useState } from 'react'
import Modals from '../../../components/Modal'
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch } from 'react-redux';
import { ErrorAlert, SuccessAlert } from '../../../helpers/Alert';
import Error from '../../../helpers/Error';
import axios from 'axios';
import {  EditRfidCard } from '../../../store/rfidcard';

function AssignRfidCards(props) {

    const [waitingForAxios,setwaitingForAxios] = useState(false)
    const [company,setCompany] = useState([])
    const [loader,setLoader] = useState(true)
    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
       
        formState: { errors },
      } = useForm();

      
      useEffect(() => {
        const url='/workspace_app/limited_subscriber_list'
      axios.get(url)
      .then((response) => {
        const temp=[]
        response.data.map((data) => {
          if(data.status === "Activated" ){
              temp.push({...data})
          }
          return(temp)
  
        })
        setCompany(temp)
        setLoader(false)
       
      })
      .catch((error) => {
        setLoader(false)
      })

      }, [])

    const onSubmit = (data) =>{
        setwaitingForAxios(true)
        
        const url=`/workspace_app/rfid_card/${props.item.id}`

        axios.patch(url,{...data,status:'Allocated'})
        .then((response) =>{
            // let msg=response.data.message
            setwaitingForAxios(false)
            dispatch(EditRfidCard(response.data))
            SuccessAlert('Rfid Card Assigned Succesfully')
            props.toggle();
        })
        .catch((error) => {
            setwaitingForAxios(false)
            let error_msg=Error(error)
            ErrorAlert(error_msg)
        })
       
    }

  return (
    <div><Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} loader={loader}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>

    
        <FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Company Name" sm={3}>
        Company Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
    <select className="form-control"
                  {...register("company", {
                    required: true,
                    
                  })}>
 <option value="">      --Select Company--</option>
                  {company.map((item,index) => {
                    return <option key={index} value={item.id}>{item.company_name}</option>
                  })}
    </select>
    
        {errors.company?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>


</ModalFooter>
</Form>
        </Modals></div>
  )
}

export default AssignRfidCards