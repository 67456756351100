import { createSlice } from "@reduxjs/toolkit";
  

const initialState={
    timing:null,
}

const TimingSlice=createSlice({
     
     name:"Timing",
     initialState,

     reducers:
     {

        AddTiming(state,action){
            state.timing = action.payload
        },
        AddNewTiming(state,action){
            state.timing = [...state.timing,action.payload]
        },
        EditTiming(state,action){
            state.timing = state.timing.map((data)=>

            data.id === action.payload.id ?action.payload :data
            )

        },

        DeleteTiming(state,action){
            state.timing= state.timing.filter((data)=>

            data.id !== action.payload
            )

        },

     }

})
export const {AddNewTiming,AddTiming,EditTiming,DeleteTiming}=TimingSlice.actions;
export default TimingSlice.reducer;