import React, { useEffect,useRef, useState } from 'react'
import {  Card, CardBody, Col, Container } from 'reactstrap'

import Breadcrumbs from '../../components/Common/Breadcrumb'
import { APP_NAME, ranges } from '../../helpers/Constants';
// import {FilterList } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { tableIcons } from '../../helpers/MaterialIcons';
// import { useDispatch, useSelector } from 'react-redux';

// import { AddDoorAccessReport } from '../../store/report';
// import Swal from 'sweetalert2';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import Moments from '../../helpers/Moment';
import fileDownload from 'js-file-download'
import { ErrorAlert, Toastr } from '../../helpers/Alert';
import { pdfGenerator } from '../../helpers/PDFReportGenerator';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { IconButton, Tooltip } from '@material-ui/core';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';



export default function DoorAccessReport() {
    // const [data,setData] = useState([])
    // // const doorAccessReport=useSelector((state) => state.ReportSlice.doorAccess)
    // const dispatch = useDispatch()
    // const[loader,setLoader]=useState(true)
    // const [filterEnabled, setFilterEnabled] = useState(false);
    const [totalRecordsCount, setTotalRecordsCount] = useState(0)
    const [downloadIndicator,setDownloadIndicator] = useState(false)
    const [urlBackup, setUrlBackup] = useState('')
     const tableRef=useRef();
    const [date,setDate]=useState({})
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "Door Access Details", link: "#" },
        ],
      });
  
    useEffect(() => {

      
      const abortController=new AbortController();

      
      // const url='/workspace_app/rfid_access_loglist'
      // axios.get(url)
      // .then((response) => {
      //   dispatch(AddDoorAccessReport(response.data))
       
       
      //   setLoader(false)
      // })
      // .catch((error) => {
      //   setLoader(false)
      // })

     
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Door Access Details", link: "#" },
              ],
            }  
          )
          return (() => {
            abortController.abort();
            // setData([]); 
          })
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    
  const exportData = (downloadType) => {
    const url = `${urlBackup}&${downloadType}`
    setDownloadIndicator(true)
    if (downloadType === "pdf_download") {
      if (totalRecordsCount >= 2000) {
        ErrorAlert("The maximum records to export as PDF is 2000. Please apply filter to reduce the number of records")
        setDownloadIndicator(false)
        return
      }
   
      axios({
        url: url,
        method: 'GET',
        // responseType: 'blob', // Important
      }).then((response) => {
        setDownloadIndicator(false)
        // console.log(response.data?.body)
        const headRowData = [response.data?.header]
        const bodyRowData = response.data?.body
        const reportName = "Door_Access_Log"
        var doc = pdfGenerator(headRowData, bodyRowData, reportName)
        doc.save("Door_Access_Log.pdf");
        console.log('')
        // tableRef.current.onQueryChange();
       
      }).catch(error => {
        Toastr("error", "Error downloading the file !")
        setDownloadIndicator(false)
      });
    }
    else {
      if (totalRecordsCount >= 5000) {
        ErrorAlert("The maximum records to export as CSV is 5000. Please apply filter to reduce the number of records")
        setDownloadIndicator(false)
        return
      }
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // Important
      }).then((response) => {
        let fileName = "Door_Access_Log.csv"
        fileDownload(response.data, fileName);
        setDownloadIndicator(false)
        // tableRef.current.onQueryChange();
      }).catch(error => {
        Toastr("error", "Error downloading the file !")
        setDownloadIndicator(false)
      });
    }
  }


  
  const handleDate = (start,end) => {

    if(start=== "cancel"){
      setDate({})
      tableRef.current.onQueryChange({page: 0});
    }
    else{
    const firstdate=moment(start).format('YYYY-MM-DD')
    const enddate=moment(end).format('YYYY-MM-DD')

    setDate({start_date: firstdate, end_date: enddate})

    tableRef.current.onQueryChange({page: 0});
    }
  }


    // useEffect(() => {
    // if(doorAccessReport !== null){
    //   const tempData = doorAccessReport.map((data) => ({
    //     ...data,
    //     username:data?.staff?.first_name && data?.staff?.last_name ? data.staff.first_name  +" "+ data.staff.last_name : data?.staff?.first_name ? data.staff.first_name : "No Data" ,
    //     company_name:data?.company?.company_name?data.company.company_name:'No Data',
    //     tag_id:data?.rfid_card?.tag_id?data.rfid_card.tag_id:'No Data',
    //     status:data?.status?data.status:'No Data',
    //     accessed_time:data?.accessed_time? Moments(data.accessed_time,'datetime'):'No Data',
    //     reason:data?.reason? data.reason:'No Data',
    //     accessed_gate:data?.accessed_gate ? data.accessed_gate : 'No Data'

       
    //   }))
    //   setData(tempData)
    // }
    // }, [doorAccessReport])
    

    const columns =
  [
  { title: "Username", field: "staff__full_name" },
  { title: "Company Assigned", field: "company__company_name" ,
  // render: rowData => <>{<Link style={{ color: 'green' }} to={'/'}>{rowData.company_name}</Link>}</>

},
    { title: "Card No", field: "rfid_card__tag_id" },
    { title: "Attempted Date", field: "accessed_time" },
    { title: "Accessed Door", field: "accessed_gate" },
    { title:'Reason', field: 'reason'},
    {title:'Status' , field:'status'},

    // { title: "Contract Start", field: "contract_start"},
    // { title: "Contract End", field: "contract_end"},
  ]

    
    
  return (
    <React.Fragment>
          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Door Access Details"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
              
             
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >


                      
            <div style={{display:'flex',marginBottom:'-55px',width:'60%'}}>
            <Col xl={1}>
              <DateRangePicker  onCallback={(startdate,enddate) => handleDate(startdate._d,enddate._d)}
                  onCancel={(data)=> handleDate(data.type)}

                  initialSettings={{ranges:ranges,
                                      opens:'right',
                                      startDate:moment().subtract(1, 'days'),
                                      // showCustomRangeLabel:true,
                                      // showDropdowns:true,
                                      endDate: moment(),
                                      maxDate:moment()
                                    }}>
  <IconButton aria-label="delete"  color={Object.keys(date).length !== 0 ? 'primary' : 'default'} style={{zIndex:'1'}}>
    <Tooltip title='Show Calendar'>
  <CalendarMonthIcon />
  </Tooltip>
</IconButton>
  </DateRangePicker>

            </Col>
            <div  style={{zIndex:'1',marginBottom:'-40px' }}>
             <p style={{marginTop:'17px',paddingRight:'20px'}}>{Object.keys(date).length !== 0 ?  `Showing Access Logs from ${date.start_date} to ${date.end_date}` : 'Showing All Access Logs'}</p>
            </div>
          </div>
                      <MaterialTable
                        title=" "
                        columns={columns}
                        
                        tableRef={tableRef}

                        data={ (query) => {
                          // console.log(query)
                          return (new Promise((resolve, reject) => {
                            let orderBy = ""
                            // let search =''
                            if (query.orderBy) {
                              orderBy = query.orderDirection === "asc" ? `&ordering=-${query.orderBy?.field}` : `&ordering=${query.orderBy?.field}`
                            }
                            // if(query.search){
                            //   search=query.search === 'Discharged' ? 'Dumped' : query.search
                            // }

                            let url = `/workspace_app/rfid_access_loglist?limit=${query.pageSize}&offset=${query.pageSize * query.page}&search=${query.search}${orderBy}`
                            if (Object.keys(date).length !== 0){
                              url +=`&start_date=${date.start_date}&end_date=${date.end_date}`
                            }
                            
                            setUrlBackup(url)
                            axios.get(url)
                              .then((response) => {
                                setTotalRecordsCount(response.data?.count)
                                // tableRef.current.dataManager.changePageSize(query.pageSize)
                                // console.log(response.data.results)
                                const DoorAccessList = response.data?.results
                                // dispatch(addGtcc(response.data.results))
                                const TempData = DoorAccessList.map((data) => ({
                                  ...data,
                                  staff__full_name:data?.staff?.first_name && data?.staff?.last_name ? data.staff.first_name  +" "+ data.staff.last_name : data?.staff?.first_name ? data.staff.first_name : "No Data" ,
                                  company__company_name:data?.company?.company_name?data.company.company_name:'No Data',
                                  rfid_card__tag_id:data?.rfid_card?.tag_id?data.rfid_card.tag_id:'No Data',
                                  status:data?.status?data.status:'No Data',
                                  accessed_time:data?.accessed_time? Moments(data.accessed_time,'datetime'):'No Data',
                                  reason:data?.reason? data.reason:'No Data',
                                  accessed_gate:data?.accessed_gate ? data.accessed_gate : 'No Data'
                          
                                }))
                                resolve({
                                  data: TempData,
                                  page: response.data?.results.length === 0 ? 0 :query.page,
                                  totalCount: response.data?.count,
                                })
                              })
                              .catch((error) => {
                                // console.log('rejected')
                                reject({
                                  data: [],
                                  page: query.page,
                                  totalCount: 0
                                })
                              })
                          }))
                        }}
                        isLoading={downloadIndicator}
                        options={{
                          exportFileName:'Door Access Details',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          // filtering: filterEnabled,
                          // selection: true
                          debounceInterval: 500,
                      exportCsv: async () => exportData("csv_download"),
                      exportPdf: async () => exportData("pdf_download"),
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
                
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

