import React, { useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { APP_NAME } from '../../helpers/Constants'; 


export default function Settings () {
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "Settings", link: "#" },
        ],
      });
  
    useEffect(() => {

      // dispatch(AddClientUser([{id:1,operator_id:8765,emp_id:1001,extn_no:593,first_name:"David",last_name:"Jacobs",designation:"Officer",email:"david@email.com",phone_no:"0501231000",User_type:"Admin",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"},{id:2,operator_id:2678,emp_id:1002,extn_no:574,first_name:"Akhil",last_name:"Prem",designation:"Accountant",email:"Akhil@gmail.com",phone_no:"0501231001",User_type:"Admin",address:"789 Fort Myres,Monreo Avenue,Florida",status:"Active"},{id:3,operator_id:3567,emp_id:1003,extn_no:567,first_name:"Sharon",last_name:"VArghese",designation:"Chief Officer",email:"Sharon@email.com",phone_no:"05012310021",User_type:"User",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"}]))
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Settings", link: "#" },
              ],
            }  
          )

        
    }, [])

  
  return (
    <React.Fragment>
          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Settings"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>


              
        </Container>
    </div>
    
    </React.Fragment>
  )
}

