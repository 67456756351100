
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    rfidCard : null,
    
};

const RfidSlice = createSlice({
    name: 'rfidcard',
    initialState,
    reducers:{


        
        

          EnableDisableRfidCard(state,action){
            state.rfidCard =state.rfidCard.map((data)=> 
            data.id === action.payload.id? {...data,status:action.payload.status} : data
            
            )
           

        },
       
        DisallocateOrLostRfidCard(state,action){
            state.rfidCard=state.rfidCard.map((data)=>
            data.id === action.payload.id? {...data,company:null,status:action.payload.status}:data
            )

        },

        
        AddRfidCard(state,action) {
            state.rfidCard=action.payload
        },
        NewRfidCard(state,action) {
            state.rfidCard=[...state.rfidCard,action.payload]
        },
        BulkAssignRfidCard(state,action){
            const temp=action.payload
            state.rfidCard=[...state.rfidCard,...temp]
        },
        EditRfidCard(state,action){
            state.rfidCard=state.rfidCard.map((data) =>
                data.id === action.payload.id ? action.payload : data
            )
        },
        DeleteRfidCard(state,action){
            state.rfidCard=state.rfidCard.filter((data)=>
                data.id !== action.payload
            )
        },
        // for bulk editing of Data
        UpdateRfidCard(state,action){
            const data=action.payload
            const temp=data.map((data) => data.id)
            const temp2=state.rfidCard.filter(data => {return !temp.includes(data.id)})
            state.rfidCard=[...temp2,...data]
        }

    }
})

export const {AddRfidCard  , BulkAssignRfidCard , EditRfidCard , DeleteRfidCard, UpdateRfidCard , EnableDisableRfidCard, DisallocateOrLostRfidCard , NewRfidCard} = RfidSlice.actions;
export default RfidSlice.reducer;