import React, { useEffect, useState } from 'react'
import Modals from '../../../../components/Modal'
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch } from 'react-redux';
import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
import Error from '../../../../helpers/Error';
import axios from 'axios';
import { EditRoom } from '../../../../store/masterdata';

function EditCabin(props) {

    const [waitingForAxios,setwaitingForAxios] = useState(false)
    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();

      useEffect(() => {
        const temp={...props.item}
        reset(temp)

          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
      


    const onSubmit = (data) =>{

        let formData = new FormData(); //formdata object
    //   formData.append("user_type", data.user_type);
        formData.append("description", data.description);
        formData.append("cabin_name", data.cabin_name);
        formData.append("cabin_no", data.cabin_no);
        setwaitingForAxios(true)
        
        const url=`/workspace_app/cabin/${props.item.id}`

        axios.patch(url,formData)
        .then((response) =>{
            // let msg=response.data.message
            setwaitingForAxios(false)
            dispatch(EditRoom(response.data))
            SuccessAlert('Cabin Edited Succesfully')
            props.toggle();
        })
        .catch((error) => {
            setwaitingForAxios(false)
            if(error.response.data.message){
                ErrorAlert(error.response.data.message)  
            }
            else if(error.response?.data?.cabin_no[0]){
                ErrorAlert(error.response?.data?.cabin_no[0])

            }
           
            else{
            let error_msg=Error(error)
            ErrorAlert(error_msg)
            }
           
        })
       
    }

  return (
    <div><Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Cabin No" sm={3}>
        Cabin No <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Cabin No"
            type="text"
            className="form-control"
            {...register("cabin_no", {
                required: true,
            })}
        />
        {errors.cabin_no?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Cabin Name" sm={3}>
        Cabin Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Cabin Name"
            type="text"
            className="form-control"
            {...register("cabin_name", {
                required: true,
            })}
        />
        {errors.cabin_name?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Description" sm={3}>
        Description <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Description"
            type="text"
            className="form-control"
            {...register("description", {
                required: true,
            })}
        />
        {errors.description?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>


</ModalFooter>
</Form>
        </Modals></div>
  )
}

export default EditCabin