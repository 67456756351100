import React from 'react';
import { Container, Card, CardBody, Col } from "reactstrap";

// import classnames from 'classnames';
// , Nav, NavItem, NavLink, TabPane, TabContent
// //Import Components
// import Accordian from "./accordian";




function AboutUs() {
    
    // const [activeTab, setActiveTab] = useState('1')
   
   
    return (
        <React.Fragment>
        <div className="page-content" style={{marginTop:"-50px"}}>
          <Container fluid>
           
                 <Col xl={24}>
              <Card style={{ minHeight: "575px" }}>
                <CardBody>
                <div className="text-center" style={{marginTop:"20px"}}>
                                                <h1>About Us</h1></div>
                                                <div style={{fontSize:"18px",marginTop:"40px",textAlign:"justify"}}>
                                                <p>Introducing The <b>Workbook</b>, premium coworking spaces from the house of HiLITE Group. Quality has always been second nature for HiLITE Group, boasting 2 fruitful decades of experience. The Workbook aims to provide top-notch workplace solutions by offering premium workspaces and countless amenities. We dream of a world where work is fueled by imagination and creativity, at a place that never runs out of it. With us, work is enjoyment. .</p>
                                                <p>Come, work and grow with us! </p>
                                                </div>
                                            


                  {/* </Row> */}
                </CardBody>
              </Card>
            </Col>
              
            </Container>
              </div>
              </React.Fragment>

    );
}

export default AboutUs