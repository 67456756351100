import React, { useEffect } from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from '../../../assets/lotties/payment_failed.json';
import { useNavigate } from 'react-router-dom';


export const PaymentFailed = () => {

  const navigate=useNavigate();


  useEffect(() => {
    setTimeout(()=>{
      navigate("/invoice")
      
    },4000)


    
    return () => clearTimeout();
   
  }, [navigate])
  return (


    <div className='page-content'>
        <div className="text-center">
          {/* <img src={Maintenance} alt=""/> */}
          <Player
            autoplay
            loop
            src={animationData}
            style={{ height: '400px', width: '400px' }}
          >
          </Player>
          <div style={{position:'relative' , top:'-90px'}}>
          <h5> Payment Failed</h5>
          </div>
        </div>
        </div>
  )
}
