import React, { useEffect, useState } from 'react'
import Modals from '../../../components/Modal'
import { set, useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch } from 'react-redux';
import { ErrorAlert, SuccessAlert } from '../../../helpers/Alert';
import Error from '../../../helpers/Error';
import axios from 'axios';
// import { AddNewRfidCard, EditRfidCard } from '../../../store/rfidcard';
import { AddNewPayment } from '../../../store/report';
import Moments from '../../../helpers/Moment';

function AddPayments(props) {

    const [waitingForAxios,setwaitingForAxios] = useState(false)
    const [company,setCompany] = useState([])
    const [invoice,setInvoice] = useState([])
    const [name,setName] = useState('')
    const [data,setData] = useState([])
   
    const [loader,setLoader] = useState(true)
    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
       setValue,
        formState: { errors },
      } = useForm();

      
      useEffect(() => {
        // let formData=new FormData();

        // formData.append('limited',true)

        const url='/workspace_app/limited_subscriber_list'
      axios.get(url)
      .then((response) => {
        // console.log(formData)
        const temp=[]
        response.data.map((data)=>{

         if(data.status==="Activated"){
            temp.push({...data})
         }
         return(temp)
        })
        setCompany(temp)
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
      })

      const url1='/workspace_app/invoice_list_for_payment'
      axios.get(url1)
      .then((response) => {
        const temp=response.data.filter((data)=>
           data.payment_status !== 'Paid'
        )
       // console.log(temp)
        setInvoice(temp)
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
      })

      }, [])

      const changeHandler = (data) =>{
        setData([])
        setValue('invoice','')
        setValue('amount','')
        const id=data.target.value
        if(id){
           
        const index = data.target.selectedIndex;
        const el = data.target.childNodes[index]
        const name =  el.getAttribute('val'); 
        setName(name)
        

          const temp=invoice.filter((data) => Number(data.company.id) === Number(id))
      
        setData(temp)
        
        }
        else{
            setData([]) 
            setName('')
        }
      }

      const changeInvoiceHandler = (id) => {
        
         

        if(id !== ''){
        const temp=invoice.filter((data) => Number(data.id) === Number(id))
         
        setValue('amount',temp[0].amount)
        }
        else{
            setValue('amount','')

        }
        
      }
     

    


    const onSubmit = (data) =>{
        let formData=new FormData();

        formData.append('company',data.company)
        formData.append('payment_id',data.payment_id)
        formData.append('name',name)
        formData.append('amount',data.amount)
        formData.append('invoice',data.invoice)
        formData.append('payment_date',data.payment_date)


        if(data.remark !==''){
        formData.append('remarks',data.remark)
        }

       // console.log(formData)

        setwaitingForAxios(true)
        
        const url='/workspace_app/payment_by_admin'

        axios.post(url,formData)
        .then((response) =>{
            // let msg=response.data.message

            // if(props.isAll){
            setwaitingForAxios(false)
            props.tableRef.current.onQueryChange();
            SuccessAlert('Payment Added Succesfully')
            props.toggle();
            // }
            // else{
            // setwaitingForAxios(false)
            // dispatch(AddNewPayment(response.data))
            // SuccessAlert('Payment Added Succesfully')
            // props.toggle();
            // }
        })
        .catch((error) => {
            setwaitingForAxios(false)
            let error_msg=Error(error)
            ErrorAlert(error_msg)
        })
       
    }

  return (
    <div><Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} loader={loader}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>

    
        <FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Company Name" sm={3}>
        Company Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
    <select className="form-control"
                  {...register("company", {
                    required: true,
                    onChange:(event) => {changeHandler(event);}
                    
                  })}>
                    <option value="">--Select Company--</option>
                  {company.map((item,index) => {
                    return <option key={item.id} val={item.company_name} value={item.id}>{item.company_name}</option>
                  })}
    </select>
    
        {errors.company?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Invoice" sm={3}>
        Invoice <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
    <select className="form-control"
                  {...register("invoice", {
                    required: true,
                    onChange:(event) => {changeInvoiceHandler(event.target.value);}
                    
                  })}>
                    <option value="">--Select Invoice--</option>
                  {data.map((item,index) => {
                    return <option key={index} value={item.id} >{`${item.invoice_id} (${Moments(item.due_date)} - ₹${item.amount} - ${item.payment_status})`}</option>
                  })}
    </select>
    
        {errors.invoice?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Payment Id" sm={3}>
        Payment Id <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Payment Id"
            type="text"
            className="form-control"
            {...register("payment_id", {
                required: true,
            })}
        />
        {errors.payment_id?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Amount" sm={3}>
        Amount <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Amount"
            type="text"
            
            className="form-control"
            {...register("amount", {
                required: true,
                disabled:true,
            })}
        />
        {errors.amount?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Payment Date" sm={3}>
        Payment Date <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Payment Date"
            type="date"
            defaultValue={new Date().toISOString().split("T")[0]}
            className="form-control"
            {...register("payment_date", {
                required: true,
               
            })}
        />
        {errors.amount?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Remark" sm={3}>
        Remark
    </Label>
    <Col sm={9}>
        <input
            placeholder="Remark"
            type="text"
            className="form-control"
            {...register("remark", {
                // required: true,
            })}
        />
        {errors.remark?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>


</ModalFooter>
</Form>
        </Modals></div>
  )
}

export default AddPayments