// _____for usermanagment_____
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clientUser : null,
    subscriber: null,
    subscriberStaff: null,
    
};

const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers:{
        AddClientUser(state,action) {
            state.clientUser=action.payload
        },
        AddNewClientUser(state,action){
            state.clientUser=[...state.clientUser,action.payload]
        },
        EditClientUser(state,action){
            state.clientUser=state.clientUser.map((data) =>
                data.id === action.payload.id ? action.payload : data
            )
        },
        DeleteClientUser(state,action){
            state.clientUser=state.clientUser.filter((data)=>
                data.id !== action.payload
            )
        },
        AddSubscriberUser(state,action) {
            state.subscriberStaff=action.payload
        },
        EditSubscriberUsers(state,action){

            const temp=state.subscriberStaff.filter((data) =>
                data.id !== action.payload.id
            )

            state.subscriberStaff=[...temp,action.payload.res]
        },
    }
})

export const { AddClientUser,AddNewClientUser,EditClientUser,DeleteClientUser,AddSubscriberUser, EditSubscriberUsers} = UserSlice.actions;
export default UserSlice.reducer;