import React from 'react';
// import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import images
// import errorImg from "../../assets/images/error-img.png";


export default function Unauthorized() {
  return (
    <React.Fragment>
     <div className="text-center"  style={{position:"relative",top:"150px"}}>
                <h1 className="fw-bold text-error">
                  Unauthorized
                </h1>
                <h3 className="text-uppercase">Sorry, page not found</h3>
                <div className="mt-5 text-center">
                  <Link
                    to="/"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Back to Dashboard
                  </Link>
                </div>
              </div>
         
    </React.Fragment>
  );
}
