import React, { useEffect, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
// import axios from "axios";

import { useSelector } from "react-redux";
// import GraphLoader from "../../components/GraphLoader/GraphLoader";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


import moment from "moment";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css'
import { Color } from "../../../helpers/Constants";



import axios from "axios";
import Error from "../../../helpers/Error";


// const AntSwitch = styled(Switch)(({ theme }) => ({
  
 
//   '&:active': {
//     '& .MuiSwitch-thumb': {
     
//     },
//     '& .MuiSwitch-switchBase.Mui-checked': {
     
//     },
//   },
//   '& .MuiSwitch-switchBase': {
    
//     '&.Mui-checked': {
     
     
//       '& + .MuiSwitch-track': {
      
//       },
//     },
//   },
//   '& .MuiSwitch-thumb': {
    
//   },
//   '& .MuiSwitch-track': {
    
//   },
// }));


export default function LineGraph(props) {

  // const [loader, setLoader] = useState(true)
  // const [heading,setHeading] = useState('Trend  Per Waste Main Catagories Last Year - [kg]')
  // const [date,setDate]=useState()
  // const [graphData, setGraphData] = useState([]);
  // const [date, setDate] = useState({});
  // const [checked,setChecked]=useState(null)
  const profile = useSelector((state) => state.authSlice.profile);
  // const [focusedInput, setFocusedInput] = useState(false);
  // const [startDate, setStartDate] = useState(null);
  // const [error, setError] = useState(null);
  // // const [apiSwitch,setApiSwitch] = useState(null)

const [error,setError] = useState(null)
  const [state,setState]=useState({options : {} , series:[]})


  useEffect(() => {

    var date = new Date();
    
    // console.log("!!!!!!!!date",(date))
    var firstdate = moment(date).subtract(364, 'days').format('YYYY-MM-DD');
    var lastdate = moment(date).format('YYYY-MM-DD');
     
  if(profile.user_class==="Hilite"){
     const url='/workspace_app/total_subscription_per_month'
    axios.post(url,{'end_date':lastdate,'time_span':'month','start_date':firstdate})
    .then((response) =>{
     
     
      setState({
        series:response.data.x_axis,
        options: {
          chart: {
            zoom: { enabled: !1 },
            toolbar: { show: true ,
              
              export: {
                svg: {
                  filename: 'Total Subscriptions',
                },
                png: {
                  filename:  'Total Subscriptions',
                },
                csv:{
                  filename:  'Total Subscriptions',
                }
              },
            }
          },
          colors: Color,
          dataLabels: { enabled: false },
          stroke: {
            width: [3, 3, 3, 3, 3, 3, 3, 3],
            curve: "straight"
          },
          title: {
            align: "left",
            style: { fontWeight: "normal", fill: "#373d3f", opacity: 1 }
          },
          grid: {
            row: { colors: ["transparent", "transparent"], opacity: .2 },
            borderColor: "#f1f1f1"
          },
          markers: { style: "inverted", size: 6 },
          xaxis: {
            categories: response.data.y_axis
           ,
            title: { text: "Month" }
          },
          yaxis: {
            title: { text: "Amount in Rupees" },
    
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            floating: !2,
            offsetY: 8,
            offsetX: -5
          },
          responsive: [{
            breakpoint: 600,
            options: {
              chart: { toolbar: { show: true } },
              legend: { show: !1 }
            }
          }]
        }
      })
  
     
     
      
    })
    .catch((error) =>{

          setError(Error(error))
    })

    

  }else{
    const url1='/workspace_app/total_subscription_per_month_by_company'
    axios.post(url1,{end_date:lastdate,time_span:'month',start_date:firstdate,company_id:profile.link_id})
    .then((response) =>{
      
     
      setState({
        series:response.data.x_axis,
        options: {
          chart: {
            zoom: { enabled: !1 },
            toolbar: { show: true ,
              
              export: {
                svg: {
                  filename: 'Total Payment',
                },
                png: {
                  filename:  'Total Payment',
                },
                csv:{
                  filename:  'Total Payment',
                }
              },
            }
          },
          colors: Color,
          dataLabels: { enabled: false },
          stroke: {
            width: [3, 3, 3, 3, 3, 3, 3, 3],
            curve: "straight"
          },
          title: {
            align: "left",
            style: { fontWeight: "normal", fill: "#373d3f", opacity: 1 }
          },
          grid: {
            row: { colors: ["transparent", "transparent"], opacity: .2 },
            borderColor: "#f1f1f1"
          },
          markers: { style: "inverted", size: 6 },
          xaxis: {
            categories: response.data.y_axis
           ,
            title: { text: "Month" }
          },
          yaxis: {
            title: { text: "Amount in Rupees" },
    
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            floating: !2,
            offsetY: 8,
            offsetX: -5
          },
          responsive: [{
            breakpoint: 600,
            options: {
              chart: { toolbar: { show: true } },
              legend: { show: !1 }
            }
          }]
        }
      })
  
     
     
      
    })
    .catch((error) =>{

          setError(Error(error))
    })

    
  }
  

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

// useEffect(() => {
//   // console.log(state)
// }, [state])


  const [totalSwitch,setTotalSwitch] = useState(false)

  const switchHandler = (event) => {
    if(event.target.checked){
      setTotalSwitch(true)
      setState(state => ({
        ...state,
        options: { ...state.options, dataLabels: { enabled: event.target.checked } }
      }))
    }
    if(event.target.checked === false){
      setTotalSwitch(false)
    setState(state => ({
      ...state,
      options: { ...state.options, dataLabels: { enabled: event.target.checked } }
    }))
  }
  };






  
//   // for handling date changes
  
//   const handleDatesChange = (dates) => {
//     if(dates){
//     setStartDate(dates)
//     // setLoader(true)
//     // setError(null)
//     // setState({ options: {}, series: [] })

//     const dat=new Date(dates)
//     const firstdate=moment(dat).subtract(364, 'days').format('MM/DD/YYYY');
//     const enddate=moment(dat).format('MM/DD/YYYY');
    
//     setDate({startdate: firstdate, enddate: enddate})
//     // if (profile.entity.entity_type === "OUTLET") {
//     //   if(checked){
//     //   // console.log("worked  outlet")
//     //   postdata1({ start_date: firstdate, end_date: enddate, time_span: "month", outlet_id: profile.entity.id ,sum_column:"price"})
//     //   // setData()
//     //   }
//     //   else{
//     //     postdata({ start_date: firstdate, end_date: enddate, time_span: "month", outlet_id: profile.entity.id ,sum_column:"chargable_weight"})
//     //   }
//     // }
//     // else {
//     //   if (profile.entity.entity_type === "PROPERTY") {
//     //     // console.log("worked property")
//     //     postdata({ start_date: firstdate, end_date: enddate, time_span: "month", property_id: profile.entity.id,sum_column:"chargable_weight" })
//     //   }
//     //   else {
//     //     // console.log("worked client")
//     //     postdata({ start_date: firstdate, end_date: enddate, time_span: "month", client_id: profile.entity.id,sum_column:"chargable_weight" })
//     //   }
//     // }
//   }
//   else{
//     setStartDate(null)
//     // setState({ options: {}, series: [] })
//     // setLoader(true)
//     // setError(null)

//     // var date = new Date();
//     // var firstdate = moment(date).subtract(364, 'days').format('MM/DD/YYYY');
//     // var lastdate = moment(date).format('MM/DD/YYYY');

//     // setDate({startdate: firstdate, enddate: lastdate})
    
//     // if (profile.entity.entity_type === "OUTLET") {
//     //   if(checked){
//     //   // console.log("worked  outlet")
//     //   postdata1({ start_date: firstdate, end_date: lastdate, time_span: "month", outlet_id: profile.entity.id ,sum_column:"price"})
//     //   // setData()
//     //   }
//     //   else{
//     //     postdata({ start_date: firstdate, end_date: lastdate, time_span: "month", outlet_id: profile.entity.id ,sum_column:"chargable_weight"})
//     //   }
//     // }
//     // else {
//     //   if (profile.entity.entity_type === "PROPERTY") {
//     //     // console.log("worked property")
//     //     postdata({ start_date: firstdate, end_date: lastdate, time_span: "month", property_id: profile.entity.id ,sum_column:"chargable_weight"})
//     //   }
//     //   else {
//     //     // console.log("worked client")
//     //     postdata({ start_date: firstdate, end_date: lastdate, time_span: "month", client_id: profile.entity.id,sum_column:"chargable_weight" })
//     //   }
//     // }
//   }
// }


  return (
    <React.Fragment>
        <Container fluid>
      <Card style={{ minHeight: "570px" }}>
        <CardBody>
        <h4 className='card-title mb-4'>{props.title}</h4>
          <div className="felx-heading">
           
         
                <FormGroup>
                  <FormControlLabel control={<Switch checked={totalSwitch} onChange={switchHandler} />}
                    label={<span style={{ fontSize: '.9rem' }}>{"Show Amount"}</span>} />
                </FormGroup>
      
           
          </div>
          {/* <div className="line_graph_date_picker">
                  <div style={{zIndex:"1000"}}>
                    <p style={{margin:'0.5rem',display:"inline"}}></p>
                    <SingleDatePicker
                       date={startDate} // momentPropTypes.momentObj or null
                       onDateChange={handleDatesChange} // PropTypes.func.isRequired
                       focused={focusedInput.focused}
                       onFocusChange={focusedInput => setFocusedInput(focusedInput)}// PropTypes.func.isRequired
                       showDefaultInputIcon
                       showClearDate
                       
                       small
                       isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                       
                    />
              </div> 
              </div> */}
          <div id='donut-chart' className='apex-charts'>
            <ReactApexChart options={state.options} series={state.series} type="line" height="380"/>
            {error && <><p className="text-center">{error}</p></> }
            {/* <><p className='text-center' style={{fontWeight:"bold"}}>Showing Values from {date.startdate} to {date.enddate}</p></>} */}
            {/* {loader ? <GraphLoader /> : null} */}
          </div>
          {/* <div
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr",gridTemplateRows:"1fr 1fr",gap:"10px 10px" }}>
            {props.salesAnalytics.options.labels.map((item, index) => (
                <div className='text-center mt-4' style={{ display: "flex" }} key={item}>
                  <p className='mb-2 text-truncate' >
                    <i
                      className='mdi mdi-circle  font-size-10 me-1'
                      style={{
                        color: props.salesAnalytics.options.colors[index],
                      }}></i>
                    {item}
                  </p>
                </div>
            ))}
          </div>  */}
        </CardBody>
      </Card>
      </Container>
    </React.Fragment>
  );

}
