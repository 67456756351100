import React, { useEffect, useState } from 'react'
import Modals from '../../../../../components/Modal'
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Input, Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch } from 'react-redux';
// import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
// import Error from '../../../../helpers/Error';
import axios from 'axios';
import { EditSubscriber } from '../../../../../store/subscriber';
import Error from '../../../../../helpers/Error';
import { ErrorAlert, SuccessAlert } from '../../../../../helpers/Alert';


function EditSubscribers(props) {

    const [waitingForAxios,setwaitingForAxios] = useState(false)
    // const [designation,setDesignation] = useState([])
    // const [loader,setLoader] = useState(true)
    const [value,setValue] = useState('')

    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();

      useEffect(() => {

        if (props.item !== null) {

        const temp={...props.item,
        contact_person:props.item && props.item.contact_person__name,
        contact_number:props.item && props.item.contact_person__number,
        email:props.item && props.item.contact_person__email,
        }
        reset(temp)

        }
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.item])
      


    const onSubmit = (data) =>{

        
        let formData = new FormData(); //formdata object
      formData.append("email", data.email);
      formData.append("company_name", data.company_name);
      formData.append("contact_person", data.contact_person);
      formData.append("address", data.address);
      formData.append("contact_number", data.contact_number);
      formData.append("reg_no", data.reg_no);
      formData.append("email", data.email);

      if(value !==''){
      formData.append("is_virtual_acc",value === 'Yes'? 1:0);
      }

      if(data.gst_in !==''){
      formData.append("gst_in", data.gst_in);
      }

        
        setwaitingForAxios(true)
        
        const url=`/workspace_app/subscriber/${props.item.id}`

        axios.put(url,formData)
        .then((response) =>{
            // let msg=response.data.message
            setwaitingForAxios(false)
            dispatch(EditSubscriber(response.data))
            SuccessAlert('Subscriber Edited Succesfully')
            props.toggle();
        })
        .catch((error) => {
            setwaitingForAxios(false)
            if(error.response.data.message){
                ErrorAlert(error.response.data.message)  
            }
            else if(error.response?.data?.reg_no[0]){
                ErrorAlert(error.response?.data?.reg_no[0])
            }
            else if(error.response?.data[0]){
                ErrorAlert(error.response?.data[0])
            }
            
            else{
                let error_msg=Error(error)
                ErrorAlert(error_msg)
            }
        })
       
    }

  return (
    <div><Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>


        <FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Reg No" sm={3}>
        Reg No <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Reg No"
            type="text"
            className="form-control"
            {...register("reg_no", {
                required: true,
            })}
        />
        {errors.reg_no?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Company Name" sm={3}>
        Company Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Company Name"
            type="text"
            className="form-control"
            {...register("company_name", {
                required: true,
            })}
        />
        {errors.company_name?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="GST IN" sm={3}>
        GST IN 
    </Label> 
    <Col sm={9}>
        <input
            placeholder="GST IN"
            type="text"
            className="form-control"
            {...register("gst_in", {
                
            })}
        />
        
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Address" sm={3}>
        Address <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Address"
            type="text"
            className="form-control"
            {...register("address", {
                required: true,
            })}
        />
        {errors.address?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Contact Person" sm={3}>
        Contact Person <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Contact Person"
            type="text"
            className="form-control"
            {...register("contact_person", {
                required: true,
            })}
        />
        {errors.contact_person?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Contact Number" sm={3}>
        Contact Number <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Contact Number"
            type="text"
            className="form-control"
            {...register("contact_number", {
                required: true,
            })}
        />
        {errors.contact_number?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Email Id" sm={3}>
        Email Id <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Email Id"
            type="text"
            className="form-control"
            {...register("email", {
                required: true,
            })}
        />
        {errors.email?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px"}}>
    <Label for="Virtusl Account" sm={3}>
        Is Virtual ? <span style={{color:'red'}}></span>
    </Label>
   <Col sm={2} style={{ marginTop: "10px"}} >
   <Label check><Input 
        name="radio1"
        type="radio"
        value="Yes"
        
        onChange={(event) => setValue(event.target.value)}
      />
      {' '}
    
        Yes
      </Label></Col>
    
    <Col sm={2} style={{ marginTop: "10px"}} >
    <Label check>
    <Input
        name="radio1"
        type="radio"
        value='No'
       

        onChange={(event) => setValue(event.target.value)}

      />
      {' '}
      
        No
      </Label></Col>
   
      
    
 
</FormGroup>


<ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>


</ModalFooter>
</Form>
        </Modals></div>
  )
}

export default EditSubscribers