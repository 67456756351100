import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container } from 'reactstrap'
// import Swal from 'sweetalert2';
import Breadcrumbs from '../../../../../components/Common/Breadcrumb'
import { APP_NAME } from '../../../../../helpers/Constants';
import { FilterList } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../../helpers/MaterialIcons';
import { useDispatch, useSelector } from 'react-redux';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// import { AddSubscriber } from '../../../../../store/user';
import { useNavigate } from 'react-router-dom';
import AssignWorkstation from './AssignWorkstation';
import { AssignCabin } from './AssignCabin';
import { AddSubscriberWorkStation } from '../../../../../store/subscriber';
import axios from 'axios';

// const Tabs = styled(Tabs)({
//     borderBottom: '1px solid #e8e8e8',
//     '& .MuiTabs-indicator': {
//       backgroundColor: '#1890ff',
//     },
//   });

export default function SubscribersWorkStations () {
    const navigate=useNavigate();
    const [data,setData] = useState([])
    const [value,setValue] = useState(2)
    const [assignModalShow,setAssignModalShow] = useState(false)
    const [assignCabinModalShow,setAssignCabinModalShow] = useState(false)
    const [loader,setLoader] = useState(true)

    const workStations=useSelector((state) => state.SubscriberSlice.subscriberWorkStation)
    const profile = useSelector((state) => state.authSlice.profile)
    const dispatch = useDispatch()
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "WorkStations", link: "#" },
        ],
      });
  
      
    const assignModalToggle = () =>{
      setAssignModalShow(!assignModalShow)
    }
    const assignCabinModalToggle = () =>{
      setAssignCabinModalShow(!assignCabinModalShow)
    }

    useEffect(() => {
     
      const abortController=new AbortController();
    const url="/workspace_app/allocated_workstation"
    axios.get(url).then((response)=> {
      dispatch(AddSubscriberWorkStation(response.data))
      setLoader(false)
    })
    .catch((error) => {
      setLoader(false)
    })
      // dispatch(AddSubscriber([{id:1,operator_id:8765,company_name:'Noux',emp_id:1001,extn_no:593,first_name:"David",last_name:"Jacobs",designation:"Officer",email:"david@email.com",phone_no:"0501231000",User_type:"Admin",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"},{id:2,operator_id:2678,emp_id:1002,company_name:'Element 8',extn_no:574,first_name:"Akhil",last_name:"Prem",designation:"Accountant",email:"Akhil@gmail.com",phone_no:"0501231001",User_type:"Admin",address:"789 Fort Myres,Monreo Avenue,Florida",status:"Active"},{id:3,operator_id:3567,emp_id:1003,company_name:'Rad',extn_no:567,first_name:"Sharon",last_name:"VArghese",designation:"Chief Officer",email:"Sharon@email.com",phone_no:"05012310021",User_type:"User",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"}]))
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "WorkStations", link: "#" },
              ],
            }  
          )
          return()=>{
            abortController.abort();
            setData([]); 
          }

           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
    if(workStations !== null){
      const tempData = workStations.map((data) => ({
        ...data,
        company_name:data?.company?.company_name ? data.company.company_name : 'No Data',
        reg_no:data?.company?.reg_no? data.company.reg_no:"No Data",
        cabin_no:data?.cabin?.cabin_no ? data.cabin.cabin_no: 'No Cabin Associated With this WorkStation'
        

      }))
      setData(tempData)
    }
    }, [workStations])
    

    const columns =
  [
   
    
    { title:'Reg No', field:'reg_no'},
    { title:'Company Name', field:'company_name'},
    { title: "Cabin", field: "cabin_no" },
    { title: "Work Station Name", field: "workstation_name" ,
  render:rowData => <>{rowData.workstation_name ? rowData.workstation_name : "No Data"}</>
  },
   
    
    
    {title:'Status' , field:'status'},

    // { title: "Contract Start", field: "contract_start"},
    // { title: "Contract End", field: "contract_end"},
  ]

  
  const handleChange = (event, newValue) => {
    setValue(newValue)
    if(newValue === 1){
      navigate('/subscriberstaffs')
    }
    else if(newValue === 0){
      navigate('/subscribers')
    }
  };
    
    
    
  return (
    <React.Fragment>

      {assignModalShow &&
      <AssignWorkstation
      title={'Assign Workstation'}
      isOpen={assignModalShow}
      toggle={assignModalToggle}
        />
      }
      {assignCabinModalShow &&
      <AssignCabin
      title={'Assign Cabin'}
      isOpen={assignCabinModalShow}
      toggle={assignCabinModalToggle}
        />
      }
     
          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="WorkStations"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
              { profile.user_type === "Admin" ?<>
               <div
                className="disappearing-button-container-page-top-right" style={{marginRight:"-80px"}}>
                  <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={assignCabinModalToggle}
                >
                  Assign Cabin
                </Button>
                 
                 
                 <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={assignModalToggle}
                >
                  Assign WorkStation
                </Button>
                
                  </div>
                  </>:null}
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
    <Tab label="Subscribers" wrapped/>
    <Tab label="Staffs" wrapped/>
    <Tab label="WorkStations" wrapped/>
  </Tabs>
</Box>
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={columns}
                        data={data}
                        isLoading={loader}

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },

                          
                          // {
                          //   icon: VisibilityOutlinedIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                          // },
                          // {
                          //   icon: Edit,
                          //   tooltip: 'Edit Client',
                          //   onClick: (event, rowData) => {
                          //     // console.log(rowData)
                          //     // setEditClientModalShow(true)
                          //     // setItemToBeEdited(rowData)
                          //   }
                          // },
                          // rowData => ({
                          //   icon: DeleteOutline,
                          //   tooltip: 'Delete Client',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       text: "You won't be able to revert this!",
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, delete it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         // deleteClientData(rowData?.id)

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   disabled: rowData.birthYear < 2000
                          // })
                        ]}
                        options={{
                          exportFileName:'Workstations List',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
                
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

