import React, { useEffect } from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from '../../../assets/lotties/payment_success.json';
import { useNavigate } from 'react-router-dom';







function PaymentSuccess() {
  const navigate=useNavigate();


  useEffect(() => {
    setTimeout(()=>{
      navigate("/invoice")
      
    },4000)



    return () => clearTimeout();
   
  }, [navigate])
  
  return (
    <div className='page-content'>
        <div className="text-center">
          {/* <img src={Maintenance} alt=""/> */}
          <Player
            autoplay
            loop
            src={animationData}
            style={{ height: '400px', width: '400px' }}
          >
          </Player>
          <div style={{position:'relative' , top:'-90px'}}>
          <h5> Payment Success</h5>
          </div>
          
        </div>
        </div>
  )
}

export default PaymentSuccess