import React, { useEffect, useState } from 'react'

import { useForm } from "react-hook-form";
import { Button,Form,  ModalFooter, Spinner, Card, CardBody} from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import Modals from '../../../../../../components/Modal';
// import { ErrorAlert, SuccessAlert } from '../../../../../../helpers/Alert';
// import Error from '../../../../helpers/Error';
// import axios from 'axios';
// import { AddNewClientUser } from '../../../../../../store/user';
import { MultiSelect } from "react-multi-select-component";
import axios from 'axios';
import { ErrorAlert, SuccessAlert, Toastr } from '../../../../../../helpers/Alert';
// import { AddNewWorkStation } from '../../../../../../store/masterdata';
import { AddNewSubscriberWorkStation, EditSubscriberDetails } from '../../../../../../store/subscriber';

function Assign_Workstation(props) {
  const subscriberDetails=useSelector((state)=>state.SubscriberSlice.subscriberDetails)
  const [options,setOptions] = useState([])
  const [loader,setLoader] = useState(true)
  const [waitingForAxios,setWaitingForAxios] = useState(false)
  const [ids,setIDs] = useState([])
  // const [company,setCompany] = useState([])
  // const [companyId,setCompanyId] = useState('')
  const dispatch = useDispatch();
  const {
    
    handleSubmit,
   
    // formState: { errors },
  } = useForm();

  useEffect(() => {

    

    const url='/workspace_app/workstation'
    axios.get(url)
    .then((response) => {
      // const temp=rfidCard.filter((data) => data.company === null)
      const temp=[]
      if(subscriberDetails.is_virtual_acc === false){
      response.data.map((data) => {
        if(data.cabin === null && data.company === null && data.is_hotseat === false){
            temp.push({...data,label:`${data.workstation_name} (${data.workstation_no})`,value:data.id,})
        }
        return(temp)
        
    })
  }
  else{
    response.data.map((data) => {
      if(data.cabin === null && data.company === null && data.is_hotseat === true){
          temp.push({...data,label:`${data.workstation_name} (${data.workstation_no})`,value:data.id,})
      }
      return(temp)
      
  })
  }
      setLoader(false)
      setOptions(temp)
    })
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const onSubmit = () =>{
      if(ids.length !==0){
       

      setWaitingForAxios(true)

      const url='/workspace_app/assign_workstation_company'

      axios.post(url,{company:props.id,workstations:ids})
      .then((response) =>{


          if(subscriberDetails.is_virtual_acc === true){
          dispatch(EditSubscriberDetails(response.data))
          dispatch(AddNewSubscriberWorkStation(response.data))
          SuccessAlert("Hotseat Assigned Successfully")
          setWaitingForAxios(false)
          props.toggle()
          }
          else{
            dispatch(AddNewSubscriberWorkStation(response.data))
            setWaitingForAxios(false)
            SuccessAlert("WorkStation Assigned Successfully")
            props.toggle()
          }
       

      })
      .catch((error) =>{
          setWaitingForAxios(false)
          // const error_msg=Error(error.response.data[0])
          ErrorAlert(error.response.data[0])
          
      })
  
      }


   
    else{
      Toastr("warning",'Please Select An WorkStation For Assigning')
    }

  }
  
  
  const [selected, setSelected] = useState([]);

  // useEffect(() => {
  //   console.log(companyId)
  // }, [companyId])
  


  const valueRenderer = (selected) => {
    if (!selected.length) {
      return `${subscriberDetails.is_virtual_acc === true ? 'Select Hotseat' : 'Select Workstation'}`;
    }

    return selected.length === 1
      ? `${selected[0].label} `
      : selected.map(({ label }) =>  label + ',');
  };

  const selectChange = (data) =>{
    
    const temp=data.map((data) => data.value)
    setIDs(temp)
    setSelected(data)
  }  


  
return (
  <Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} size={props.size} loader={loader}>
     <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>
     <div style={{minHeight:"400px"}}>
          
{/* <FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Company Name" sm={3}>
        Company Name
    </Label>
    <Col sm={9}>
    <select className="form-control"
                  {...register("company", {
                    required: true,
                    onChange:(event) =>  {setCompanyId(event.target.value);}

                  })}>
 <option value="">      --Select Company--</option>
                  {company.map((item,index) => {
                    return <option key={index} value={item.id}>{item.company_name}</option>
                  })}
    </select>
    
        {errors.company?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col> */}
{/* </FormGroup> */}

    
    <MultiSelect
      options={options}
      value={selected}
      onChange={selectChange}
      labelledBy="Select Workstation"
      hasSelectAll={false}
      valueRenderer={valueRenderer}
    />
  
  {selected.length !== 0 ?
  <div style={{padding:"20px"}}>
  <p>Selected Workstations :</p>
 
   {selected.map((data) =>
    <div className='card__tiles' key={data.id}>
  <Card key={data.id} >
  <CardBody>
    <div style={{display:'flex',justifyContent:'space-between'}}>
  <table cellSpacing="0" cellPadding="6">
                  <tbody>
                  <tr>
                      <td>Workstation No</td>
                      <td> : </td>
                      <td>{data.workstation_no}</td>
                    </tr>
                    <tr>
                      <td>Workstation Name</td>
                      <td> : </td>
                      <td>{data.label}</td>
                    </tr>
                    </tbody>
                    </table>
                    <table cellSpacing="0" cellPadding="6">
                      <tbody>
                    <tr>
                      <td>Workstation Location</td>
                      <td> : </td>
                      <td>{data.workstation_location}</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td> : </td>
                      <td>{data.description}</td>
                    </tr>
                   
                  </tbody>
                </table>
                </div>
  </CardBody>
  </Card>
  </div>
   )}
 
  
 
  </div>
  : null }
  </div>
  
<ModalFooter>
                          <Button
                              type="submit"
                              
                              className="w-xs waves-effect waves-light me-1"
                              // onClick={() => {
                              //     onSubmit();
                              // }}
                          >
                              {waitingForAxios ? (
                                  <Spinner animation="grow" size="sm" />
                              ) : (
                                  "Submit"
                              )}
                              
                          </Button>
                          <Button
                              type="button"
                              color="danger"
                              className="w-xs waves-effect waves-light me-1"
                              onClick={props.toggle}
                          >
                              Close
                          </Button>


</ModalFooter>
</Form>
      </Modals>
)
}


export default Assign_Workstation