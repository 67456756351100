import React from "react";
import { Card, CardBody} from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";

export default function SalesAnalytics(props) {
  // const [salesAnalytics, setSalesAnalytics] = useState({
  //   series: [42, 26, 15],
  //   options: {
  //     labels: ["Product A", "Product B", "Product C"],
  //     plotOptions: {
  //       pie: {
  //         donut: {
  //           size: "75%",
  //         },
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     legend: {
  //       show: false,
  //     },
  //     colors: ["#B23C3C", "#7F7F7F"],
  //   },
  // });

  return (
    <React.Fragment>
      <Card style={{ minHeight: "491px",height:'100px'}}>
        <CardBody>
          {/* <div className='float-end' >
            <select className='form-select form-select-sm'>
              <option defaultValue>Hard Waste</option>
              <option value='1'>Liquid Waste</option>
              <option value='2'>Pieces</option>
            </select>
          </div> */}
          <h4 className='card-title mb-4'>{props.name}</h4>

          <div id='donut-chart' className='apex-charts'>
            <ReactApexChart
              options={props.salesAnalytics.options}
              series={props.salesAnalytics.series}
              type='donut'
              height='250'
            />
          </div>
          <div 
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr",gridTemplateRows:"1fr 1fr 1fr",gap:"10px 10px" }}>
            {props.salesAnalytics.options?.labels?.map((item, index) => (
              
              
            
                <div className='text-center mt-4' style={{ display: "flex",position:'relative',left:'30%' }} key={item}>
                  <p className='mb-2 text-truncate' >
                    <i
                      className='mdi mdi-circle  font-size-10 me-1'
                      style={{
                        color: props.salesAnalytics.options.colors[index],
                      }}></i>
                    {item}
                  </p>
                </div>
                
              
            ))}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
