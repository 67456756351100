import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import logodark from "../../../assets/images/THE-WORKBOOK-LOGO.png";
import { publicAxios } from "../../../axiosInstance";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import AbaciLoader from "../../../components/AbaciLoader/AbaciLoader";
import { setLogin, setLogOut } from "../../../store/auth";
import { useNavigate } from "react-router-dom";
import { APP_NAME } from "../../../helpers/Constants";
import Error from "../../../helpers/Error";
import { Toastr } from "../../../helpers/Alert";
import axios from "axios";


function Login() {
  const [waitingForAxis, setWaitingForAxis] = useState(false);
  const [error, setError] = useState(null);
  const auth = useSelector((state) => state.authSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const token = Cookies.get("token");
    if (typeof token === "undefined") {
      dispatch(setLogOut())
    }
    else{
      const url = "users_api/profile";
      axios
        .get(url)
        .then((response) => {
          if(response.data.user_status === "Activated"){
            dispatch(setLogin({user: response.data}));
            navigate("/dashboard");
          }
          else {
            Cookies.remove("token");
            dispatch(setLogOut());
          }
        })
        .catch((error)=>{
          if (!error.response) {
            setError(502);
          } else {
            const errorStatusTemp = error.response.status;
            if (errorStatusTemp === 401) {
              Cookies.remove("token");
              dispatch(setLogOut())
              navigate("/login");
            } else {
              dispatch(setLogOut())
              navigate("/login");
              // setError(errorStatusTemp)
            }
          }
        }) 
      
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    setWaitingForAxis(true);
    const url = "users_api/login";
    publicAxios
      .post(url, data)
      .then((response) => {
        setWaitingForAxis(false)
        dispatch(setLogin(response.data))
        navigate("/dashboard");
      })
      .catch((error) => {
        dispatch(setLogOut());
        let errorMessage = null
        if (error.response?.status === 403) {
          errorMessage = error?.response?.data?.error
        }
        else {
          errorMessage = "Error occured, please check your connection and try again!"
        }
        setWaitingForAxis(false);
        Toastr("error", errorMessage)
      });
  };
  if (error !== null) {
    return (<Error error={error} />)
  }
  if (auth.loggedIn === null || auth.loggedIn) {
    return <AbaciLoader />;
  }
  else {
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100" style={{marginTop:'-15px'}}>
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/">
                                <img
                                  src={logodark}
                                  alt=""
                                  height="18"
                                  className="auth-logo logo-dark mx-auto mb-4"
                                />
                                <img
                                  src={logodark}
                                  alt=""
                                  height="18"
                                  className="auth-logo logo-light mx-auto mb-4"
                                />
                              </Link>
                            </div>
                            <h4 className="font-size-18 mt-5">Welcome !</h4>
                            <p className="text-muted">
                              Sign in to continue to WorkBook
                            </p>
                          </div>
                          {false ? (
                            <Alert color="danger">{"halo"}</Alert>
                          ) : null}
                          {/* {multiTenantFound
                            ?
                            <>
                              <p className="text-muted">
                                Multiple account found
                                <br />
                                Please click on the desired account to proceed
                              </p>
                              {
                                waitingForAxis ?
                                  <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  :
                                  <div className="p-2 mt-5" style={{ maxHeight: "300px", overflow: "auto", cursor: "pointer" }}>
                                    {multiTenantList.map((item, index) => {
                                      return (
                                        <div key={index} className="border border-primary"
                                          onClick={() => checkLogin(item)}>{item}</div>
                                      )
                                    })}
                                  </div>
                              }
                            </>

                            : */}
                             <div className="p-2 mt-5">


                              <form
                                className="form-horizontal"
                                onSubmit={handleSubmit(onSubmit)}
                              >
                                {/* register your input into the hook by invoking the "register" function */}
                                <div className="auth-form-group-custom mb-4">
                                  <i className="ri-user-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="username">Username</Label>
                                  <input
                                    className="form-control"
                                    placeholder="Enter username"
                                    {...register("username", {
                                      required: true,
                                      pattern:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    })}
                                  />

                                  {errors.username?.type === "required" && (
                                    <span
                                      style={{
                                        color: "#bf3d48",
                                      }}
                                    >
                                      This field is required
                                    </span>
                                  )}
                                  {errors.username?.type === "pattern" && (
                                    <span
                                      style={{
                                        color: "#bf3d48",
                                      }}
                                    >
                                      Please provide a valid email address
                                    </span>
                                  )}
                                </div>
                                <div className="auth-form-group-custom mb-4">
                                  <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="username">Password</Label>
                                  <input
                                    className="form-control"
                                    placeholder="Enter password"
                                    type="password"
                                    {...register("password", {
                                      required: true,
                                    })}
                                  />
                                  {errors.password && (
                                    <span
                                      style={{
                                        color: "#bf3d48",
                                      }}
                                    >
                                      Please provide your password
                                    </span>
                                  )}
                                </div>

                                <div className="mt-4 text-center">
                                  <Button 
                                  
                                    
                                    className="w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    {waitingForAxis ? (
                                      <Spinner animation="grow" size="sm" />
                                    ) : (
                                      "Log In"
                                    )}
                                  </Button>
                                  {/* <p style={{ color: "red" }}>{responseError}</p> */}
                                </div>
                                <div className="mt-4 text-center">
                                  <Link to="/forgotpassword" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                                </div>
                              </form>
                            </div>
                          {/* } */}
                          <div className="mt-5 text-center">
                            <p>
                              © 2022 {APP_NAME}.
                            </p>
                          </div>
                         
                       
                          
                        </div>
                        {/* <div className="mt-5" style={{display:"flex",justifyContent:"center",flexDirection:'column'}}>
                <footer style={{display:"flex",justifyContent:"center",}}>
                <Link to="/termsandconditions" >Terms & Conditions |</Link>  &nbsp; <Link to="/privacypolicy"> Privacy Policy |  </Link> &nbsp;<Link to="/refundpolicy"> RefundPolicy </Link><br />
                
                        </footer>
                        <footer style={{marginTop:"2px",display:"flex",justifyContent:"center"}}>
                <Link to="/aboutus">AboutUs |</Link> &nbsp; <Link to="/contactus"> Contact Us </Link> <br />
                
                        </footer>
                        </div> */}
                      </Col>
                    </Row>
                    
                  </div>
                </div>
                <footer style={{marginTop:"-66px",display:"flex",justifyContent:"center"}}>
                <Link to="/termsandconditions" >Terms & Conditions |</Link>  &nbsp; <Link to="/privacypolicy"> Privacy Policy |  </Link> &nbsp;<Link to="/refundpolicy"> RefundPolicy </Link><br />
                
                        </footer>
                        <footer style={{marginTop:"2px",display:"flex",justifyContent:"center"}}>
                        <Link to="/servicepricing">Service Pricing |</Link> &nbsp;   <Link to="/aboutus">AboutUs |</Link> &nbsp; <Link to="/contactus"> Contact Us </Link> <br />
                
                        </footer>
              </Col>
              <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;