import React, { useEffect,useRef, useState } from 'react'
import { Card, CardBody, Col, Container } from 'reactstrap'
import fileDownload from 'js-file-download'

import Breadcrumbs from '../../components/Common/Breadcrumb'
import { APP_NAME, ranges } from '../../helpers/Constants';
// import {FilterList } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { tableIcons } from '../../helpers/MaterialIcons';
// import { useDispatch, useSelector } from 'react-redux';

import { ErrorAlert, Toastr } from '../../helpers/Alert';
import { pdfGenerator } from '../../helpers/PDFReportGenerator';

// import { AddLoginReport } from '../../store/report';
import axios from 'axios';
import Moments from '../../helpers/Moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { IconButton, Tooltip } from '@material-ui/core';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
// import { textAlign } from '@mui/system';


export default function LoginDetails () {
    const [data,setData] = useState([])
    const [urlBackup, setUrlBackup] = useState('')
    const [totalRecordsCount, setTotalRecordsCount] = useState(0)
    const [downloadIndicator,setDownloadIndicator] = useState(false)

    const tableRef=useRef();
    const [date,setDate]=useState({})
    // const [loader,setLoader] = useState(true)
    // const loginDetails=useSelector((state) => state.ReportSlice.loginReport)
    // const dispatch = useDispatch()
    // const [filterEnabled, setFilterEnabled] = useState(false);
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "Login Details", link: "#" },
        ],
      });
  
    useEffect(() => {

      const abortController=new AbortController();


      // const url='/users_api/login_details'
      // axios.get(url)
      // .then((response) => {
      //   dispatch(AddLoginReport(response.data))
      //   setLoader(false)
      // })
      // .catch((error) => {
      //   setLoader(false)
      // })

      // // dispatch(AddLoginReport([{id:1,operator_id:8765,emp_id:1001,attempted_date:'12/12/2022',extn_no:593,first_name:"David",last_name:"Jacobs",designation:"Officer",email:"david@email.com",phone_no:"0501231000",ip_address:'49.37.224.126:51820',User_type:"Admin",address:"789 Monreo Avenue,Fort Myres,Florida",reason:'Invalid Password',status:"Failed"},{id:2,attempted_date:'12/12/2022',ip_address:'49.37.224.126:5182',operator_id:2678,emp_id:1002,extn_no:574,first_name:"Akhil",last_name:"Prem",designation:"Accountant",email:"Akhil@gmail.com",phone_no:"0501231001",User_type:"Admin",address:"789 Fort Myres,Monreo Avenue,Florida",reason:'User Not Found (Akhil@gmail.com)',status:"Failed"},{id:3,attempted_date:'12/12/2022',reson:'Active User',ip_address:'49.37.224.126:51820',operator_id:3567,emp_id:1003,extn_no:567,first_name:"Sharon",last_name:"VArghese",designation:"Chief Officer",email:"Sharon@email.com",phone_no:"05012310021",User_type:"User",address:"789 Monreo Avenue,Fort Myres,Florida",reason:'Active User',status:"Success"}]))
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Login Details", link: "#" },
              ],
            }  
          )

          return (() => {
            abortController.abort();
            setData([]); 
          })

           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

      const handleDate = (start,end) => {

    if(start=== "cancel"){
      setDate({})
      tableRef.current.onQueryChange({page: 0});
    }
    else{
    const firstdate=moment(start).format('YYYY-MM-DD')
    const enddate=moment(end).format('YYYY-MM-DD')

    setDate({start_date: firstdate, end_date: enddate})

    tableRef.current.onQueryChange({page: 0});
    }
  }

  const exportData = (downloadType) => {
    const url = `${urlBackup}&${downloadType}`
    setDownloadIndicator(true)
    if (downloadType === "pdf_download") {
      if (totalRecordsCount >= 2000) {
        ErrorAlert("The maximum records to export as PDF is 2000. Please apply filter to reduce the number of records")
        setDownloadIndicator(false)
        return
      }
   
      axios({
        url: url,
        method: 'GET',
        // responseType: 'blob', // Important
      }).then((response) => {
        setDownloadIndicator(false)
        // console.log(response.data?.body)
        const headRowData = [response.data?.header]
        const bodyRowData = response.data?.body
        const reportName = "Login Details"
        var doc = pdfGenerator(headRowData, bodyRowData, reportName)
        doc.save("Login Details.pdf");
        console.log('')
        // tableRef.current.onQueryChange();
       
      }).catch(error => {
        Toastr("error", "Error downloading the file !")
        setDownloadIndicator(false)
      });
    }
    else {
      if (totalRecordsCount >= 5000) {
        ErrorAlert("The maximum records to export as CSV is 5000. Please apply filter to reduce the number of records")
        setDownloadIndicator(false)
        return
      }
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // Important
      }).then((response) => {
        let fileName = "Login Details.csv"
        fileDownload(response.data, fileName);
        setDownloadIndicator(false)
        // tableRef.current.onQueryChange();
      }).catch(error => {
        Toastr("error", "Error downloading the file !")
        setDownloadIndicator(false)
      });
    }
  }

    // useEffect(() => {
    // if(loginDetails !== null){
    //   const tempData = loginDetails.map((data) => ({
    //     ...data,
    //     username:data?.user?.full_name ? data.user.full_name : 'No Data',
    //     user_email:data?.user?.email ? data.user.email : 'No Data',
    //     created_date:data?.created_date ? Moments(data.created_date,'datetime')  : 'No Data'
    //   }))
    //   setData(tempData)
    // }
    // }, [loginDetails])
    

    const columns =
  [
   
    
    
    { title: "Name", field: "full_name" },
    { title: "Email Id", field: "email"},

    {title:'Ip Address' , field:'ip_address'},
    
  
   
    { title:'Attempted Date', field: 'created_date'},
    { title:'Reason', field: 'reason'},
    {title:'Status' , field:'status'},

    // { title: "Contract Start", field: "contract_start"},
    // { title: "Contract End", field: "contract_end"},
  ]

    
    
  return (
    <React.Fragment>
          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Login Details"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
              
             
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >

              <div style={{display:'flex',marginBottom:'-55px',width:'60%'}}>
            <Col xl={1}>
              <DateRangePicker  onCallback={(startdate,enddate) => handleDate(startdate._d,enddate._d)}
                  onCancel={(data)=> handleDate(data.type)}

                  initialSettings={{ranges:ranges,
                                      opens:'right',
                                      startDate:moment().subtract(1, 'days'),
                                      // showCustomRangeLabel:true,
                                      // showDropdowns:true,
                                      endDate: moment(),
                                      maxDate:moment()
                                    }}>
  <IconButton aria-label="delete" color={Object.keys(date).length !== 0 ? 'primary' : 'default'} style={{zIndex:'1'}}>
    <Tooltip title='Show Calendar'>
  <CalendarMonthIcon />
  </Tooltip>
</IconButton>
  </DateRangePicker>

            </Col>
            <div  style={{zIndex:'1',marginBottom:'-40px' }}>
             <p style={{marginTop:'17px',paddingRight:'20px'}}>{Object.keys(date).length !== 0 ?  `Showing Login Details from ${date.start_date} to ${date.end_date}` : 'Showing All Login Details'}</p>
            </div>
            </div>
           
           
            
       
          
           
        
         
                      <MaterialTable
                        title=" "
                        columns={columns}
                       
                        // isLoading={loader}
                        tableRef={tableRef}
                        data={ (query) => {
                          // console.log(query)
                          return (new Promise((resolve, reject) => {
                            let orderBy = ""
                            // let search =''
                            if (query.orderBy) {
                              orderBy = query.orderDirection === "asc" ? `&ordering=-${query.orderBy?.field}` : `&ordering=${query.orderBy?.field}`
                            }
                            // if(query.search){
                            //   search=query.search === 'Discharged' ? 'Dumped' : query.search
                            // }

                            let url = `/users_api/login_details?limit=${query.pageSize}&offset=${query.pageSize * query.page}&search=${query.search}${orderBy}`
                            if (Object.keys(date).length !== 0){
                              url +=`&start_date=${date.start_date}&end_date=${date.end_date}`
                            }
                            
                            setUrlBackup(url)
                            axios.get(url)
                              .then((response) => {
                                setTotalRecordsCount(response.data?.count)
                                // tableRef.current.dataManager.changePageSize(query.pageSize)
                                // console.log(response.data.results)
                                const DoorAccessList = response.data?.results
                                // dispatch(addGtcc(response.data.results))
                                const TempData = DoorAccessList.map((data) => ({
                                  ...data,
                                  full_name:data?.user?.full_name ? data.user.full_name : 'No Data',
                                  email:data?.user?.email ? data.user.email : 'No Data',
                                  created_date:data?.created_date ? Moments(data.created_date,'datetime')  : 'No Data'
                                  
                          
                                }))
                                resolve({
                                  data: TempData,
                                  page: response.data?.results.length === 0 ? 0 :query.page,
                                  totalCount: response.data?.count,
                                })
                              })
                              .catch((error) => {
                                // console.log('rejected')
                                reject({
                                  data: [],
                                  page: query.page,
                                  totalCount: 0
                                })
                              })
                          }))
                        }}
                        isLoading={downloadIndicator}
                        actions={[
                          // {
                          //   icon: FilterList,
                          //   tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                          //   isFreeAction: true,
                          //   onClick: (event) => { setFilterEnabled(state => !state) }
                          // },

                          
                          // {
                          //   icon: VisibilityOutlinedIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                          // },
                        //   {
                        //     icon: Edit,
                        //     tooltip: 'Edit Client',
                        //     onClick: (event, rowData) => {
                        //       // console.log(rowData)
                        //       // setEditClientModalShow(true)
                        //       // setItemToBeEdited(rowData)
                        //     }
                        //   },
                        //   rowData => ({
                        //     icon: DeleteOutline,
                        //     tooltip: 'Delete Client',
                        //     onClick: (event, rowData) => {
                        //       Swal.fire({
                        //         title: 'Are you sure?',
                        //         text: "You won't be able to revert this!",
                        //         icon: 'warning',
                        //         showCancelButton: true,
                        //         confirmButtonColor: '#3085d6',
                        //         cancelButtonColor: '#d33',
                        //         confirmButtonText: 'Yes, delete it!'
                        //       }).then((result) => {
                        //         if (result.isConfirmed) {
                        //           // deleteClientData(rowData?.id)

                                
                        //         }
                        //       })
                        //       // setDeleteModal({ id: rowData?.id, toggle: true });
                        //     },
                        //     disabled: rowData.birthYear < 2000
                        //   })
                        ]}
                        options={{
                          exportFileName:'Login Details',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          debounceInterval: 500,
                          exportCsv: async () => exportData("csv_download"),
                          exportPdf: async () => exportData("pdf_download"),
                          // filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
                
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

