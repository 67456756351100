import React, { useEffect, useState } from 'react'

import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Spinner,Card, CardBody} from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import Modals from '../../../components/Modal';
// import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
import Error from '../../../helpers/Error';
// import axios from 'axios';
// import { AddNewClientUser } from '../../../../store/user';
import { MultiSelect } from "react-multi-select-component";
import axios from 'axios';
import { ErrorAlert, SuccessAlert, Toastr } from '../../../helpers/Alert';
import { UpdateRfidCard } from '../../../store/rfidcard';

function RFidCardBulkAssign(props) {
  const [options,setOptions] = useState([])
  const [loader,setLoader] = useState(true)
  const rfidCard=useSelector((state) => state.RfidSlice.rfidCard)
  const [selected, setSelected] = useState([]);
  const [waitingForAxios,setWaitingForAxios] = useState(false)
  const [ids,setIDs] = useState([])
  const [company,setCompany] = useState([])
  const [companyId,setCompanyId] = useState('')
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
   
    formState: { errors },
  } = useForm();

  useEffect(() => {

    const url='/workspace_app/limited_subscriber_list'
    axios.get(url)
    .then((response) => {
      
      const temp=[]
      response.data.map((data) => {
        if(data.status === "Activated" ){
            temp.push({...data})
        }
        return(temp)

      })
      setCompany(temp)
      setLoader(false)
    })
    .catch((error) => {
      setLoader(false)
    })
  }, [])

  useEffect(() => {
    if(rfidCard !== null){
        const temp=[]
        // const temp=rfidCard.filter((data) => data.company === null)
        rfidCard.map((data) => {
            if(data.company === null){
                temp.push({...data,label:`${data.friendly_name} (${data.tag_id})`,value:data.id})
            }
            return(temp)
        })
        // console.log(temp)
        setOptions(temp)
    }
  }, [rfidCard])
  

  const onSubmit = () =>{
      if(ids.length !==0){

      setWaitingForAxios(true)

      const url='/workspace_app/assign_rfid_company'

      axios.post(url,{company:companyId,rfid_cards:ids})
      .then((response) =>{

          dispatch(UpdateRfidCard(response.data))
          setWaitingForAxios(false)
          SuccessAlert("Rfid Assigned Successfully")
          props.toggle()

      })
      .catch((error) =>{
       
            const error_msg=Error(error)
            ErrorAlert(error_msg)
          
          setWaitingForAxios(false)
      })
    }
    else{
      Toastr("warning",'Please Select An Rfid Card For Assigning')
    }

  }
  
  
  

  // useEffect(() => {
  //   console.log(companyId)
  // }, [companyId])
  


  const valueRenderer = (selected) => {
    if (!selected.length) {
      return "Select RfidCard";
    }

    return selected.length === 1
      ? `${selected[0].label} `
      : selected.map(({ label }) =>  label + ',');
  };

  const selectChange = (data) =>{
    
    const temp=data.map((data) => data.value)
    setIDs(temp)
    setSelected(data)
  }  


  
return (
  <Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} size={props.size} loader={loader}>
    <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>
     <div style={{minHeight:"400px"}}>
     
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Company Name" sm={3}>
        Company Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
    <select className="form-control"
                  {...register("company", {
                    required: true,
                    onChange:(event) =>  {setCompanyId(event.target.value);}

                  })}>
 <option value="">      --Select Company--</option>
                  {company.map((item,index) => {
                    return <option key={index} value={item.id}>{item.company_name}</option>
                  })}
    </select>
    
        {errors.company?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

    
    <MultiSelect
      options={options}
      value={selected}
      onChange={selectChange}
      labelledBy="Select Workstation"
      hasSelectAll={false}
      valueRenderer={valueRenderer}
    />
  
  {selected.length !== 0 ?
  <div style={{padding:"20px"}}>
  <p>Selected Workstations :</p>
 
   {selected.map((data) =>
    <div className='card__tiles' key={data.id}>
  <Card key={data.id} >
  <CardBody>
    <div style={{display:'flex',justifyContent:'space-between'}}>
  <table cellSpacing="0" cellPadding="6">
                  <tbody>
                  <tr>
                      <td>Tag No</td>
                      <td> : </td>
                      <td>{data.tag_id}</td>
                    </tr>
                    <tr>
                      <td>Card Name</td>
                      <td> : </td>
                      <td>{data.label}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td> : </td>
                      <td>{data.status}</td>
                    </tr>
                    </tbody>
                    </table>
                   
                </div>
  </CardBody>
  </Card>
  </div>
   )}
 
  
 
  </div>
  : null }
  </div>
  
<ModalFooter>
                          <Button
                              type="submit"
                              
                              className="w-xs waves-effect waves-light me-1"
                              // onClick={() => {
                              //     onSubmit();
                              // }}
                          >
                              {waitingForAxios ? (
                                  <Spinner animation="grow" size="sm" />
                              ) : (
                                  "Submit"
                              )}
                              
                          </Button>
                          <Button
                              type="button"
                              color="danger"
                              className="w-xs waves-effect waves-light me-1"
                              onClick={props.toggle}
                          >
                              Close
                          </Button>


</ModalFooter>
</Form>
      </Modals>
)
}


export default RFidCardBulkAssign