import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    accessGroup:null,
}
 
const AccessGroupSlice=createSlice({

    name:"accessGroup",
    initialState,
    reducers:
    {

        AddAccessGroup(state,action){
            state.accessGroup = action.payload
        
        },
        AddNewAccessGroup(state,action){
            state.accessGroup = [...state.accessGroup,action.payload]
        },
        EditAccessGroup(state,action){
            state.accessGroup = state.accessGroup.map((data)=>

            data.id === action.payload.id ?action.payload :data
            )

        },

        DeleteAccessGroup(state,action){
            state.accessGroup= state.accessGroup.filter((data)=>

            data.id !== action.payload
            )

        }
    }
})
export const{AddAccessGroup,AddNewAccessGroup,EditAccessGroup,DeleteAccessGroup}=AccessGroupSlice.actions;
export default AccessGroupSlice.reducer;