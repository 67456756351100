import React from "react";
import { Link } from "react-router-dom";
// import { Container, Row, Col } from "reactstrap";


//Import images
import errorImg from "../../../assets/images/error-img.png";

export default function ExpiredLink(props) {
  return (
    <React.Fragment>
     <div className="text-center" style={{position:"relative",top:"200px"}}>
                <h1 className="fw-bold text-error">
                  5{" "}
                  <span className="error-text">
                    0<img src={errorImg} alt="" className="error-img" />
                  </span>{" "}
                  0
                </h1>

                <h2 className="text-uppercase">Looks like you got lost</h2>
                <p className="text-danger h5">
                {props.error ? props.error : "The page your looking for doesnt exist or has been moved."}
                </p>
                <Link
                to="/"
                className="btn btn-primary waves-effect waves-light mt-2"
                >
                  Back to Home
                </Link>
               
            
              </div>
         
    </React.Fragment>
  );
}
