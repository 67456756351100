import axios from "axios";
import {baseURL} from './helpers/baseURL'
import Cookies from "js-cookie";

export const publicAxios = axios.create({
  baseURL: baseURL,
  timeout: 5000,
  withCredentials: true
});

const token = Cookies.get("token");
export const authAxios = axios.create({
  baseURL: baseURL ,
  timeout: 5000,
  headers: {
              "content-type": "application/json",  
              accept: 'application/json', 
              Authorization: "Bearer "+ token 
            },
  withCredentials: true
});


export default publicAxios;
