import React, { useEffect, useState } from 'react'
import Modals from '../../../../../../components/Modal';
import { useForm } from "react-hook-form";
import { Button,  Col, Form, FormGroup, Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorAlert ,SuccessAlert} from '../../../../../../helpers/Alert';
import Error from '../../../../../../helpers/Error';
import axios from 'axios';
import { DeleteSubscriberWorkStation, EditSubscription, EndSubscriberSubscription } from '../../../../../../store/subscriber';



function EditSubscriptionDetails(props) {
    // const [options,setOptions] = useState([])

    // const [ids,setIDs] = useState([])
    // const [subscription,setSubscription] = useState([])

    const [waitingForAxios,setwaitingForAxios] = useState(false)
    const dispatch = useDispatch()
    // const [loader,setLoader] = useState(true)
    const subscriberWorkStation =useSelector((state)=>state.SubscriberSlice.subscriberWorkStation)
    const subscriberSubscription=useSelector((state)=>state.SubscriberSlice.subscriberSubscription)

    const {
        register,
        handleSubmit,
        reset,
        
        formState: { errors },
      } = useForm();
    
    //   useEffect(() => {
    //     console.log(props)
    //     const temp={...props.item}
    //     reset(temp)
    //     // console.log(temp)
     
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, [props])
      

    const onSubmit = (data) =>{

        let formData = new FormData(); //formdata object
    //   formData.append("user_type", data.user_type);
        // formData.append("plan_name", data.plan_name);
        // formData.append("amount", data.amount);
        // formData.append("description", data.description);
        // formData.append("duration", data.duration);
       formData.append("subscriber_id", props.item.id);

       formData.append("subscription_end_date", data.subscription_end);

        setwaitingForAxios(true)
        
        const url=`/workspace_app/modify_subscription_end_date`

        axios.post(url,formData)
        .then((response) =>{
            // let msg=response.data.message
            setwaitingForAxios(false)
            dispatch(EditSubscription(response.data))
            // dispatch(EndSubscriberSubscription())
            dispatch(DeleteSubscriberWorkStation(props.item.workstation_id))
            SuccessAlert('Subscription Details Updated Succesfully')
            props.toggle();
           // console.log(response.data)
        })
        .catch((error) => {
            setwaitingForAxios(false)
            
            let error_msg=Error(error)
            ErrorAlert(error_msg)
        })
       
    }

  return (
    <Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} size={props.size} >
    <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="End Date" sm={3}>
    Subscription End Date <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder=" End Date"
            type="date"
            
            // min={new Date().toISOString().split("T")[0]}
            className="form-control"
            {...register("subscription_end", {
                required: true,
            })}
        />
        {errors.subscription_end?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>


          
  <ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>
  
  
  </ModalFooter>
      </Form>
      </Modals>



  )
}

export default EditSubscriptionDetails