import React, { useEffect, useRef, useState } from 'react'
import {  Card, CardBody, Col, Container } from 'reactstrap'
import { APP_NAME } from '../../../../helpers/Constants';
import {  Edit,FilterList } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../helpers/MaterialIcons';
import { useDispatch, useSelector } from 'react-redux';
import { AddClientUser, AddSubscriberUser } from '../../../../store/user';
import axios from 'axios';

import VisibilityOff from '@material-ui/icons/VisibilityOff';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import EditSubscriberUser from './EditSubscriberUser';


export default function SubscriberUsers () {

    const tableRef=useRef();
    const [data,setData] = useState([])
    const [loader,setLoader] = useState(true)
    const [editModalShow,setEditModalShow] = useState(false)
    const [itemToBeEdited,setItemToBeEdited] = useState(null)
    const user=useSelector((state) => state.UserSlice.subscriberStaff)
    const profile = useSelector((state) => state.authSlice.profile)
    const [deletedData,setDeletedData] = useState(false)
    const dispatch = useDispatch()
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "Subscriber Users", link: "#" },
        ],
      });

    const editModalToggle = () => {
      setEditModalShow(!editModalShow)
    }
  
    useEffect(() => {
      
      const abortController=new AbortController();

      const url='/users_api/subscriber_user'
      axios.get(url)
      .then((response) => {
        dispatch(AddSubscriberUser(response.data))
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
      })

      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Subscriber Users", link: "#" },
              ],
            }  
          )
          return (() => {
            abortController.abort();
            setData([]); 
          })

           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const subscriberHandler=()=>{
        setLoader(true)
        // tableRef.current.onQueryChange({});
        if(deletedData === false){
          const url=`/users_api/deleted_subscriber_user`
          //   const url='/workspace_app/staffs_subscriber/16'
            axios.get(url,)
            .then((response) => {
              dispatch(AddSubscriberUser(response.data))
              setDeletedData(true)
              setLoader(false)
            })
            .catch((error) => {
              setLoader(false)
            })
      
      
        }
        else{
           setLoader(true)
          const url='/users_api/subscriber_user'
          //   const url='/workspace_app/staffs_subscriber/16'
            axios.get(url)
            .then((response) => {
              dispatch(AddSubscriberUser(response.data))
              setLoader(false)
              setDeletedData(false)
            })
            .catch((error) => {
              setLoader(false)
            })
        }
      
      
       }
      



    useEffect(() => {
      // console.log(user)
    if(user !== null){
      const tempData = user.map((data,index) => ({
        ...data,
        sl_no:index+1
      }))
      setData(tempData)
    }
    }, [user])
    

    const columns =
  [
   
    
    { title: "Sl No", field: "sl_no"},
    { title: "First Name", field: "first_name" },
    { title: "Last Name", field: "last_name",
render:rowData=><>{rowData.last_name ? rowData.last_name : 'No Data'}</>
},
    {title:'Phone No' , field:'contact_number'},
    
  
   
    // {title:'Address' , field:'address'},
    { title: "Email Id", field: "email",
    render: rowData => <>{rowData.email && rowData.user_status === 'Invited' ? <span >{rowData.email} <p className="text-danger">(Activation Pending)</p></span> : rowData.email ? rowData.email :  'No Data'}</>  

  },
    // { title:'User Type', field: 'user_type'},
    {title:'Status' , field:'user_status'},

    // { title: "Contract Start", field: "contract_start"},
    // { title: "Contract End", field: "contract_end"},
  ]

    
    
  return (
    <React.Fragment>

    {editModalShow &&
      <EditSubscriberUser
      isOpen={editModalShow}
      toggle={editModalToggle}
      title={'Edit Subscriber User'}
      item={itemToBeEdited}
        />
      }


          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Subscriber Users"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
             
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={columns}
                        data={data}
                        isLoading={loader}
                        tableRef={tableRef}
                        

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },
                          {
                            icon: deletedData === false ?VisibilityOutlinedIcon:VisibilityOff ,
                            tooltip:deletedData === false ?'View Deteted Users':'View Active Users ',
                            isFreeAction: true,
                            onClick: (event) => {
                            
                              subscriberHandler();
                            }
                            
                          },

                          
                          // {
                          //   icon: VisibilityOutlinedIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                          // },
                          {
                            icon: Edit,
                            tooltip: 'Edit user',
                            onClick: (event, rowData) => {
                              // console.log(rowData)
                              // setEditClientModalShow(true)
                              setItemToBeEdited(rowData)
                              editModalToggle();
                            },hidden:profile.user_type ==="User"
                          },
                         
                        ]}
                        options={{
                          exportFileName:'Users List',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
                
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

