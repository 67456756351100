import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container} from 'reactstrap'
// import Swal from 'sweetalert2';
import { APP_NAME } from '../../../../helpers/Constants';
// import { DeleteOutline, Edit,FilterList } from '@material-ui/icons';
// import MaterialTable from 'material-table';
// import { tableIcons } from '../../../../helpers/MaterialIcons';
// import { useDispatch, useSelector } from 'react-redux';
import './Scheduler.css'

// import LockIcon from '@material-ui/icons/Lock';
// import Unlock from "@material-ui/icons/LockOpen"
import axios from 'axios';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
// import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
// import Error from '../../../../helpers/Error';
// import Moments from '../../../../helpers/Moment';
// // import AddSchedule from './ManageSchedule';
// // import { AddTiming } from '../../../../store/timing';
// import moment from 'moment';
import { useParams } from 'react-router-dom';
import AddTimings from './AddTiming';


export default function SchedulerDetails () {

    const { id } = useParams(); 
    const [addModalShow, setAddModalShow] = useState(false)
    const [selected, setSelected] = useState([])
    const [days,setDays] = useState([{'Monday':[]},{'Tuesday':[]},{'Wensday':[]},{'Thurday':[]},{'Friday':[]},{'Saturday':[]},{'Sunday':[]}])
    // const [items, setItems] = useState([ 'Monday', 'Tuesday', 'Wenesday', 'Thursday', 'Friday', 'Saturday','Sunday'])
    const [items, setItems] = useState([])
    const [ctrlPressed, setCtrlPressed] = useState(false)
    const [shiftPressed, setShiftPressed] = useState(false)
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null)
    const [startAndEndIndexesForShiftSelection, setStartAndEndIndexesForShiftSelection] = useState({
      start: null,
      end: null
    })
    const [dashboardState, setDashboardState] = useState({
      breadcrumbItems: [
        { title: APP_NAME, link: "/" },
        { title: "Scheduler", link: "#" },
      ],
    });

    // const keyFinder = (data) => {
    //   return Object.keys(data)[0]
    // }

    const dataValidator = (datas) => {
      // days.map((data) =>  )

      // [Object.keys(data)[0]].push({shift12:{from:datas.tag_id, to:datas.friendly_name}}) : data)
      // console.log(datas)
      // let key=''
      // let arr = []
      // days.map(data => selected.includes(Object.keys(data)[0]) === true ? 
      // arr.push({[Object.keys(data)[0]]:[{from:datas.tag_id, to:datas.friendly_name}]}) : arr.push(data))


      // -------------------------------------------------------------------------------
      const temp = days.map(data => selected.includes(Object.keys(data)[0]) === true ? 
      {[Object.keys(data)[0]]:[{from:datas.tag_id, to:datas.friendly_name}]} : data)
      // const tmp=temp2.map((data) => console.log(data.Monday,"data"))

      setDays(temp)
      //console.log(temp,'temp2')
      // console.log('tmp',tmp)
    }
      // -------------------------------------------------------------------------------

      useEffect(() => {
      //  console.log(days)
      }, [days])
      

    const addModalToggle = () => {
      setAddModalShow(!addModalShow)
    }

    const detectKeyDown = (e) => {
      if (e.key === 'Control') {
        setCtrlPressed(true)
      }
      if (e.key === 'Shift') {
        setShiftPressed(true)
        if (lastSelectedIndex === null) {
        }
      }
    }
    const detectKeyUp = (e) => {
      if (e.key === 'Control') {
        setCtrlPressed(false)
      }
      if (e.key === 'Shift') {
        setShiftPressed(false)
  
      }
    }
    useEffect(() => {
      
      if (!shiftPressed) {
        // console.log(startAndEndIndexesForShiftSelection.start !== null && startAndEndIndexesForShiftSelection.end !== null)
        // console.log(startAndEndIndexesForShiftSelection.start,startAndEndIndexesForShiftSelection.end)
       // console.log('tst',startAndEndIndexesForShiftSelection)
        if (startAndEndIndexesForShiftSelection.start !== null && startAndEndIndexesForShiftSelection.end !== null) {
          let tempState = [...selected]
          items.map((item, index) => {
            if (index >= startAndEndIndexesForShiftSelection.start && index <= startAndEndIndexesForShiftSelection.end) {
              tempState = [...tempState, item]
              tempState = removeDuplicates(tempState)
            }
            return(tempState)
          })
          setSelected(tempState)
        }
        setStartAndEndIndexesForShiftSelection({
          start: null,
          end: null
        })
        setLastSelectedIndex(null)
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shiftPressed])
  
    const removeDuplicates = (arr) => {
      return arr.filter((item,
        index) => arr.indexOf(item) === index);
    }
  
    useEffect(() => {
      document.addEventListener('keydown', detectKeyDown, false)
      document.addEventListener('keyup', detectKeyUp, false)
      return (() => {
        document.removeEventListener('keydown', detectKeyDown, false)
        document.removeEventListener('keyup', detectKeyUp, false)
      })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const selecthandler = (item, index) => {
      if (shiftPressed) {
        if (lastSelectedIndex !== null) {
          let startIndex = lastSelectedIndex > index ? index : lastSelectedIndex
          let endIndex = lastSelectedIndex < index ? index : lastSelectedIndex
          let temp = {
            start: startIndex,
            end: startIndex,
          }
  
          setStartAndEndIndexesForShiftSelection({
            start: startIndex,
            end: endIndex
          })
          //console.log('startIndex index is', startIndex)
          //console.log('endIndex index is', endIndex)
        }
      }
      else {
        let tempState = [...selected, item]
        tempState = removeDuplicates(tempState)
        setSelected(tempState)
        setLastSelectedIndex(index)
  
      }
    }
  
    useEffect(() => {
     // console.log(selected)
    }, [selected])
    
  
    const deSelecthandler = (e,item, index) => {
      //console.log(item)
      e.preventDefault()
      let tempState = []
      selected.map(innerItem => {
        if (innerItem !== item){
          tempState.push(innerItem)
        }
        return(tempState)
      })
      setSelected(tempState)
    }
  
    useEffect(() => {

      
      const temp=days.map((data) => Object.keys(data)[0])
      setItems(temp)

      const url=`/workspace_app/scheduler/${id}`
      axios.get(url)
      .then((response) => {
        // dispatch(AddTiming(response.data))
       
      })
      .catch((error) => {
      
      })

      // dispatch(AddSubscriber([{id:1,operator_id:8765,company_name:'Noux',emp_id:1001,extn_no:593,first_name:"David",last_name:"Jacobs",designation:"Officer",email:"david@email.com",phone_no:"0501231000",User_type:"Admin",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"},{id:2,operator_id:2678,emp_id:1002,company_name:'Element 8',extn_no:574,first_name:"Akhil",last_name:"Prem",designation:"Accountant",email:"Akhil@gmail.com",phone_no:"0501231001",User_type:"Admin",address:"789 Fort Myres,Monreo Avenue,Florida",status:"Active"},{id:3,operator_id:3567,emp_id:1003,company_name:'Rad',extn_no:567,first_name:"Sharon",last_name:"VArghese",designation:"Chief Officer",email:"Sharon@email.com",phone_no:"05012310021",User_type:"User",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"}]))
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Scheduler Details", link: "#" },
              ],
            }  
          )

           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    
  return (
    <React.Fragment>

      {addModalShow &&
        <AddTimings
        isOpen={addModalShow}
        toggle={addModalToggle}
        title={'Add Timing'}
        fnction={dataValidator}
        />
        } 

        {/* {addModalShow &&
        <AddSchedule
        isOpen={addModalShow}
        toggle={addModalToggle}
        title={'Manage Schedule'}
        />
        } */}

        {/* {addTimingModalShow &&
        <AddTimings
        isOpen={addTimingModalShow}
        toggle={addTimingModalToggle}
        title={'Add Timing'}
        item={itemToBeEdited}
        />
        } */}

          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Scheduler Details"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
               <div
                className="disappearing-button-container-page-top-right" style={{marginRight:"-80px"}}>
                 {selected.length !==0 ? 
                <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={addModalToggle}
                >
                  Add Timing
                </Button>
                :null}
                  </div>
                 
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                  <Container fluid>

    <div className="schedule-items-container">
    
      {
        items.map((item, index) => {
          let itemColor = selected.includes(item) ? ' #F1F1F1' : '#fff'
          if (startAndEndIndexesForShiftSelection.start !== null & startAndEndIndexesForShiftSelection.end !== null) {
            itemColor = index >= startAndEndIndexesForShiftSelection.start && index <= startAndEndIndexesForShiftSelection.end ? ' #F1F1F1' : itemColor
          }
          return (
         <>
         <div  className='schedule-item_main' style={{width:'100%'}}>
            <div
              className='schedule-item'
              onClick={() => selecthandler(item, index)}
              onContextMenu={(e) => deSelecthandler(e, item, index)}
              style={
                {
                  backgroundColor: itemColor
                }}
              key={index}>
              <div className='schedule-title-container'>
              <h5    className='schedule-titles' style={
                {
                  backgroundColor: itemColor
                }}>{item}</h5>
                </div>
                </div>

                <div className='schedule-item-tables'  >
  <Card key={index} style={
      {
        backgroundColor: itemColor
      }}>
  <CardBody>
    <div>
    <table style={{width: '100%'}}>
                     <tbody>
                     <tr>
                       <td>Shift 1</td>
                     </tr>
                     <tr>
                       <td>From</td>
                       <td> : </td>
                       <td>02:30Am</td>
                     </tr>
                     <tr>
                       <td>To</td>
                       <td> : </td>
                       <td>07:20pm</td>
                     </tr>
                     
                    
                   </tbody>
                   </table>
                   <hr />
                   <table style={{width: '100%'}}>
                    <tbody>
                     <tr>
                       <td>Shift 2</td>
                     </tr>
                     <tr>
                       <td>From</td>
                       <td> : </td>
                       <td>02:30Am</td>
                     </tr>
                     <tr>
                       <td>To</td>
                       <td> : </td>
                       <td>07:20pm</td>
                     </tr>
                     
                    
                   </tbody>
                 </table>
                 <hr />
                
                </div>
             
  </CardBody>
  </Card>
  </div>
{/*                                        
<div className='schedule-item-tables' style={
                {
                  backgroundColor: itemColor
                }}>
<table border='1' style={{width: '100%'}}>
                     <tbody>
                     <tr>
                       <td>Shift 1</td>
                     </tr>
                     <tr>
                       <td>From</td>
                       <td> : </td>
                       <td>02:30Am</td>
                     </tr>
                     <tr>
                       <td>To</td>
                       <td> : </td>
                       <td>07:20pm</td>
                     </tr>
                     
                    
                   </tbody>
                    <tbody>
                     <tr>
                       <td>Shift 2</td>
                     </tr>
                     <tr>
                       <td>From</td>
                       <td> : </td>
                       <td>02:30Am</td>
                     </tr>
                     <tr>
                       <td>To</td>
                       <td> : </td>
                       <td>07:20pm</td>
                     </tr>
                     
                    
                   </tbody>
                 </table>
                 
            </div> */}
           
            </div>
            </>   
            
          )
        })
      }

    </div>
    
    {/* <div  style={{display:'flex',marginTop:'8px'}} >
    {items.map((item,index) =>{
     let itemColor2 = selected.includes(item) ? ' #F1F1F1' : '#fff'
     if (startAndEndIndexesForShiftSelection.start !== null & startAndEndIndexesForShiftSelection.end !== null) {
      itemColor2 = index >= startAndEndIndexesForShiftSelection.start && index <= startAndEndIndexesForShiftSelection.end ? ' #F1F1F1' : itemColor2
     }
     return (
    <div className='schedule-item-tables'  >
  <Card key={item.id} style={
      {
        backgroundColor: itemColor2
      }}>
  <CardBody>
    <div>
    <table style={{width: '100%'}}>
                     <tbody>
                     <tr>
                       <td>Shift 1</td>
                     </tr>
                     <tr>
                       <td>From</td>
                       <td> : </td>
                       <td>02:30Am</td>
                     </tr>
                     <tr>
                       <td>To</td>
                       <td> : </td>
                       <td>07:20pm</td>
                     </tr>
                     
                    
                   </tbody>
                   </table>
                   <hr />
                   <table style={{width: '100%'}}>
                    <tbody>
                     <tr>
                       <td>Shift 2</td>
                     </tr>
                     <tr>
                       <td>From</td>
                       <td> : </td>
                       <td>02:30Am</td>
                     </tr>
                     <tr>
                       <td>To</td>
                       <td> : </td>
                       <td>07:20pm</td>
                     </tr>
                     
                    
                   </tbody>
                 </table>
                </div>
  </CardBody>
  </Card>
  </div>
     )
  })
}
  </div> */}
   
   </Container>
                </CardBody>
                </Card>
              </Col>
                
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

