import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import Maintenance from "../../../assets/images/maintenance-bg.png";
// import { Link } from "react-router-dom";
// import RevenueAnalytics from "./RevenueAnalytics";
// import VerticalLayout from "../../../components/VerticalLayout";
// import SalesAnalytics from './SalesAnalytics';
// import LatestTransactions from "./LatestTransactions";
// import MiniWidgets from './MiniWidgets';
import { APP_NAME, WorkstationColor,InvoiceColor } from "../../../helpers/Constants";
// import { Player, Controls } from '@lottiefiles/react-lottie-player';
// import animationData from '../../../assets/lotties/abaci_working.json';
import { useSelector } from "react-redux";
import axios from "axios";
// import paymentLogo from "../../../assets/images/payment_logo.png";
// import { baseURL } from "../../../helpers/baseURL";
import CookiePolicy from "../Registration/CookiePolicy";
// import Tiles from "./Tiles";
import MiniWidgets from "./MiniWidgets";
import LineGraph from "./LineGraph";
import SalesAnalytics from "./SalesAnalytics";
// import LatestTransactions from "./LatestTransactions";
import AccessLog from "./AccessLogTableSubscriber";
import AOS from "aos";
import "aos/dist/aos.css";
// import { HiCurrencyRupee } from "react-icons/hi";
import Moments from "../../../helpers/Moment";

export default function Index() {

  const profile = useSelector((state) => state.authSlice.profile)
  const [modalShow,setModalShow]=useState(false)
 
  const [workstationStatus,setWorkstationStatus] = useState({options : {} , series:[]})
  const [invoiceStatus, setInvoiceStatus] = useState({options : {} , series:[]})
 
  const [tableData,setTableData]=useState([])
  
  useEffect(() => {
    if(profile.user_class==="Hilite"){
      HiliteUserClass();

 
    }
    else{
      SubscriberUserClass();

    }
    


   }, [profile.user_class])
   

  const [subscription,setSubscription] = useState([{
    icon: "ri-macbook-line",
    title: "Active Workstations ",
    // value : ,
    desc: "Active / Total Workstations ",
  },
  {
    icon: "fas fa-rupee-sign",
    title: "Current Month Invoices",
    // value : ,
    desc: "Paid /  Total Current Month Invoices",
  },
  {
    icon: "ri-macbook-line",
    title: "Subscribers",
    // value : ,
    desc: "Active / Total Subscribers",
  },
  {
    icon: "ri-user-fill",
    title: "Hotseats",
    // value : ,
    desc: "Active v/s Total Hotseats",
  },
  
  ]);
  const [subscription1,setSubscription1] = useState([{
    icon: "fas fa-rupee-sign",
    title: "Amount Paid",
    // value : 4000,
    desc: "Paid /  Total Invoice Amount",
   
  },
  {
    icon: "ri-macbook-line",
    title: "Workstations",
    // value : 14,
    desc: "Total Workstations",
  },
  {
    icon: "ri-bank-card-fill",
    title: "Rfid Cards",
    // value : 100,
    desc: " Assigned / Total Cards ",
    
  },
  {
    icon: "ri-user-fill",
    title: "Staffs",
    // value : 25,
    desc: "Total Staffs",
    
  },
  
  ]);

  const [dashboardState, setDashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "Dashboard", link: "#" },
    ],
  });





  const HiliteUserClass= () => {
   
    const url='/workspace_app/total_count_account_invoice_workstation'
    axios.get(url)
    .then((response) =>{
     
     setSubscription([{
       icon: "ri-macbook-line",
       title: "Active Workstations ",
       value : response.data.count_active_workstations  ,
       total:response.data.total_workstations,
       desc: "Active / Total Workstations",
     },
     {
       icon: "fas fa-rupee-sign",
       title: "Current Month Invoices",
       value : response.data.paid_invoice_amount,
       total:response.data.invoice_amount,
       desc: "Paid / Total Current Month Invoices",
     },
     {
       icon: "ri-macbook-line",
       title: "Subscribers",
       value :response.data.active_accounts,
       total:response.data.total_no_of_accounts,
       desc: "Active / Total Subscribers",
     },
     {
       icon: "ri-user-fill",
       title: "Hotseats",
       value : response.data.count_active_hotseat,
       total:response.data.total_hotseat,
       desc: "Active / Total Hotseats",
     },
     
     ])
     
     
      
    })
    .catch((error) =>{
      
    })

    const url2='/workspace_app/total_count_workstation_based_status'

      axios.get(url2)
    .then((response)=>{
      // const data=['Subscribed','Allocated','Available','Active','Disabled']
      const graph_keys=[]
      const graph_values=[]
      response.data.map((data)=>{
        
        graph_keys.push(data.status)

        
        if(data.count === 0){
          graph_values.push(0)
        }
        else{
         
        graph_values.push(data.count)
        }
        // graph_keys.sort()
        
        return(graph_keys,graph_values)
        
    })
    // data.map((data) => {
    //   if(!graph_keys.includes(data)){
    //     graph_keys.push(data)
    //     graph_values.push(0)
    //   }
    //   graph_keys.sort() 
    //   return(graph_keys,graph_values)
    // })
   
    setWorkstationStatus({
  
        series: graph_values,
        options: {
          labels: graph_keys,
          plotOptions: {
            pie: {
              donut: {
                size: "75%",
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          colors:WorkstationColor,
        },

       } )
      
    })
    .catch((error) =>{
      
    })
   
     



    const url3='/workspace_app/total_count_invoice_based_status'
    
    

      axios.get(url3)
    .then((response)=>{
      // const data=['Paid','Due','Overdue']
      const graph_keys=[]
      const graph_values=[]
      response.data.map((data)=>{
       
        graph_keys.push(data.status)

        
        if(data.count === 0){
          graph_values.push(0)
        }
        else{
        graph_values.push(data.count)
        
        }
        // graph_keys.sort()
       
        return(graph_keys,graph_values)
        
    })
    // to append remaining status of invoice when it is not coming from backend
    // data.map((data) => {
    //   if(!graph_keys.includes(data)){
    //     graph_keys.push(data)
    //     graph_values.push(0)
    //   } 
    //   graph_keys.sort()
    //   return(graph_keys,graph_values)
    // })
   
    setInvoiceStatus({
  
        series: graph_values,
        options: {
          labels: graph_keys,
          plotOptions: {
            pie: {
              donut: {
                size: "75%",
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          colors:InvoiceColor,
        },

       } )
      
    })
    .catch((error) =>{
      
    })
   
    



    





    
  }

  const SubscriberUserClass=()=>{
    const url='/workspace_app/total_amount_card_workstation_staff'
    axios.get(url)
    .then((response) =>{
     
     setSubscription1([{
      icon: "fas fa-rupee-sign",
      title: "Amount Paid",
      value : response.data.paid_invoice_amount,
      total:response.data.invoice_amount,
      desc: "Paid /  Total Invoice Amount",
      
    },
    {
      icon: "ri-macbook-line",
      title: "Workstations",
      value :response.data.count_workstations_allocated,
      desc: "Total Workstations",
      isSubscriber: true,

    },
    {
      icon: "ri-bank-card-fill",
      title: "Rfid Cards",
      value : response.data.assigned_rfid_cards,
      total:response.data.count_total_rfid_cards,
      desc: "Assigned / Total Rfid Cards ",
      

    },
    {
      icon: "ri-user-fill",
      title: "Staffs",
      value : response.data.count_total_staff,
      desc: "Total Staffs",
      isSubscriber: true,

    },
    
    ])
     
     
      
    })
    .catch((error) =>{
      
    })
    
    const url4='/workspace_app/last_ten_access_log_for_company'
    axios.get(url4)
    .then((response)=>{
     
      const tempData = response.data.map((data,key)=>({
        id:data.id,
        slno:key+1,
        username:data?.staff && data?.staff?.first_name && data?.staff?.last_name ? data.staff.first_name  +" "+ data.staff.last_name : data?.staff?.first_name ? data.staff.first_name : "No Data",
        cardNo:data?.rfid_card?.tag_id? data.rfid_card.tag_id :"No Data",
        accessed_time:data?.accessed_time? Moments(data.accessed_time,'datetime'):"No Data",
        accessed_gate:data?.accessed_gate?data.accessed_gate:"No Data",
        reason:data?.reason?data.reason:"No Data",


      
      
      }))
      setTableData(tempData)
     

    })
    .catch((error) =>{
      
    })

    


  }


  



  


 
 

  function modalToggle(){
    setModalShow(!modalShow)
  }


  

  useEffect(() => {

    AOS.init();
    AOS.refresh();

    setDashboardState(
      {
        breadcrumbItems: [
          { title: APP_NAME, link: "/dashboard" },
          { title: "Dashboard", link: "#" },
        ],
      }
    )
  }, [])
  useEffect(() => {
    const token=localStorage.getItem('token');
    if(token === null){
      setModalShow(true)
    }
   
  }, [])
  


  useEffect(() => {
    tempTheme();
  }, []);

  const tempTheme = () => {
    if (document.body) {
      document.body.setAttribute("data-sidebar", "dark");
    }
  };


  



  const subscriptionExpireList = {
    columns: [
      {
        dataField: "slno",
        text: "Sl.No",
      },
      {
        dataField: "username",
        text: "Username",
      },
      {
        dataField: "cardNo",
        text: "Card No",
      },
      {
        dataField: "accessed_time",
        text: "Attempted Date",
      },
      {
        dataField: "accessed_gate",
        text: "Accessed Door",
      },
      {
        dataField: "reason",
        text: "Reason",
      },
     
    ],
    rows:tableData
  };

  return (
    <div className='page-content'>

      <CookiePolicy 
      isOpen = {modalShow}
      toggle = {modalToggle}
      title={'Cookies Policy'}
      size={'xl'}
      />
      {/* Container can be fluid or boxed */}
      <Container fluid>
        <Breadcrumbs
          title='Dashboard'
          breadcrumbItems={dashboardState.breadcrumbItems}
        />
        
  {profile.user_class ==="Subscriber"? <>

  <Row>
             <Col xl={12}>
             
            <Row xl={4} data-aos="fade-up"  data-aos-duration="1000">
              <MiniWidgets reports={subscription1} 
              // loader={loader.loader5}
              />
             
            </Row>
            </Col>
            </Row>
            <Row data-aos="fade-up"  data-aos-duration="1000">
              <LineGraph title={'Total Payment Last Year'}
            
              
              
              />      
            </Row>
            <Row data-aos="fade-up"  data-aos-duration="1000">
            <AccessLog 
            
            name={'Last 10 Access Logs'}
            data={subscriptionExpireList}
  
            />
            </Row>
  
  </> :
  <>


              
      {/* Container can be fluid or boxed */}
    
    
         <Row>
             <Col xl={12}>
             
            <Row xl={4} data-aos="fade-up"  data-aos-duration="1000">
              <MiniWidgets reports={subscription} 
              // loader={loader.loader5}
              />
             
            </Row>
            </Col>
            </Row>
            <Row data-aos="fade-up"  data-aos-duration="1000">
              <LineGraph title={'Payments Recieved Last Year'}/>      
            </Row>
       
            <Row data-aos="fade-up"  data-aos-duration="1000">
       
            <Col xl={6}>
            <SalesAnalytics
                  name={"Workstations Status"}
                  salesAnalytics={workstationStatus} 
                />
            </Col>

     
       
         
         
            <Col xl={6}>
            <SalesAnalytics
                  name={"Invoice Status"}
                  salesAnalytics={invoiceStatus}
                   
                />
            </Col>

         
         
          </Row>
          </>}
        

      </Container>
    </div>
  );
}
