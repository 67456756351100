import { configureStore } from "@reduxjs/toolkit";
import layoutSlice from "./layout";
import UserSlice from "./user"
import ReportSlice from './report'
import RfidSlice from "./rfidcard"
import MasterSlice from './masterdata'
import authSlice from './auth'
import SubscriberSlice from './subscriber'
import DoorSlice from './door'
import AccessGroupSlice from './accessGroups'
import TimingSlice from './timing'
import SchedulerSlice from'./scheduler'


const store = configureStore({
  reducer: {
    layoutSlice: layoutSlice,
    UserSlice: UserSlice,
    RfidSlice : RfidSlice,
    ReportSlice : ReportSlice,
    MasterSlice : MasterSlice,
    authSlice : authSlice,
    SubscriberSlice : SubscriberSlice,
    DoorSlice:DoorSlice,
    TimingSlice:TimingSlice,
    AccessGroupSlice:AccessGroupSlice,
    SchedulerSlice:SchedulerSlice,
  },
});

export default store;
