import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container } from 'reactstrap'
import Swal from 'sweetalert2';
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import { APP_NAME } from '../../../../helpers/Constants';
import { Edit,FilterList } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../helpers/MaterialIcons';
import { useDispatch, useSelector } from 'react-redux';
import { AddWorkStation, DeleteWorkStation, EditWorkStation } from '../../../../store/masterdata';
import axios from 'axios';
import AddWorkStations from './AddWorkStation';
import EditWorkStations from './EditWorkStation';
import LockIcon from '@material-ui/icons/Lock';
import Unlock from "@material-ui/icons/LockOpen";
// import CloseIcon from '@material-ui/icons/Close';
import Error from '../../../../helpers/Error';
import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';


export default function WorkStations () {
    const [data,setData] = useState([])
    const [modalShow,setModalShow] = useState(false)
    const [itemToBeEdited,setItemToBeEdited] = useState(null)
    const [editModalShow,setEditModalShow] = useState(false)
    const [loader,setLoader]=useState(true)
    const workStation=useSelector((state) => state.MasterSlice.workStations)
    const profile = useSelector((state) => state.authSlice.profile)
    const dispatch = useDispatch()
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "WorkStations", link: "#" },
        ],
      });
  
    useEffect(() => {

      
      const abortController=new AbortController();
      const url='/workspace_app/workstation'
      axios.get(url)
      .then((response) => {
        dispatch(AddWorkStation(response.data))
        setLoader(false)
      })

      // dispatch(AddWorkStation([{id:1,operator_id:8765,emp_id:1001,extn_no:593,first_name:"WorkStation Center Circle",last_name:" Center Circle",designation:"Officer",email:"david@email.com",phone_no:"Upto 8 people",User_type:"Admin",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"},{id:2,operator_id:2678,emp_id:1002,extn_no:574,first_name:"WorkStation Entrance",last_name:"Near Entrance",designation:"Accountant",email:"Akhil@gmail.com",phone_no:"upto 3 people",User_type:"Admin",address:"789 Fort Myres,Monreo Avenue,Florida",status:"Active"},{id:3,operator_id:3567,emp_id:1003,extn_no:567,first_name:"WorkStation BackSide",last_name:"BackSide",designation:"Chief Officer",email:"Sharon@email.com",phone_no:"4 people can occupy",User_type:"User",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"}]))
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "WorkStations", link: "#" },
              ],
            }  
          )
          return (() => {
            abortController.abort();
            setData([]); 
          })
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addModalToggle = () => {
      setModalShow(!modalShow)
    }
    const editModalToggle = () => {
      setEditModalShow(!editModalShow)
    }

    useEffect(() => {
    if(workStation !== null){
      const tempData = workStation.map((data) => ({
        ...data,
        cabin_no:data?.cabin?.cabin_no ? data.cabin.cabin_no : 'No Cabin Selected',
        hotseat:data?.is_hotseat? "Yes":"No",
      }))
      setData(tempData)
    }
    }, [workStation])
    

    const columns =
  [
    { title: "Work Station No", field: "workstation_no"},
    { title: "Cabin No", field: "cabin_no"},

    { title: "Work Station Name", field: "workstation_name" },
    {title:'Hotseat' , field:'hotseat'},
    { title: "Work Station Location", field: "workstation_location"},
    {title:'Description' , field:'description'},
    {title:'Status' , field:'status'},

    // { title: "Contract Start", field: "contract_start"},
    // { title: "Contract End", field: "contract_end"},
  ]

   // for handling update delete activate etc....
   const UpdateHandler = (id,method) =>{

    if(method==="Deleted"){
    // console.log(id)
    const url=`/workspace_app/workstation/${id}`
    axios.patch(url,{status:method})
          .then((response) =>{
            SuccessAlert("WorkStation Deleted Successfully!")
            dispatch(DeleteWorkStation(id))
          })
          .catch((error) =>{
            const err_msg=Error(error)
            ErrorAlert(err_msg)
           
          })
        }
        else if(method==="Active" || method==="Disabled"){
          
          const url=`/workspace_app/workstation/${id}`
          axios.patch(url,{status:method})
          .then((response) =>{
            SuccessAlert("WorkStation Updated Successfully!")
            dispatch(EditWorkStation(response.data))
          })
          .catch((error) =>{
            const err_msg=Error(error)
            ErrorAlert(err_msg)
           
          })
        }
        // else{
        //   const url=`/workspace_app/workstation/${id}`
        //   axios.patch(url,{cabin:null})
        //   .then((response) =>{
        //     SuccessAlert("Cabin Removed Successfully!")
        //     dispatch(EditWorkStation(response.data))
        //   })
        //   .catch((error) =>{
        //     const err_msg=Error(error)
        //     ErrorAlert(err_msg)
           
        //   })

        // }
       
        
   }
  

    
    
  return (
    <React.Fragment>

{modalShow &&
      <AddWorkStations
      title={'Add WorkStation'}
      isOpen={modalShow}
      toggle={addModalToggle}
        />
      }
      {editModalShow &&
      <EditWorkStations
      title={'Edit WorkStation'}
      isOpen={editModalShow}
      toggle={editModalToggle}
      item={itemToBeEdited}
        />
      }


          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="WorkStations"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
              
              { profile.user_type === "Admin" ?<>
              <div
                className="disappearing-button-container-page-top-right" style={{marginRight:"-80px"}}>
                 <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={addModalToggle}
                >
                  Add WorkStation
                </Button>
                  </div>
                  </> : null}
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={columns}
                        data={data}
                        isLoading={loader}

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },

                          
                          // {
                          //   icon: VisibilityOutlinedIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                          // },
                          rowData=>({
                            icon: Edit,
                            tooltip: 'Edit Workstation',
                            onClick: (event, rowData) => {
                              // console.log(rowData)
                              // setEditClientModalShow(true)
                              setItemToBeEdited(rowData)
                              editModalToggle();
                            },hidden:profile.user_type ==="User"||rowData.status==='Subscribed'||rowData.is_hotseat===true||rowData.status==='Allocated'
                          }),
                          // rowData => ({
                          //   icon: DeleteOutline,
                          //   tooltip: 'Delete Workstation',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       text: "You won't be able to revert this!",
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, delete it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,"Deleted")
                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   // disabled: rowData.birthYear < 2000
                          // }),
                          // rowData => ({
                          //   icon: Unlock,
                          //   tooltip: 'Enable Workstation',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                                
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, Enable it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,"Active")
                          //         // deleteClientData(rowData?.id)

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   hidden:profile.user_type ==="User"|| rowData.status === "Active"  || rowData.status === "Available" || rowData.status === 'Allocated' ||rowData.status==="Subscribed"||rowData.is_hotseat===true
                          // }),
                          // rowData => ({
                          //   icon: LockIcon,
                          //   tooltip: 'Disable Workstation',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, Disable it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,"Disabled")
                          //         // deleteClientData(rowData?.id)

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   hidden: profile.user_type ==="User"|| rowData.status === "Disabled" || rowData.status === "Subscribed" || rowData.status === 'Allocated'||rowData.is_hotseat===true


                            
                          // }),
                          // rowData => ({
                          //   icon: CloseIcon,
                          //   tooltip: 'Remove Cabin',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, Removed it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,"Removed")
                          //         // deleteClientData(rowData?.id)

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                            


                            
                          // }),
                          
                          
                          
                        ]}
                        options={{
                          exportFileName:'Workstations',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
                
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

