import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container } from 'reactstrap'
import Swal from 'sweetalert2';
import Breadcrumbs from '../../../../../components/Common/Breadcrumb'
import { APP_NAME } from '../../../../../helpers/Constants';
import {  Edit,FilterList } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../../helpers/MaterialIcons';
import { useDispatch, useSelector } from 'react-redux';
import BlockIcon from '@mui/icons-material/Block';
import RestoreIcon from '@mui/icons-material/Restore';
import LockIcon from '@material-ui/icons/Lock';
import Unlock from "@material-ui/icons/LockOpen"
import SendIcon from '@mui/icons-material/Send';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { AddSubscriber, ActivateDeactivateBlockSubscriber, DeleteSubscriber } from '../../../../../store/subscriber';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AddSubscribers from './AddSubscribers';
import EditSubscribers from './EditSubscriber';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Error from '../../../../../helpers/Error';
import { ErrorAlert, SuccessAlert } from '../../../../../helpers/Alert';

import { Link } from 'react-router-dom';
export default function Subscribers () {
    const navigate = useNavigate();
    const [data,setData] = useState([])
    const [value,setValue] = useState(0)
    const [addModalShow,setAddModalShow] = useState(false)
    const [editModalShow,setEditModalShow] = useState(false)
    const [itemToBeEdited,setItemToBeEdited] = useState(null)
    const [loader,setLoader] = useState(true)
    const subscriber=useSelector((state) => state.SubscriberSlice.subscriber)
    const profile = useSelector((state) => state.authSlice.profile)
    const dispatch = useDispatch()
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "All Subscribers", link: "#" },
        ],
      });

      const addModalToggle = () => {
        setAddModalShow(!addModalShow)
      }
      const editModalToggle = () => {
        setEditModalShow(!editModalShow)
      }
  
    useEffect(() => {

      const abortController = new AbortController();

      const url='/workspace_app/subscriber'
      axios.get(url)
      .then((response) => {
        dispatch(AddSubscriber(response.data))
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
      })

      // dispatch(AddSubscriber([{id:1,operator_id:8765,company_name:'Noux',emp_id:1001,extn_no:593,first_name:"David",last_name:"Jacobs",designation:"Officer",email:"david@email.com",phone_no:"0501231000",User_type:"Admin",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"},{id:2,operator_id:2678,emp_id:1002,company_name:'Element 8',extn_no:574,first_name:"Akhil",last_name:"Prem",designation:"Accountant",email:"Akhil@gmail.com",phone_no:"0501231001",User_type:"Admin",address:"789 Fort Myres,Monreo Avenue,Florida",status:"Active"},{id:3,operator_id:3567,emp_id:1003,company_name:'Rad',extn_no:567,first_name:"Sharon",last_name:"VArghese",designation:"Chief Officer",email:"Sharon@email.com",phone_no:"05012310021",User_type:"User",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"}]))
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "All Subscribers", link: "#" },
              ],
            }  
          )
          
          return () => {
            abortController.abort();
            setData([]); 
          }
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
    if(subscriber !== null){
      // console.log(subscriber)
      const tempData = subscriber.map((data) => ({
        ...data,
        contact_person__name:data?.contact_person?.full_name ? data.contact_person.full_name : "",
        contact_person__number:data?.contact_person?.contact_number ? data.contact_person.contact_number : "",
        contact_person__email:data?.contact_person?.email ? data.contact_person.email : "",
        invitation_status:data?.contact_person?.user_status ? data.contact_person.user_status : "",
        acc_type:data?.is_virtual_acc ? "Virtual":"Ordinary",
        subscription_status:data?.subscription_status? data.subscription_status :'No Data'
      }))
      setData(tempData)
    }
    }, [subscriber])
    

    const columns =
  [
   
    
    { title:'Reg No', field:'reg_no' ,
    render:rowData =><> {rowData.reg_no ? rowData.reg_no : 'No Data'}</>,
  },
    { title:'Company Name', field:'company_name',
   
    render:rowData =><><Tooltip arrow title={`View Details of ${rowData.company_name}`}><Link  to={`/subscriberdetails/${rowData.id}`}> {rowData.company_name ? rowData.company_name : 'No Data'}</Link></Tooltip></>,

  },
    { title: "Contact Person", field: "contact_person__name",
     render:rowData =><> {rowData.contact_person__name ? rowData.contact_person__name : 'No Data'}</>,

  },
   
    
    {title:'Contact No' , field:'contact_person__number',
    render:rowData =><> {rowData.contact_person__number ? rowData.contact_person__number : 'No Data'}</>,

  },
    {title:'Email' , field:'contact_person__email',
    render: rowData => <>{rowData.contact_person__email && rowData.invitation_status === 'Invited' ? <span >{rowData.contact_person__email} <p className="text-danger">(Activation Pending)</p></span> : rowData.contact_person__email ? rowData.contact_person__email :  'No Data'}</>  

  },
   
    {title:'Address' , field:'address',
    render:rowData =><> {rowData.address ? rowData.address : 'No Data'}</>,

  },
  {title:'Account Type' , field:'acc_type'},
  // {title:'Subscription Status' , field:'subscription_status'},
    {title:'Account Status' , field:'status'},

    // { title: "Contract Start", field: "contract_start"},
    // { title: "Contract End", field: "contract_end"},
  ]

      // for handling delete activate deactivate
 const UpdateHandler = (id,method) =>{

  if(method==="Deleted"){
  // console.log(id)
  const url=`/workspace_app/subscriber/${id}`
  axios.patch(url,{status:method})
        .then((response) =>{
          SuccessAlert("Subscriber Deleted Successfully!")
          dispatch(DeleteSubscriber(id))
        })
        .catch((error) =>{
          const err_msg=Error(error)
          ErrorAlert(err_msg)
         
        })
      }
      else{
        if(method === 'Activated' || method === 'Deactivated'){
        const url=`/workspace_app/deactivate_or_activate_subscriber/${id}`
        axios.put(url,{status:method})
        .then((response) =>{
          SuccessAlert("Subscriber Updated Successfully!")
          dispatch(ActivateDeactivateBlockSubscriber({...response.data,id:id}))
        })
        .catch((error) =>{
          const err_msg=Error(error)
          ErrorAlert(err_msg)
         
        })
      }
      

     else if(method === 'Reactivate'){
      const url=`/workspace_app/activate_disallocated_subscriber/${id}`
        axios.put(url,{status:'Activated'})
        .then((response) =>{
          SuccessAlert("Subscriber ReActivated Successfully!")
          dispatch(ActivateDeactivateBlockSubscriber({...response.data,id:id}))
        })
        .catch((error) =>{
          const err_msg=Error(error)
          ErrorAlert(err_msg)
         
        })
     }

      else if(method === 'invite'){
        const url=`/users_api/resend_invitation/${id}` 
        axios.put(url)
        .then((response) =>{
          SuccessAlert("Invitation Resend Successfully!")
          // dispatch(editEnvirolUser(response.data))
        })
        .catch((error) =>{
          const err_msg=Error(error)
          ErrorAlert(err_msg)
         
        })
      }
      else if(method === 'Disallocated'){
       // console.log('wrked')
        const url=`/workspace_app/disallocate_subscriber/${id}`
        axios.put(url,{status:method})
        .then((response) =>{
          SuccessAlert("Subscriber Disallocated Successfully!")
          dispatch(ActivateDeactivateBlockSubscriber({...response.data,id:id}))
        })
        .catch((error) =>{
          if(error?.response?.data?.message){
            ErrorAlert(error.response.data.message)
          }
          else{
          const err_msg=Error(error)
          ErrorAlert(err_msg)
          }
        })
      }
      }
 }

  const handleChange = (event, newValue) => {
    setValue(newValue)
    if(newValue === 1){
      navigate('/subscriberstaffs')
    }
    else if(newValue === 2){
      navigate('/subscriberworkstations')
    }
  };
    
    
  return (
    <React.Fragment>

      {addModalShow &&
      <AddSubscribers
      isOpen={addModalShow}
      toggle={addModalToggle}
      title={'Add Subscriber'}
        />
      }
       {editModalShow &&
      <EditSubscribers
      isOpen={editModalShow}
      toggle={editModalToggle}
      title={'Edit Subscriber'}
      item={itemToBeEdited}
        />
      }
      
          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="All Subscribers"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
              { profile.user_type === "Admin" ?<>
               <div
                className="disappearing-button-container-page-top-right" style={{marginRight:"-80px"}}>
                 
                 <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={addModalToggle}
                >
                  Add Subscribers
                </Button>
                  </div>
                  </>:null}
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
    <Tab label="Subscribers"  wrapped/>
    <Tab label="Staffs" wrapped/>
    <Tab label="WorkStations" wrapped/>
  </Tabs>
</Box>
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={columns}
                        data={data}
                        isLoading={loader}

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },

                          
                          // {
                          //   icon: VisibilityOutlinedIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                          // },
                          {
                            icon: VisibilityIcon,
                            tooltip: 'View Detail',
                            onClick: (event, rowData) => navigate(`/subscriberdetails/${rowData.id}`)
                            
                          },
                           rowData=>({
                            icon: Edit,
                            tooltip: 'Edit Subscriber',
                            onClick: (event, rowData) => {
                              // console.log(rowData)
                              // setEditClientModalShow(true)
                              setItemToBeEdited(rowData)
                              editModalToggle();
                            },
                            hidden:rowData.status === "Disallocated" || profile.user_type ==="User"
                           
                          }),
                          rowData => ({
                            icon: SendIcon,
                            tooltip: 'Resend Invitation',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'info',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Send!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.contact_person.id,"invite")
                                  // deleteClientData(rowData?.id)

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden:   rowData.status === "Activated" ||rowData.status === "Disallocated" || profile.user_type ==="User"|| rowData.invitation_status === "Activated" || rowData.invitation_status === "Deactivated" || rowData.invitation_status === "Active"|| rowData.invitation_status === "Deleted"
                          }),
                         
                          rowData => ({
                            icon: Unlock,
                            tooltip: 'Activate Subscriber',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Enable it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,"Activated")
                                  // deleteClientData(rowData?.id)

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden:  rowData.status === "Disallocated" || rowData.status === "Activated" || rowData.status === "Invited"||profile.user_type ==="User"
                          }),

                          rowData => ({
                            icon: RestoreIcon,
                            tooltip: 'Reactivate Subscriber',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Reactivate it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,"Reactivate")
                                  // deleteClientData(rowData?.id)

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden:   rowData.status === "Deactivated" ||rowData.status === "Activated" || rowData.status === "Invited"||profile.user_type ==="User"
                          }),
                          rowData => ({
                            icon: LockIcon,
                            tooltip: 'Deactivate Subscriber',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Deactivate it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,"Deactivated")
                                  // deleteClientData(rowData?.id)

                                
                                } 
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden: rowData.status === "Disallocated" || rowData.status === "Deactivated" || rowData.status === "Invited" || profile.user_type ==="User"
                          }),
                          rowData => ({
                            icon: BlockIcon,
                            tooltip: 'Disallocate Subscriber',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: 'It Will Disallocate All the Cards & Workstations Associated with this Subscriber',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Disallocate !'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,"Disallocated")
                                  // deleteClientData(rowData?.id)

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden : rowData.subscription_status === "Active"|| rowData.subscription_status === "Pending" ||rowData.status === "Disallocated" ||profile.user_type ==="User" || rowData.status === 'Blocked' || rowData.status === "Invited"||rowData.is_virtual_acc===true
                          }),
                          // rowData => ({
                          //   icon: LockIcon,
                          //   tooltip: 'Disable User',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, Disable it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,"Deactivated")
                          //         // deleteClientData(rowData?.id)

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   hidden: rowData.status === "Deactivated" || rowData.status === "Invited" 
                          // }),

                          // {
                          //   icon: DeleteOutline,
                          //   tooltip: 'Delete Client',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       text: "You won't be able to revert this!",
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, delete it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,'Deleted')

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                           
                          // },

                        ]}
                        options={{
                          exportFileName:'Subscribers List',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>

        </Container>
    </div>
    
    </React.Fragment>
  )
}

