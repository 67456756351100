import React, { useCallback, useEffect, useState } from 'react'
import Modals from '../../../../../components/Modal'
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch } from 'react-redux';
// import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
// import Error from '../../../../helpers/Error';
import axios from 'axios';
import { EditSubscriberStaff } from '../../../../../store/subscriber';
import Error from '../../../../../helpers/Error';
import { ErrorAlert, SuccessAlert } from '../../../../../helpers/Alert';


function EditStaff(props) {

    const [waitingForAxios,setwaitingForAxios] = useState(false)
    const [company,setCompany] = useState([])
    const [loader,setLoader] = useState(true)
    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
      const dataValidator = useCallback(() =>{

        if (props.item !== null) {
          //  console.log(props.item)
          
          const tempdata={...props.item,
            company:props.item?.company?.id && props.item.company.id 
          }
          
          reset(tempdata)
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])  

      useEffect(() => {
        const url='/workspace_app/limited_subscriber_list'
      axios.get(url)
      .then((response) => {
        const temp=[]
        response.data.map((data)=>{

         if(data.status==="Activated"){
            temp.push({...data})
         }
         return(temp)
        })
        setCompany(temp)
        setLoader(false)
        dataValidator();
      })
      .catch((error) => {
        dataValidator();
        setLoader(false)
      })

      }, [dataValidator])
      


    const onSubmit = (data) =>{
        
               
        let formData = new FormData(); //formdata object
      formData.append("reg_no", data.reg_no);
      formData.append("last_name", data.last_name);
      formData.append("first_name", data.first_name);
      formData.append("contact_number", data.contact_number);
      formData.append("company", data.company);
      formData.append("email", data.email);
        setwaitingForAxios(true)
        
        const url=`/workspace_app/staffs/${props.item.id}`

        axios.patch(url,formData)
        .then((response) =>{
            // let msg=response.data.message
            setwaitingForAxios(false)
            dispatch(EditSubscriberStaff(response.data))
            SuccessAlert('Staff Edited Succesfully')
            props.toggle();
        })
        .catch((error) => {
            setwaitingForAxios(false)
            if(error.response.data.message){
                ErrorAlert(error.response.data.message)  
            }
           else if(error.response?.data?.email[0]){
                ErrorAlert(error.response?.data?.email[0])

            }
           else{
            let error_msg=Error(error)
            ErrorAlert(error_msg)
           }
        })
       
    }

  return (
    <div><Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} loader={loader}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>


    
        <FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Company Name" sm={3}>
        Company Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
    <select className="form-control"
                  {...register("company", {
                    required: true,
                    
                  })}>
 <option value="">      --Select Company--</option>
                  {company.map((item,index) => {
                    return <option key={index} value={item.id}>{item.company_name}</option>
                  })}
    </select>
    
        {errors.company?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
        <FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Reg No" sm={3}>
        Reg No <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Reg No"
            type="text"
            className="form-control"
            {...register("reg_no", {
                required: true,
            })}
        />
        {errors.reg_no?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="First Name" sm={3}>
        First Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="First Name"
            type="text"
            className="form-control"
            {...register("first_name", {
                required: true,
            })}
        />
        {errors.first_name?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Last Name" sm={3}>
        Last Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Last Name"
            type="text"
            className="form-control"
            {...register("last_name", {
                required: true,
            })}
        />
        {errors.last_name?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Contact Number" sm={3}>
        Contact Number <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Contact Number"
            type="text"
            className="form-control"
            {...register("contact_number", {
                required: true,
            })}
        />
        {errors.contact_number?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Email Id" sm={3}>
        Email Id <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Email Id"
            type="text"
            className="form-control"
            {...register("email", {
                required: true,
            })}
        />
        {errors.email?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>


<ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>


</ModalFooter>
</Form>
        </Modals></div>
  )
}

export default EditStaff