import React from "react";
import {
  Col,
   Dropdown,
   DropdownMenu,
   DropdownToggle,
   DropdownItem,
  Card,
  CardBody,
} from "reactstrap";
// import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import GraphLoader from "../../components/GraphLoader/GraphLoader";
import { downloadExcel } from "react-export-table-to-excel";
import { useState } from "react";

// const expandRow = {
//   renderer: (row) => (
//     <>
//       Action :
//       <Link to='#' className='me-3 text-primary'>
//         <i className='mdi mdi-pencil font-size-18'></i>
//       </Link>
//       <Link to='#' className='text-danger'>
//         <i className='mdi mdi-trash-can font-size-18'></i>
//       </Link>
//     </>
//   ),
//   showExpandColumn: true,
//   expandByColumnOnly: true,
// };

export default function AccessLog(props) {
  const [menu, setMenu] = useState(false);

  // const options = {
  //   // pageStartIndex: 0,
  //   hideSizePerPage: false,
  //   hidePageListOnlyOnePage: false,
  //   sizePerPageList: [
  //     {
  //       text: "5th",
  //       value: 10,
  //     }
  //   ],
  // };

  
  function handleDownloadExcel() {

    const header=props.data.columns.map((data) => data.text)
//     const temp= props.data.rows.forEach(object => {
//       delete object['id'];
// });
     downloadExcel({
      fileName: `${props.name} Report`,
       sheet: "react-export-table-to-excel",
       tablePayload: {
        header,
          // accept two different data structures
         body: props.data.rows,
       },
    });
  }

  // const selectRow = {
  //   mode: "checkbox",
  //   clickToSelect: true,
  // };

  return (
    <React.Fragment>
      <Col xl={12}>
        <Card>
          <CardBody>
            
          <Dropdown
              isOpen={menu}
              toggle={() => setMenu(!menu)}
              className='float-end'
              style={{cursor: 'pointer'}}
              >
              <DropdownToggle tag='i' className='arrow-none card-drop'>
                <i className='mdi mdi-dots-vertical'></i>
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-end'>
                <DropdownItem onClick={handleDownloadExcel}>Export As CSV</DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <h4 className='card-title mb-4'>{props.name}</h4>
            <div className='table-responsive'>
              <BootstrapTable
                keyField='id'
                data={props.data.rows}
                columns={props.data.columns}
                // expandRow={expandRow}
                // pagination={paginationFactory(options)}
                // selectRow={selectRow}
              />
               {/* {props.loader ? <GraphLoader /> : null}
              {props.error ? <><p className="text-center">{props.error}</p></> : null } */}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}
