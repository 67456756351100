import { createSlice } from "@reduxjs/toolkit";
const initialState={
    door:null,

};

const DoorSlice = createSlice({
    name:"door",
    initialState,
    reducers:{

        AddDoor(state,action){
            state.door = action.payload
        },

        AddNewDoor(state,action){
            state.door = [...state.door,action.payload]
        },

        EditDoor(state,action){
            state.door = state.door.map((data)=>
            data.id === action.payload.id ? action.payload :data
            )
            

        },
        DeleteDoor(state,action){
            state.door = state.door.filter((data)=>
            data.id !== action.payload
            
            )

        },
        EditDoorStatus(state,action){
            state.door = state.door.map((data)=>
            data.id === action.payload.id ? {...data,door_status:action.payload.door_status}:data
            )
            

        },
        

        }
    
})
export const{AddDoor,AddNewDoor,EditDoor,DeleteDoor, EditDoorStatus }=DoorSlice.actions;
export default DoorSlice.reducer;