import React, { useEffect, useRef, useState } from 'react'
import {  Card, CardBody, Col, Container } from 'reactstrap'
// import Tooltip from '@mui/material/Tooltip';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { IconButton, Tooltip } from '@material-ui/core';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CancelIcon from '@mui/icons-material/Cancel';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { APP_NAME, ranges } from '../../../helpers/Constants';
// import { DeleteOutline, Edit,FilterList } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../helpers/MaterialIcons'; 
import { useSelector } from 'react-redux';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import SendIcon from '@mui/icons-material/Send';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import Moments from '../../../helpers/Moment';
import {  ErrorAlert, SuccessAlert, Toastr } from '../../../helpers/Alert';
import Error from '../../../helpers/Error';
import fileDownload from 'js-file-download'
import Swal from 'sweetalert2';
import { pdfGenerator } from '../../../helpers/PDFReportGenerator';
import moment from 'moment';


export default function Invoices () {
   
    // const navigate = useNavigate();

    const tableRef=useRef();
    const [date,setDate]=useState({})
    const [totalRecordsCount, setTotalRecordsCount] = useState(0)
    const [downloadIndicator,setDownloadIndicator] = useState(false)
    const [urlBackup, setUrlBackup] = useState('')
    // const [filterEnabled, setFilterEnabled] = useState(false);
    const profile = useSelector((state) => state.authSlice.profile)
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "Invoices", link: "#" },
        ],
      });

       
  const exportData = (downloadType) => {
    const url = `${urlBackup}&${downloadType}`
    setDownloadIndicator(true)
    if (downloadType === "pdf_download") {
      if (totalRecordsCount >= 2000) {
        ErrorAlert("The maximum records to export as PDF is 2000. Please apply filter to reduce the number of records")
        setDownloadIndicator(false)
        return
      }
   
      axios({
        url: url,
        method: 'GET',
        // responseType: 'blob', // Important
      }).then((response) => {
        setDownloadIndicator(false)
        // console.log(response.data?.body)
        const headRowData = [response.data?.header]
        const bodyRowData = response.data?.body
        const reportName = "Invoice_List"
        var doc = pdfGenerator(headRowData, bodyRowData, reportName)
        doc.save("Invoice_List.pdf");
        console.log('')
        // tableRef.current.onQueryChange();
       
      }).catch(error => {
        Toastr("error", "Error downloading the file !")
        setDownloadIndicator(false)
      });
    }
    else {
      if (totalRecordsCount >= 5000) {
        ErrorAlert("The maximum records to export as CSV is 5000. Please apply filter to reduce the number of records")
        setDownloadIndicator(false)
        return
      }
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // Important
      }).then((response) => {
        let fileName = "Invoice_List.csv"
        fileDownload(response.data, fileName);
        setDownloadIndicator(false)
        // tableRef.current.onQueryChange();
      }).catch(error => {
        Toastr("error", "Error downloading the file !")
        setDownloadIndicator(false)
      });
    }
  }



  const handleDate = (start,end) => {

    if(start=== "cancel"){
      setDate({})
      tableRef.current.onQueryChange({page: 0});
    }
    else{
    const firstdate=moment(start).format('YYYY-MM-DD')
    const enddate=moment(end).format('YYYY-MM-DD')

    setDate({start_date: firstdate, end_date: enddate})

    tableRef.current.onQueryChange({page: 0});
    }
  }


      const UpdateHandler = (data,method) =>{
        console.log(data)
        if(method==="download"){
        const url = data
        const name = data.split('/')[2]
       
        axios.get(url, {
          responseType: 'blob',
        }).then(res => {
          console.log(res)
          fileDownload(res.data, name);
        });
      }
     else if(method==="email"){
      const pk=data
      const url='/workspace_app/resend_invoice'
      axios.post(url,{invoice_pk:pk})
      .then((response) =>{
        SuccessAlert("Invoice Resend Successfully")
        
      })
      .catch((error) =>{
        const err_msg=Error(error)
        ErrorAlert(err_msg)
       
      })
     }
     else if(method==="Cancel"){
      const pk=data
      const url=`/workspace_app/cancel_invoice/${pk}`
      axios.put(url)
      .then((response) =>{
        SuccessAlert("Invoice Cancelled Successfully")
        tableRef.current.onQueryChange();
      })
      .catch((error) =>{
        const err_msg=Error(error)
        ErrorAlert(err_msg)
       
      })
     }
      else{
        const pk=data
        const url='/workspace_app/regenerate_invoice'
        axios.post(url,{invoice_pk:pk})
        .then((response) =>{
          SuccessAlert(" Invoice Regenerated Successfully")
          
        })
        .catch((error) =>{
          const err_msg=Error(error)
          ErrorAlert(err_msg)
         
        })
        
      }
    }
        
     
    useEffect(() => {

      // const abortController = new AbortController();
      // if(profile.user_class === "Hilite")
      // {
      //   const url='/workspace_app/invoice_list_for_excel_download'
      //   axios.get(url)
      //   .then((response) => {
        
        
         
      //       const temp = response.data.map((data)=>({
      //         ...data,
      //         invoice_id:data?.invoice_id? data.invoice_id :'No Data',
      //         created_date:data?.created_date? Moments(data.created_date):'No Data',
      //         gst_in:data?.company?.gst_in? data.company.gst_in:'No Data',
      //         place_of_supply:'Kerala',
      //         rent_hsn:'997212',
      //         maintenance_hsn:'997221',
      //         cgst_amount:data?.cgst_amount ? data.cgst_amount : 'No Data',
      //         sgst_amount:data?.sgst_amount ? data.sgst_amount : 'No Data',
      //         payment_status:data?.payment_status? data.payment_status:'No Data',
      //         company_name:data?.company?.company_name? data.company.company_name:'No Data',
      //         amount_without_gst:data?.amount_without_gst? data.amount_without_gst:'No Data',
      //         amount:data?.amount? data.amount : 'No Data',
              
        

      //        }))
      //        setLoader(false)
      //        setData(temp)
             
  
         
      //       })
      //       .catch((error) => {
      //         setLoader(false)
      //       })
      // }
      // else{
      //   const url='/workspace_app/invoice'
      // axios.get(url)
      // .then((response) => {
        
      
       
      //     const temp = response.data.map((data)=>({

      //       ...data,created_date:data?.created_date? Moments(data.created_date):'No Data',
      //       cgst_amount:data?.cgst_amount ? data.cgst_amount : 'No Data',
      //       sgst_amount:data?.sgst_amount ? data.sgst_amount : 'No Data',
         
      //       amount_without_gst:data?.amount_without_gst? data.amount_without_gst:'No Data',
      //     }))
      //      setLoader(false)
      //      setData(temp)

       
      //     })
      // .catch((error) => {
      //   setLoader(false)
      // })
        
      // }
      
      

      // dispatch(AddSubscriber([{id:1,operator_id:8765,company_name:'Noux',emp_id:1001,extn_no:593,first_name:"David",last_name:"Jacobs",designation:"Officer",email:"david@email.com",phone_no:"0501231000",User_type:"Admin",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"},{id:2,operator_id:2678,emp_id:1002,company_name:'Element 8',extn_no:574,first_name:"Akhil",last_name:"Prem",designation:"Accountant",email:"Akhil@gmail.com",phone_no:"0501231001",User_type:"Admin",address:"789 Fort Myres,Monreo Avenue,Florida",status:"Active"},{id:3,operator_id:3567,emp_id:1003,company_name:'Rad',extn_no:567,first_name:"Sharon",last_name:"VArghese",designation:"Chief Officer",email:"Sharon@email.com",phone_no:"05012310021",User_type:"User",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"}]))
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Invoices", link: "#" },
              ],
            }  
          )

    }, [])

    useEffect(() => {
      console.log(tableRef)
    }, [tableRef])
    

    const column =
    [{ title:'Invoice ID', field:'invoice_id' ,
      render:rowData =><> {rowData.invoice_id ? rowData.invoice_id : 'No Data'}</>,
    },
    {title: "Company Name", field: "company_name"},
    { title:'Bill Date', field:'bill_date'},

  { title:'GST No', field:'gst_in',
      render:rowData =><>{rowData.gst_in? rowData.gst_in: 'No Data'}</>,
  },
     
    // {title: "Place Of Supply", field: "place_of_supply"},
    {title: "Rent HSN Code", field: "rent_hsn"},
    {title: " Maintenance HSN Code", field: "maintenance_hsn"},
    {title: " Amount (Without GST)", field: "amount_without_gst"},
    {title: "CGST Amount", field: "cgst_amount"},
    {title: "SGST Amount", field: "sgst_amount"},
    { title:' Invoice Amount', field:'amount',
     
    render:rowData =><>{rowData.amount? rowData.amount: 'No Data'}</>,},
    { title: "Status", field: "payment_status",}
    ]



  return (
    <React.Fragment>

      
          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Invoices"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
                 <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >

           <div style={{display:'flex',marginBottom:'-55px',width:'60%'}}>
            <Col xl={1}>
              <DateRangePicker  onCallback={(startdate,enddate) => handleDate(startdate._d,enddate._d)}
                  onCancel={(data)=> handleDate(data.type)}
                  initialSettings={{ranges:ranges,
                                      opens:'right',
                                      startDate:moment().subtract(1, 'days'),
                                      // showCustomRangeLabel:true,
                                      // showDropdowns:true,
                                      endDate: moment(),
                                      maxDate:moment()
                                    }}>
  <IconButton aria-label="delete"  color={Object.keys(date).length !== 0 ? 'primary' : 'default'} style={{zIndex:'1'}}>
    <Tooltip title='Show Calendar'>
  <CalendarMonthIcon />
  </Tooltip>
</IconButton>
  </DateRangePicker>

            </Col>
            <div  style={{zIndex:'1',marginBottom:'-40px' }}>
             <p style={{marginTop:'17px',paddingRight:'20px'}}>{Object.keys(date).length !== 0 ?  `Showing Invoices from ${date.start_date} to ${date.end_date}` : 'Showing All Invoices'}</p>
            </div>
          </div>
                      <MaterialTable
                        title=" "
                        columns={column}
                        tableRef={tableRef}
                        data={ (query) => {
                          console.log(query)
                          return (new Promise((resolve, reject) => {
                           
                            let orderBy = ""
                            if (query.orderBy) {
                              orderBy = query.orderDirection === "asc" ? `&ordering=-${query.orderBy?.field}` : `&ordering=${query.orderBy?.field}`
                            }
                         

                            let url = `/workspace_app/invoice_list_for_excel_download?limit=${query.pageSize}&offset=${query.pageSize * query.page}&search=${query.search}${orderBy}`
                            
                            if (Object.keys(date).length !== 0){
                              url +=`&start_date=${date.start_date}&end_date=${date.end_date}`
                            }
                            
                            setUrlBackup(url)
                            axios.get(url)
                              .then((response) => {
                                setTotalRecordsCount(response.data?.count)
                                tableRef.current.dataManager.changePageSize(query.pageSize)
                                // console.log(response.data.results)
                                const InvoiceList = response.data?.results
                                // dispatch(addGtcc(response.data.results))
                                const TempData = InvoiceList.map((data) => ({
                                  ...data,
                                  invoice_id:data?.invoice_id? data.invoice_id :'No Data',
                                  bill_date:data?.bill_date? Moments(data.bill_date):'No Data',
                                  gst_in:data?.company?.gst_in? data.company.gst_in:'No Data',
                                  place_of_supply:'Kerala',
                                  rent_hsn:'997212',
                                  maintenance_hsn:'997221',
                                  cgst_amount:data?.cgst_amount ? data.cgst_amount : 'No Data',
                                  sgst_amount:data?.sgst_amount ? data.sgst_amount : 'No Data',
                                  payment_status:data?.payment_status? data.payment_status:'No Data',
                                  company_name:data?.company?.company_name? data.company.company_name:'No Data',
                                  amount_without_gst:data?.amount_without_gst? data.amount_without_gst:'No Data',
                                  amount:data?.amount? data.amount : 'No Data',
                                  
                                }))
                                resolve({
                                  data: TempData,
                                  page: response.data?.results.length === 0 ? 0  :query.page,
                                  totalCount: response.data?.count,
                                })
                              })
                              .catch((error) => {
                                // console.log('rejected')
                                reject({
                                  data: [],
                                  page: query.page,
                                  totalCount: 0
                                })
                              })
                          }))
                        }}
                        isLoading={downloadIndicator}

                        actions={[
                          // {
                          //   icon: FilterList,
                          //   tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                          //   isFreeAction: true,
                          //   onClick: (event) => { setFilterEnabled(state => !state) }
                          // },
                          rowData => ({
                            icon: DownloadIcon,
                            tooltip: 'Download Invoice',
                            onClick: (event) => {UpdateHandler(rowData.invoice_file,'download')}
                          }),
                          rowData => ({
                            icon:CancelIcon,
                            tooltip: 'Cancel Invoice',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Cancel it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,'Cancel')
                                }
                              })
                            },
                              hidden:profile.user_class==='Subscriber'||  profile.user_type ==="User"||rowData.payment_status==="Paid"||rowData.payment_status==="Cancelled"


                          }),

                          rowData => ({
                            icon: ReplayCircleFilledIcon,
                            tooltip: 'Regenerate Invoice',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Regenerate it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,'generate')
                                }
                              })
                            },
                              hidden:profile.user_class==='Subscriber'||  profile.user_type ==="User"


                          }),
                          rowData => ({
                            icon: SendIcon,
                            tooltip: 'Resend email',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Resend Invoice!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,'email')
                                }
                              })
                            },
                              hidden:profile.user_class==='Subscriber'||  profile.user_type ==="User"
                          }),
                      
                        ]}
                        options={{
                          exportFileName:'Invoices',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          debounceInterval: 500,
                          exportCsv: async () => exportData("csv_download"),
                          exportPdf: async () => exportData("pdf_download"),
                          // filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col> 
              

               
               
               
           
                
           
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

