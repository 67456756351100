import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container } from 'reactstrap'
// import Swal from 'sweetalert2';
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import { APP_NAME } from '../../../../helpers/Constants';
import { Edit,FilterList } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../helpers/MaterialIcons';
import { useDispatch, useSelector } from 'react-redux';
import { AddSubscriptionPlans } from '../../../../store/masterdata';
import axios from 'axios';
import AddSubscriptionPlan from './AddSubscriptionPlan';
import EditSubscriptionPlan from './EditSubscriptionPlan';
// import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
// import Error from '../../../../helpers/Error';
// import LockIcon from '@material-ui/icons/Lock';
// import Unlock from "@material-ui/icons/LockOpen"

export default function SubscriptionPlans() {
    const [data,setData] = useState([])
    const [addModalShow,setAddModalShow] = useState(false)
    const [editModalShow,setEditModalShow] = useState(false)
    const [itemToBeEdited,setItemToBeEdited] = useState(null)
    const [loader,setLoader] = useState(true)
    const subscriptionPlans=useSelector((state) => state.MasterSlice.subscriptionPlans)
    const profile = useSelector((state) => state.authSlice.profile)
    const dispatch = useDispatch()
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "Subscription Plans", link: "#" },
        ],
      });

      const addModalToggle = () =>{
        setAddModalShow(!addModalShow)
      }
      const editModalToggle = () =>{
        setEditModalShow(!editModalShow)
      }
  
    useEffect(() => {

      
      const abortController=new AbortController();
      const url='/workspace_app/subscription_plan'
      axios.get(url)
      .then((response) => {
        dispatch(AddSubscriptionPlans(response.data))
        setLoader(false)
      })

      // dispatch(AddSubscriptionPlans([{id:1,operator_id:'3 months',emp_id:'3-month Plan',extn_no:593,first_name:"400",last_name:"466",designation:"Officer",email:"david@email.com",phone_no:"Its an 3 month plan",User_type:"Admin",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"},{id:2,operator_id:'6 Months',emp_id:'6- Month Plan',extn_no:574,first_name:"800",last_name:"888",designation:"Accountant",email:"Akhil@gmail.com",phone_no:"Its is an 6 month Plan",User_type:"Admin",address:"789 Fort Myres,Monreo Avenue,Florida",status:"Active"},{id:3,operator_id:'1 Year',emp_id:'1 Year Plan',extn_no:567,first_name:"1500",last_name:"1600",designation:"Chief Officer",email:"Sharon@email.com",phone_no:"It is an 1 Year Plan",User_type:"User",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"}]))
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Subscription Plans", link: "#" },
              ],
            }  
          )
          return (() => {
            abortController.abort();
            setData([]); 
          })
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
    if(subscriptionPlans !== null){
      const tempData = subscriptionPlans.map((data) => ({
        ...data
      }))
      setData(tempData)
    }
    }, [subscriptionPlans])
    

    const columns =
  [
   
    
    { title: "Plan Name", field: "plan_name"},
    // { title: "Rate", field: "rate" },
    { title: "Rent HSN", field: "rent_hsn_code",
  
  },
  { title: " Rent Amount", field: "rent_amount",
  render:rowData => <>{rowData.rent_amount ? rowData.rent_amount : "No Data"}</>
  },
  { title: "Maintenance HSN", field: "maintenance_hsn_code",
  render:rowData => <>{rowData.maintenance_hsn_code? rowData.maintenance_hsn_code : "No Data"}</>
  },
  { title: " Maintenance Amount", field: "maintenance_amount",
  render:rowData => <>{rowData.maintenance_amount ? rowData.maintenance_amount : "No Data"}</>
  },
    {title:'Description' , field:'description'},
    // {title:'Duration' , field:'duration'},
    {title:'Status' , field:'status'},

    // { title: "Contract Start", field: "contract_start"},
    // { title: "Contract End", field: "contract_end"},
  ]

   // for handling update delete activate etc....
  //  const UpdateHandler = (id,method) =>{

  //   if(method==="Deleted"){
  //   // console.log(id)
  //   const url=`/workspace_app/subscription_plan/${id}`
  //   axios.patch(url,{status:method})
  //         .then((response) =>{
  //           SuccessAlert("Subscription Deleted Successfully!")
  //           dispatch(DeleteSubscriptionPlans(id))
  //         })
  //         .catch((error) =>{
  //           const err_msg=Error(error)
  //           ErrorAlert(err_msg)
           
  //         })
  //       }
  //       else{
          
  //         const url=`/workspace_app/subscription_plan/${id}`
  //         axios.patch(url,{status:method})
  //         .then((response) =>{
  //           SuccessAlert("Subscription Updated Successfully!")
  //           dispatch(EditSubscriptionPlans(response.data))
  //         })
  //         .catch((error) =>{
  //           if(error.response.data.message){
  //             ErrorAlert(error.response.data.message)  
  //         }
  //        else{
  //         let error_msg=Error(error)
  //         ErrorAlert(error_msg)
  //        }
           
  //         })
  //       }
       
        
  //  }
  
  

    
    
  return (
    <React.Fragment>

      {addModalShow &&
      <AddSubscriptionPlan
      title='Add Subscription Plan'
      isOpen={addModalShow}
      toggle={addModalToggle}
      />

      }
      
      {editModalShow &&
      <EditSubscriptionPlan
      title='Edit Subscription Plan'
      isOpen={editModalShow}
      toggle={editModalToggle}
      item={itemToBeEdited}
      />

      }

          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Subscription Plans"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
             {profile.user_type === "Admin" ?<>
              
              <div
                className="disappearing-button-container-page-top-right" style={{marginRight:"-80px"}}>
                 <Button
                 style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                 type="button"
                 color="warning"
                 
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={addModalToggle}
                >
                  Add Subscription Plan
                </Button>
                  </div>
                  </> :null}
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={columns}
                        data={data}
                        isLoading={loader}

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },

                          
                          // {
                          //   icon: VisibilityOutlinedIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                          // },
                          {
                            icon: Edit,
                            tooltip: 'Edit Subscription',
                            onClick: (event, rowData) => {

                              // console.log(rowData)
                              // setEditClientModalShow(true)
                              setItemToBeEdited(rowData)
                              editModalToggle();
                            },
                            hidden :profile.user_type==="User"
                          },
                          // rowData => ({
                          //   icon: DeleteOutline,
                          //   tooltip: 'Delete Subscription',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       text: "You won't be able to revert this!",
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, delete it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,'Deleted')

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   disabled: rowData.birthYear < 2000
                          // }),
                          // rowData => ({
                          //   icon: Unlock,
                          //   tooltip: 'Enable Subscription',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                                
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, Enable it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,"Active")
                          //         // deleteClientData(rowData?.id)

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   hidden: rowData.status === "Active" 
                          // }),
                          // rowData => ({
                          //   icon: LockIcon,
                          //   tooltip: 'Disable Subscription',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, Disable it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,"Disabled")
                          //         // deleteClientData(rowData?.id)

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   hidden: rowData.status === "Disabled" 
                          // })
                        ]}
                        options={{
                          exportFileName:'Subscription Plans',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
                
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

