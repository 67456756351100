import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container } from 'reactstrap'
import Swal from 'sweetalert2';
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import { APP_NAME } from '../../../../helpers/Constants';
import { DeleteOutline, Edit,FilterList } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../helpers/MaterialIcons';
import { useDispatch, useSelector } from 'react-redux';
import { AddClientUser, DeleteClientUser, EditClientUser } from '../../../../store/user';
import axios from 'axios';
import AddUser from './AddUser';
import EditUser from './EditUser';
import Error from '../../../../helpers/Error';
import LockIcon from '@material-ui/icons/Lock';
import Unlock from "@material-ui/icons/LockOpen"
import SendIcon from '@mui/icons-material/Send';
import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';


export default function ClientUsers () {
    const [data,setData] = useState([])
    const [loader,setLoader] = useState(true)
    const [addModalShow,setAddModalShow] = useState(false)
    const [editModalShow,setEditModalShow] = useState(false)
    const [itemToBeEdited,setItemToBeEdited] = useState(null)
    const user=useSelector((state) => state.UserSlice.clientUser)
    const profile = useSelector((state) => state.authSlice.profile)
    const dispatch = useDispatch()
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "Client Users", link: "#" },
        ],
      });

    const addModalToggle = () => {
      setAddModalShow(!addModalShow)
    }
    const editModalToggle = () => {
      setEditModalShow(!editModalShow)
    }
  
    useEffect(() => {
      
      const abortController=new AbortController();

      const url='/users_api/client_user'
      axios.get(url)
      .then((response) => {
        dispatch(AddClientUser(response.data))
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
      })

      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Client Users", link: "#" },
              ],
            }  
          )
          return (() => {
            abortController.abort();
            setData([]); 
          })

           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

     // for handling delete activate deactivate
 const UpdateHandler = (id,method) =>{

  if(method==="Deleted"){
  // console.log(id)
  const url=`/users_api/userdetails/${id}`
  axios.patch(url,{user_status:method})
        .then((response) =>{
          SuccessAlert("User Deleted Successfully!")
          dispatch(DeleteClientUser(id))
        })
        .catch((error) =>{
          const err_msg=Error(error)
          ErrorAlert(err_msg)
         
        })
      }
      else{
        if(method === 'Activated' || method === 'Deactivated'){
        const url=`/users_api/userdetails/${id}`
        axios.patch(url,{user_status:method})
        .then((response) =>{
          SuccessAlert("User Updated Successfully!")
          dispatch(EditClientUser(response.data))
        })
        .catch((error) =>{
          const err_msg=Error(error)
          ErrorAlert(err_msg)
         
        })
      }
      else{
        const url=`/users_api/resend_invitation/${id}`
        axios.put(url)
        .then((response) =>{
          SuccessAlert("Invitation Resend Successfully!")
          // dispatch(editEnvirolUser(response.data))
        })
        .catch((error) =>{
          const err_msg=Error(error)
          ErrorAlert(err_msg)
         
        })
      }
      }
 }


    useEffect(() => {
      // console.log(user)
    if(user !== null){
      const tempData = user.map((data,index) => ({
        ...data,
        sl_no:index+1
      }))
      setData(tempData)
    }
    }, [user])
    

    const columns =
  [
   
    
    { title: "Sl No", field: "sl_no"},
    { title: "First Name", field: "first_name" },
    { title: "Last Name", field: "last_name",
  render:rowData=><>{rowData.last_name ? rowData.last_name : "No Data"}</>
  },
    {title:'Phone No' , field:'contact_number',
    render:rowData=><>{rowData.contact_number ? rowData.contact_number : "No Data"}</>

  },  
    {title:'Address' , field:'address',
    render:rowData=><>{rowData.address ? rowData.address : "No Data"}</>

  },
    { title: "Email Id", field: "email",
    render: rowData => <>{rowData.email && rowData.user_status === 'Invited' ? <span >{rowData.email} <p className="text-danger">(Activation Pending)</p></span> : rowData.email ? rowData.email :  'No Data'}</>  

  },
    { title:'User Type', field: 'user_type'},
    {title:'Status' , field:'user_status'},

    // { title: "Contract Start", field: "contract_start"},
    // { title: "Contract End", field: "contract_end"},
  ]

    
    
  return (
    <React.Fragment>

      {addModalShow &&
      <AddUser
      isOpen={addModalShow}
      toggle={addModalToggle}
      title={"Add User"}
      />
      }
      {editModalShow &&
      <EditUser
      isOpen={editModalShow}
      toggle={editModalToggle}
      title={"Edit User"}
      item={itemToBeEdited}
      />
      }

          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Client Users"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
              { profile.user_type === "Admin" ?<>
              <div
                className="disappearing-button-container-page-top-right" style={{marginRight:"-80px"}}>
                 <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={addModalToggle}
                >
                  Add Users
                </Button>
                  </div>
                  </>:null}
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={columns}
                        data={data}
                        isLoading={loader}

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },

                          
                          // {
                          //   icon: VisibilityOutlinedIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                          // },
                          {
                            icon: Edit,
                            tooltip: 'Edit user',
                            onClick: (event, rowData) => {
                              // console.log(rowData)
                              // setEditClientModalShow(true)
                              setItemToBeEdited(rowData)
                              editModalToggle();
                            },hidden:profile.user_type ==="User"
                          },
                          rowData => ({
                            icon: DeleteOutline,
                            tooltip: 'Delete User',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: "You won't be able to revert this!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, delete it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,"Deleted")
                                  // deleteClientData(rowData?.id)

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden:profile.user_type ==="User"
                          }),
                          rowData => ({
                            icon: SendIcon,
                            tooltip: 'Reinvite User',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'info',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Send!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,"invite")
                                  // deleteClientData(rowData?.id)

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden:profile.user_type ==="User"|| rowData.user_status === "Activated" || rowData.user_status === "Deactivated"
                          }),
                          rowData => ({
                            icon: Unlock,
                            tooltip: 'Enable User',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Enable it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,"Activated")
                                  // deleteClientData(rowData?.id)

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden:profile.user_type ==="User"|| rowData.user_status === "Activated" || rowData.user_status === "Invited"
                          }),
                          rowData => ({
                            icon: LockIcon,
                            tooltip: 'Disable User',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Disable it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,"Deactivated")
                                  // deleteClientData(rowData?.id)

                                
                                } 
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden:profile.user_type ==="User"|| rowData.user_status === "Deactivated" || rowData.user_status === "Invited"
                          })
                        ]}
                        options={{
                          exportFileName:'Users List',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
                
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

