import React, { useEffect,useState } from 'react'
import Modals from '../../../../components/Modal'
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch } from 'react-redux';
import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
import Error from '../../../../helpers/Error';
import axios from 'axios';


import { EditScheduler } from '../../../../store/scheduler';

function  EditSchedule(props) {

    const [waitingForAxios,setwaitingForAxios] = useState(false)
    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();

      
      useEffect(() => {
        const temp={...props.item}
        reset(temp)
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])


    const onSubmit = (data) =>{
        let formData = new FormData(); //formdata object
        //   
            formData.append("scheduler_name", data.scheduler_name);
        setwaitingForAxios(true)
        
        const url=`/workspace_app/scheduler/${props.item.id}`

        axios.patch(url,formData)  
        .then((response) =>{
            // let msg=response.data.message
            setwaitingForAxios(false)
            dispatch(EditScheduler(response.data))
            SuccessAlert('Edited Sheduler Succesfully')
            props.toggle();
            
        })
        .catch((error) => {
            setwaitingForAxios(false)
            let error_msg=Error(error)
            ErrorAlert(error_msg)
        })
       
    }

  return (
    <div><Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Scheduler Name" sm={3}>
        Scheduler Name
    </Label>
    <Col sm={9}>
        <input
            placeholder="Scheduler Name"
            type="text"
            className="form-control"
            {...register("scheduler_name", {
                required: true,
            })}
        />
        {errors.scheduler_name?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>


</ModalFooter>
</Form>
        </Modals></div>
  )
}

export default EditSchedule