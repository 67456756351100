import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container } from 'reactstrap'
import Swal from 'sweetalert2';
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import { APP_NAME } from '../../../../helpers/Constants';
import { DeleteOutline, Edit,FilterList } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../helpers/MaterialIcons';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Error from '../../../../helpers/Error';
// import LockIcon from '@material-ui/icons/Lock';
// import Unlock from "@material-ui/icons/LockOpen"
import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
// import NoSimIcon from '@mui/icons-material/NoSim';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { AddSubscriberStaff, DeleteSubscriberStaff, DisallocateRfidCard, EditSubscriberStaff } from '../../../../store/subscriber';
import AddCompanyStaff from './AddCompanyStaff';
import EditCompanyStaff from './EditCompanyStaff';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AssignRfidCardStaff from './AssignRfidCardStaff';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
// import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';




export default function Staff () {
    // const profile = useSelector((state) => state.authSlice.profile)
    const [data,setData] = useState([])
    
    const [loader,setLoader] = useState(true)
    const [addModalShow,setAddModalShow] = useState(false)
    const [editModalShow,setEditModalShow] = useState(false)
    const [itemToBeEdited,setItemToBeEdited] = useState(null)
    const[deletedData,setDeletedData]=useState(false)
    const staff=useSelector((state) => state.SubscriberSlice.subscriberStaff)
    // const rfidCard=useSelector((state) => state.RfidSlice.rfidCard)
    const dispatch = useDispatch()
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "Staffs", link: "#" },
        ],
      });
     const[assignModalShow,setAssignModalShow] =useState(false)

    const addModalToggle = () => {
      setAddModalShow(!addModalShow)
    }
    const editModalToggle = () => {
      setEditModalShow(!editModalShow)
    }
  

    const assignModalToggle=()=>{
      setAssignModalShow(!assignModalShow)
    }
    useEffect(() => {
      
      const abortController = new AbortController();

      const url=`/workspace_app/staffs_subscriber`
    //   const url='/workspace_app/staffs_subscriber/16'
      axios.get(url)
      .then((response) => {
        dispatch(AddSubscriberStaff(response.data))
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
      })

      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Staffs", link: "#" },
              ],
            }  
          )

          return (() => {
            abortController.abort();
            setData([]); 
          })
        

           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

     // for handling delete activate deactivate
 const UpdateHandler = (id,method) =>{
  //console.log(method)
  if(method==="Deleted"){
  // console.log(id)
  const url=`/workspace_app/staffs/${id}`
  axios.patch(url,{status:method})
        .then((response) =>{
          SuccessAlert("Staff Deleted Successfully!")
          dispatch(DeleteSubscriberStaff(id))
        })
        .catch((error) =>{
          const err_msg=Error(error)
          ErrorAlert(err_msg)
         
        })
      }
      else{
        if(method === 'Active' || method === 'Disabled'){
          const url=`/workspace_app/staffs/${id}`
          axios.patch(url,{status:method})
          .then((response) =>{
            SuccessAlert(`Staff Updated Successfully!`)
            dispatch(EditSubscriberStaff(response.data))
          })
          .catch((error) =>{
            const err_msg=Error(error)
            ErrorAlert(err_msg)
           
          })
      }
      // else if(method === 'Lost'){
      //   const url=`/workspace_app/lost_rfid/${id}`
      //   axios.put(url)
      //   .then((response) =>{
          
      //     SuccessAlert("Rfid Card Enbled Successfully!")
      //     dispatch(EnableDisableRfidCard({...response.data,id:id}))
      //   })
      //   .catch((error) =>{
          
      //     ErrorAlert(error.response.message)
         
      //   })
      // }
      else if(method === 'Remove') {
        const url=`/workspace_app/disallocate_staff_rfid/${id}`
        axios.put(url)
        .then((response) =>{
          SuccessAlert("Rfid Disallocated Successfully!")
          dispatch(DisallocateRfidCard({...response.data,id:id}))
        })
        .catch((error) =>{
          ErrorAlert(error.response.data.message)
         
        })
      }
      }
      
 }

 const staffHandler=()=>{
  setLoader(true)
  if(deletedData === false){
    
    const url=`/workspace_app/staffs_subscriber/1`
    //   const url='/workspace_app/staffs_subscriber/16'
      axios.get(url,)
      .then((response) => {
        dispatch(AddSubscriberStaff(response.data))
        setDeletedData(true)
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
      })


  }
  else{
     setLoader(true)
    const url=`/workspace_app/staffs_subscriber`
    //   const url='/workspace_app/staffs_subscriber/16'
      axios.get(url)
      .then((response) => {
        dispatch(AddSubscriberStaff(response.data))
        setLoader(false)
        setDeletedData(false)
      })
      .catch((error) => {
        setLoader(false)
      })
  }


 }


    useEffect(() => {
      // console.log(user)
    if(staff !== null){
      const tempData = staff.map((data) => ({
        ...data,
        rfid:data?.rfid_staff?.tag_id ? data.rfid_staff.tag_id : 'No Rfid Card ',
        rfid_name:data?.rfid_staff?.friendly_name? data.rfid_staff.friendly_name : 'No Rfid Card ',
        
      }))
      setData(tempData)
    }
    }, [staff])
    

    const columns =
  [
   
    {title:'Rfid ' , field:'rfid'},
    {title:'Rfid Name' , field:'rfid_name'},
    { title: "Reg No", field: "reg_no"},
    { title: "First Name", field: "first_name" },
    { title: "Last Name", field: "last_name"},
    {title:'Phone No' , field:'contact_number'},
    
  
   
    // {title:'Address' , field:'address'},
    { title: "Email Id", field: "email",
    render: rowData => <>{rowData.email ? rowData.email :  'No Data'}</>  

  },
    // { title:'User Type', field: 'user_type'},
    {title:'Status' , field:'status'},

    // { title: "Contract Start", field: "contract_start"},
    // { title: "Contract End", field: "contract_end"},
  ]

    
    
  return (
    <React.Fragment>


      {addModalShow &&
      <AddCompanyStaff
      isOpen={addModalShow}
      toggle={addModalToggle}
      title={"Add Staff"}
      />
      }
      
      {editModalShow &&
      <EditCompanyStaff
      isOpen={editModalShow}
      toggle={editModalToggle}
      title={"Edit Staff"}
      item={itemToBeEdited}
      />
      }
      {assignModalShow && 
      <AssignRfidCardStaff
      isOpen={assignModalShow}
      toggle={assignModalToggle}
      title={"Assign Rfid card "}
      item={itemToBeEdited}
      />}
      
      

          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Staffs"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
              
              <div
                className="disappearing-button-container-page-top-right" style={{marginRight:"-80px"}}>
                 <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={addModalToggle}
                >
                  Add Staff
                </Button>
                  </div>
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={columns}
                        data={data}
                        isLoading={loader}

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },

                          
                          // {
                          //   icon: VisibilityOutlinedIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                          // },
                          rowData => ({
                            icon: AddCircleIcon,
                            tooltip: 'Assign Rfid Card',
                            onClick: (event, rowData) => {
                              setItemToBeEdited(rowData.id)
                              assignModalToggle()
                             

                              
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                           hidden:rowData.rfid_staff !== null||rowData.status==="Deleted"
                          }),
                          // rowData => ({
                          //   icon: CreditCardOffIcon,
                          //   tooltip: 'Lost Rfid',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                                
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, Updated it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.rfid_staff.id,"Lost")
                          //         // deleteClientData(rowData?.id)

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //  hidden: profile.user_type ==="User" || rowData.rfid_staff === null
                           
                          // }),

                          
                          {
                            icon: Edit,
                            tooltip: 'Edit Staff',
                            onClick: (event, rowData) => {
                              // console.log(rowData)
                              // setEditClientModalShow(true)
                              setItemToBeEdited(rowData)
                              editModalToggle();
                            }
                          },
                          rowData => ({
                            icon: DeleteOutline,
                            tooltip: 'Delete Staff',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text: "You won't be able to revert this!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, delete it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,"Deleted")
                                  // deleteClientData(rowData?.id)

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden:rowData.status==="Deleted"
                           
                          }),
                          // rowData => ({
                          //   icon: Unlock,
                          //   tooltip: 'Enable Staff',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                                
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, Enable it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,"Active")
                          //         // deleteClientData(rowData?.id)

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   hidden: rowData.status === "Active" || rowData.status === "Invited"
                          // }),
                          // rowData => ({
                          //   icon: LockIcon,
                          //   tooltip: 'Disable Staff',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, Disable it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,"Disabled")
                          //         // deleteClientData(rowData?.id)

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   hidden: rowData.status === "Disabled" || rowData.status === "Invited"
                          // }),
                          rowData => ({
                            icon: DoDisturbIcon,
                            tooltip: 'Disallocate Rfid',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Disallocate it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,'Remove')

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden:rowData.rfid_staff === null||rowData.status==="Deleted"
                          }),

                          
                          {
                            icon: deletedData === false ?VisibilityOutlinedIcon:VisibilityOff ,
                            tooltip:deletedData === false ?'View Deteted Staffs':'View Active Staffs ',
                            isFreeAction: true,
                            onClick: (event) => {
                            
                              staffHandler();
                            }
                            
                          },
                        ]}
                        options={{
                          exportFileName:'Staff List',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
                
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

