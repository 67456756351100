import { useEffect, useState } from "react";
// import { publicAxios, authAxios } from "./axiosInstance";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import AbaciLoader from "./components/AbaciLoader/AbaciLoader";
import { useNavigate } from "react-router-dom";
import { setLogin, setLogOut } from "./store/auth";
import Unauthorized from "./containers/Pages/Error/Unauthorized"
import Error from "./containers/Pages/Error"
import axios from "axios";

export default function PrivateRoute(props) {
  // const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [error, setError] = useState(null);
  // let history = window
  // const [entityType, setEntityType] = useState(null);
  const auth = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log(history)
  // }, [history])
  

  useEffect(() => {
    // console.log("auth is ",auth)
    if (auth.loggedIn === null){
      const token = Cookies.get("token");
      if (typeof token === "undefined") {
        dispatch(setLogOut())
        navigate("/login");
      }
      else{
        const url = "users_api/profile";
        axios
          .get(url)
          .then((response) => {
            if (response.data.user_status === "Activated") {
              dispatch(setLogin({user:response.data}))
              // navigate("/dashboard");
            }
            else {
              Cookies.remove("token");
              dispatch(setLogOut())
              navigate("/login");
            }
          })
          .catch((error) => {
            if (!error.response) {
              setError(502);
            } else {
              const errorStatusTemp = error.response.status;
              if (errorStatusTemp === 401 || errorStatusTemp=== 403) {
                Cookies.remove("token");
                dispatch(setLogOut())
                navigate("/login");
              } else {
                setError(errorStatusTemp)
              }
            }
          })
      }
    }
    
      

  
  });
  if (error !== null) {
    return (<Error error={error} />)
  }
  else if (auth.loggedIn) {
    // return <></>
    if (props.allowedTo.includes(auth.profile.user_class)) {
      return <>{props.children}</>
      }
      else{
        return <Unauthorized />
      }
  }
  else {
    return <AbaciLoader/>;
  }
}
