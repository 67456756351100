import React, { useState, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useForm } from "react-hook-form";
// import swal from "sweetalert";
import {
 
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Swal from "sweetalert2";
export default function ImageCropper(props) {
  const [image, setImage] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [cropper, setCropper] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const ModalToggle = () => {
    setIsModalVisible(!isModalVisible);
  };
  const { register, reset } = useForm();

  const onChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const uploadedFile = e.target.files[0];
    // props.setUserImageFile(uploadedFile);
    if (uploadedFile.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
      showModal();
    } else {
      Swal.fire({
        title: "Oops...",
        text: "The selected file is not an image!",
        icon: "error",
      });
    }
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(uploadedFile);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    reset({ imageInput: null });
    setIsModalVisible(false);
    getCropData();
  };


  const getCropData = () => {
    if (typeof cropper !== "undefined") {
 
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };
  useEffect(() => {
    props.setCropppedImage(cropData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cropData]);
  return (
    <div>
      <div style={{ width: "100%" }}>
        <input
          disabled={props.disabled}
          className="form-control form-control-sm"
          id="customFile"
          {...register("imageInput")}
          onChange={onChange}
          type="file"
        />

        <Modal
          size={props.size}
          centered
          isOpen={isModalVisible}
          toggle={ModalToggle}
        >
          <ModalHeader toggle={ModalToggle}>
          
          {   "Crop the Profile Picture"}
          </ModalHeader>
          <ModalBody>    <Cropper
              style={{ height: 380, width: "100%" }}
              zoomTo={1}
              aspectRatio={1}
              preview=".img-preview"
              src={image}
              viewMode={1}
              guides={true}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance) => {
            
                setCropper(instance);
              }}
            />
            <br />
            <p>Scroll for zooming in and out...</p></ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="primary"
              className="w-xs waves-effect waves-light me-1"
              onClick={handleOk}
            >
              Save
            </Button>
            <Button
              type="button"
              color="danger"
              className="w-xs waves-effect waves-light me-1"
              onClick={ModalToggle}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>

        {/* <Modal
          title="Crop the Profile Picture"
          visible={isModalVisible}
          footer={null}
        >
          <div style={{ margin: "0 50px 0 50px" }}>
        
          </div>
          <AbaciHorizontalLine />
     
          <div className="flex-footer-container-modal">
            <div className="button-container-modal">
              <AbaciButton
                width="100px"
                height="40px"
                textcolor="white"
                color="#FF8585"
                waveColor="#F5B9B9"
                onClick={handleCancel}
              >
                Cancel
              </AbaciButton>
            </div>
            <div className="button-container-modal">
              <AbaciButton
                width="100px"
                height="40px"
                textcolor="white"
                color="#3CA1B1"
                waveColor="#3BCBE2"
                onClick={handleOk}
              >
                Save
              </AbaciButton>
            </div>
          </div>
        </Modal> */}
      </div>
    </div>
  );
}
