
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loginReport : null,
    doorAccess  : null,
    transaction : null,
    payment : null,
};

const ReportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers:{
        AddLoginReport(state,action) {
            state.loginReport=action.payload
        },
        AddDoorAccessReport(state,action) {
            state.doorAccess=action.payload
        },
        AddTransactionReport(state,action) {
            state.transaction=action.payload
        },
        AddPayment(state,action){
            state.payment = action.payload
        },
        AddNewPayment(state,action){
            state.payment=[...state.payment,action.payload]
        },
        EditPayment(state,action){
            state.payment=state.payment.map((data) => 
                data.id === action.payload.id ? action.payload : data
            )
        }
    }
})

export const {AddLoginReport , AddDoorAccessReport , AddTransactionReport , AddPayment , AddNewPayment , EditPayment} = ReportSlice.actions;
export default ReportSlice.reducer;