import React, { useEffect } from "react";
import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { useSelector } from "react-redux";
// import Unauthorized from "../../containers/Pages/Error/Unauthorized";


export default function SidebarContent(props) {
  const profile = useSelector((state) => state.authSlice.profile)
  const location = useLocation();

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  if (profile !== null) {
    
    if (profile.user_class === "Hilite") {
  return (
    
 
    
    <React.Fragment>
    <div id='sidebar-menu'>
      <ul className='metismenu list-unstyled' id='side-menu'>
        <li className='menu-title'>{"Menu"}</li>

        <li>
          <Link to='/dashboard' className='waves-effect'>
            <i className='ri-dashboard-line'style={{color:location.pathname==='/dashboard'?'white':'#8590a5'}}></i>
            {/* <span className='badge rounded-pill bg-success float-end'>3</span> */}
            <span className='ms-1' style={{color:location.pathname==='/dashboard'?'white':'#8590a5'}}>{"Dashboard"}</span>
          </Link>
        </li>

        <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-user-settings-line"></i>
                  <span className="ms-1">{"Subscribers"}</span>
                </Link>
                <ul className="sub-menu">
                  <li><Link to="/subscribers" style={{ color: location.pathname === '/subscribers' ? 'white' : '#8590a5' }}>{"All Subscribers"}</Link></li>
                  <li><Link to="/subscriberstaffs" style={{ color: location.pathname === '/subscriberstaffs' ? 'white' : '#8590a5' }}>{"Staffs"}</Link></li>                  
                  <li><Link to="/subscriberworkstations" style={{ color: location.pathname === '/subscriberworkstations' ? 'white' : '#8590a5' }}>{"Subscriber Workstations"}</Link></li>                  
                </ul>
              </li>
              <li>
          <Link to='/managerfidcard' className=' waves-effect'>
            <i className='ri-bank-card-fill' style={{color:location.pathname==='/managerfidcard'?'white':'#8590a5'}}></i>
            <span className='ms-1'style={{color:location.pathname==='/managerfidcard'?'white':'#8590a5'}}>{"Manage Rfid Card"}</span>
          </Link>
        </li>
        {profile.user_type === "Admin" ?<>
        <li>
          <Link to='/doors' className=' waves-effect'>
            <i className='ri-door-lock-line' style={{color:location.pathname==='/doors'?'white':'#8590a5'}}></i>
            <span className='ms-1'style={{color:location.pathname==='/doors'?'white':'#8590a5'}}>{"Doors"}</span>
          </Link>
        </li>
        

        
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-folder-3-fill"></i>
                  <span className="ms-1">{"Reports"}</span>
                </Link>
                <ul className="sub-menu">
                  <li><Link to="/logindetails" style={{ color: location.pathname === '/logindetails' ? 'white' : '#8590a5' }}>{"Login Details"}</Link></li>
                  <li><Link to="/dooraccessdetails" style={{ color: location.pathname === '/dooraccessdetails' ? 'white' : '#8590a5' }}>{"Door Access Details"}</Link></li>
                  
                  
                </ul>
              </li>
              </>:null}
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-database-2-line"></i>
                  <span className="ms-1">{"Master Data"}</span>
                </Link>
                <ul className="sub-menu">
                  <li><Link to="/cabin" style={{ color: location.pathname === '/cabin' ? 'white' : '#8590a5' }}>{"Cabin"}</Link></li>
                  <li><Link to="/workstations" style={{ color: location.pathname === '/workstations' ? 'white' : '#8590a5' }}>{"Work Stations"}</Link></li>
                  <li><Link to="/subscriptionplans" style={{ color: location.pathname === '/subscriptionplans' ? 'white' : '#8590a5' }}>{"Subscription Plans"}</Link></li>                
                </ul>
              </li>
              <li>
            <Link to='/invoicelist' className=' waves-effect'>
              <i className='ri-file-pdf-fill' style={{ color: location.pathname === '/invoicelist' ? 'white' : '#8590a5' }}></i>
              <span className='ms-1' style={{ color: location.pathname === '/invoicelist' ? 'white' : '#8590a5' }}>{"Invoices"}</span>
            </Link>
          </li>
              <li>
          <Link to='/payment' className=' waves-effect'>
            <i className='ri-secure-payment-line' style={{color:location.pathname==='/payment'?'white':'#8590a5'}}></i>
            <span className='ms-1'style={{color:location.pathname==='/payment'?'white':'#8590a5'}}>{"Payments"}</span>
          </Link>
        </li>
        {profile.user_type === "Admin" ?<>
        <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ri-user-settings-line"></i>
                  <span className="ms-1">{"User Managment"}</span>
                </Link>
                <ul className="sub-menu">
                  <li><Link to="/clientusers" style={{ color: location.pathname === '/clientusers' ? 'white' : '#8590a5' }}>{"Hilite Users"}</Link></li>
                  <li><Link to="/subscriberusers" style={{ color: location.pathname === '/subscriberusers' ? 'white' : '#8590a5' }}>{"Subscriber Users"}</Link></li>
                </ul>
              </li>
       
        
        </>:null}
       
        {/* <li>
          <Link to='/settings' className=' waves-effect'>
            <i className='ri-settings-3-fill'style={{color:location.pathname==='/settings'?'white':'#8590a5'}}></i>
            <span className='ms-1'style={{color:location.pathname==='/settings'?'white':'#8590a5'}}>{"Settings"}</span>
          </Link>
        </li> */}
            
      </ul>
    </div>
  </React.Fragment>
  );
}
else if (profile.user_class  === "Subscriber") { 
  return(
  <React.Fragment>
  <div id='sidebar-menu'>
    <ul className='metismenu list-unstyled' id='side-menu'>
      <li className='menu-title'>{"Menu"}</li>

      <li>
        <Link to='/dashboard' className='waves-effect'>
          <i className='ri-dashboard-line'style={{color:location.pathname==='/dashboard'?'white':'#8590a5'}}></i>
          {/* <span className='badge rounded-pill bg-success float-end'>3</span> */}
          <span className='ms-1' style={{color:location.pathname==='/dashboard'?'white':'#8590a5'}}>{"Dashboard"}</span>
        </Link>
      </li>
      <li>
            <Link to={`/subscriberdetails`} className=' waves-effect'>
              <i className='ri-building-4-line' style={{ color: location.pathname === `/subscriberdetails` ? 'white' : '#8590a5' }}></i>
              <span className='ms-1' style={{ color: location.pathname === `/subscriberdetails` ? 'white' : '#8590a5' }}>{"Company Detail"}</span>
            </Link>
          </li>
          <li>
            <Link to='/staffs' className=' waves-effect'>
              <i className='ri-user-settings-line' style={{ color: location.pathname === '/staffs' ? 'white' : '#8590a5' }}></i>
              <span className='ms-1' style={{ color: location.pathname === '/staffs' ? 'white' : '#8590a5' }}>{"Staff Managment"}</span>
            </Link>
          </li>
          <li>
            <Link to='/invoices' className=' waves-effect'>
              <i className='ri-file-pdf-fill' style={{ color: location.pathname === '/invoices' ? 'white' : '#8590a5' }}></i>
              <span className='ms-1' style={{ color: location.pathname === '/invoices' ? 'white' : '#8590a5' }}>{"Invoices"}</span>
            </Link>
          </li>

    </ul>
  </div>
</React.Fragment>
  )
}
  }
}