import React, { useEffect, useState } from 'react'
import Modals from '../../../../components/Modal'
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
// import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
// import Error from '../../../../helpers/Error';
import axios from 'axios';
import Error from '../../../../helpers/Error';
import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
import { EditSubscriberStaff } from '../../../../store/subscriber';


function AssignRfidCardStaff(props) {

    const profile = useSelector((state) => state.authSlice.profile)

    const [waitingForAxios,setwaitingForAxios] = useState(false)
    const [rfidCard,setRfidCard] = useState([])
    // const [loader,setLoader] = useState(true)
    const dispatch=useDispatch();
    const { 
        register,
        handleSubmit,
       
        formState: { errors },
      } = useForm();

      useEffect(() => {
        
        axios.get('/workspace_app/assign_rfid_staff').then((response)=>setRfidCard(response.data))
        
    }, [])
      
    const onSubmit = (data) =>{
        

        let formData = new FormData();

        formData.append('rfid',data.rfid)
        formData.append("company", profile.link_id);
        formData.append("staff", props.item);
        setwaitingForAxios(true)
        
        const url='/workspace_app/assign_rfid_staff'

        axios.post(url,formData)
        .then((response) =>{
            // let msg=response.data.message
            setwaitingForAxios(false)
            dispatch(EditSubscriberStaff(response.data))
            SuccessAlert('Rfid Card Assiigned Succesfully')
            props.toggle();
        })
        .catch((error) => {
            setwaitingForAxios(false)
            let error_msg=Error(error)
            ErrorAlert(error_msg)
        })
       
    }
   
    

  return (
    <div><Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>

        <FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Rfid Card" sm={3}>
        Rfid Card <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
    <select className="form-control"
                  {...register("rfid", {
                    required: true,

                  })}>
 <option value="">      --Select Rfid Card--</option>
                  {rfidCard.map((item,index) => {
                    return <option key={index} value={item.id}>{item.friendly_name}</option>
                  })}
    </select>
    
        {errors.rfid?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>


</ModalFooter>
</Form>
        </Modals></div>
  )
}

export default AssignRfidCardStaff