import React from 'react'
import { Modal, ModalHeader,ModalBody } from "reactstrap";
import GraphLoader from './GraphLoader/GraphLoader';

function Modals(props) {
  
    // useEffect(() => {
    //     console.log(props.isModelOpen)
    //   }, [props.isModelOpen])
      
  return (
    <Modal
        isOpen={props.isOpen}
    centered
    scrollable
    size={props.size ? props.size : "lg"}
    toggle={props.toggle}
    innerRef={props.ref}
    zIndex={'1002'}
  >
    {props.loader ? <div style={{zIndex:"9999"}}> <GraphLoader /> </div> : null}

    <ModalHeader toggle={props.toggle}>
      {props.title}
    </ModalHeader>
    <ModalBody>
     {props.children}
    </ModalBody>
  
  </Modal>
  )
}

export default Modals