import React, { useEffect, useState } from 'react'
import {  Card, CardBody, Col, Container } from 'reactstrap'
import Tooltip from '@mui/material/Tooltip';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { APP_NAME } from '../../../helpers/Constants';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../helpers/MaterialIcons'; 
// import { useSelector } from 'react-redux';
import Fab from '@mui/material/Fab';
import PaymentIcon from '@mui/icons-material/Payment';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import Moments from '../../../helpers/Moment';
import { baseURL } from '../../../helpers/baseURL';
import { FilterList } from '@material-ui/icons';
import {  Toastr } from '../../../helpers/Alert';
import Error from '../../../helpers/Error';
import fileDownload from 'js-file-download'


export default function InvoicesCompany () {
   
    // const navigate = useNavigate();
    const [data,setData] = useState([])
   
    const [loader,setLoader] = useState(true)
   
    const [filterEnabled, setFilterEnabled] = useState(false);
    // const profile = useSelector((state) => state.authSlice.profile)
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "Invoices", link: "#" },
        ],
      });

      const downloadHandler = (data) =>{
       
      
        const url = data
        const name = data.split('/')[2]
       
        axios.get(url, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, name);
        });
    
    }
        
     
    
    useEffect(() => {

      const abortController = new AbortController();
  
        const url='/workspace_app/invoice'
      axios.get(url)
      .then((response) => {
          const temp = response.data.map((data)=>({

            ...data,bill_date:data?.bill_date? Moments(data.bill_date):'No Data',
            cgst_amount:data?.cgst_amount ? data.cgst_amount : 'No Data',
            sgst_amount:data?.sgst_amount ? data.sgst_amount : 'No Data',
         
            amount_without_gst:data?.amount_without_gst? data.amount_without_gst:'No Data',
          }))
           setLoader(false)
           setData(temp)

       
          })
      .catch((error) => {
        setLoader(false)
      })
   
      // dispatch(AddSubscriber([{id:1,operator_id:8765,company_name:'Noux',emp_id:1001,extn_no:593,first_name:"David",last_name:"Jacobs",designation:"Officer",email:"david@email.com",phone_no:"0501231000",User_type:"Admin",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"},{id:2,operator_id:2678,emp_id:1002,company_name:'Element 8',extn_no:574,first_name:"Akhil",last_name:"Prem",designation:"Accountant",email:"Akhil@gmail.com",phone_no:"0501231001",User_type:"Admin",address:"789 Fort Myres,Monreo Avenue,Florida",status:"Active"},{id:3,operator_id:3567,emp_id:1003,company_name:'Rad',extn_no:567,first_name:"Sharon",last_name:"VArghese",designation:"Chief Officer",email:"Sharon@email.com",phone_no:"05012310021",User_type:"User",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"}]))
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Invoices", link: "#" },
              ],
            }  
          )
          
          return (() => {
            abortController.abort();
            setData([]); 
          })
        
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



  

    const column =
  [
   
    
    { title:'Invoice ID', field:'invoice_id' ,
    render:rowData =><> {rowData.invoice_id ? rowData.invoice_id : 'No Data'}</>,
  },
  { title:'Bill Date', field:'bill_date',
   },
 
  { title:'CGST Amount', field:'cgst_amount'},
  { title:'SGST Amount', field:'sgst_amount'},
  {title: " Amount (Without GST)", field: "amount_without_gst"},
  { title:'Amount', field:'amount',
  render:rowData =><>{rowData.amount? rowData.amount: 'No Data'}</>,
  },
  { title: "", field: "payment_status",
    render:rowData => <>
       
        <Tooltip title={rowData.payment_status}>
          
        <Fab size='small' style={{width:'40px',borderRadius:'50px',backgroundColor:rowData.payment_status === 'Generated' || rowData.payment_status === 'Due' ? 'orange' : rowData.payment_status === 'Paid' ? 'Green' : rowData.payment_status === 'Overdue' ? 'red' : null}}/>
       </Tooltip>
  
      </>

  },
   

  ]




  function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}




  
async function displayRazorpay(id) {
 

  
  const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
  );

  if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
  }

  // creating a new order
  
  const result = await axios.post("/workspace_app/razorpay_order",{invoice_id:id})
  .catch((error) => {
    if(error.response.data){
      Toastr('warning',error.response.data)
    }
    else{
    let err_msg=Error(error)
    Toastr('warning',err_msg)
    }
  })

 

  if (!result) {
      //console.log(result)
      return;
  }

  // Getting the order details back
  //  const {merchantId=null , amount=null,currency=null,orderId=null } = result.data;

  const options = {
      key: result.data.merchantId,
      amount: result.data.amount.toString(),
      currency: result.data.currency,
      name: result.data.name,
      description: "Test Transaction",
      image:'',
      order_id: result.data.orderId,
      callback_url: baseURL+result.data.callback_url,
      redirect: true,
     
  
      theme: {
          color: "#61dafb",
      },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}
    
    
  return (
    <React.Fragment>
      
          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Invoices"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
                 <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={column}
                        data={data}
                        isLoading={loader}

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },
                          rowData => ({
                            icon: DownloadIcon,
                            tooltip: 'Download Invoice',
                           
                            onClick: (event) => {downloadHandler(rowData.invoice_file,'download')}
                          }),

                         
                          rowData => ({
                          icon: PaymentIcon,
                          tooltip: 'Make Payment',
                          onClick: (event, rowData) => {
                           
                           displayRazorpay(rowData.id);
                          
                          },
                          hidden: rowData.payment_status === "Paid" ||rowData.payment_status === "Due" ||rowData.payment_status === "Overdue"
                          
                        }),
                        ]}
                        options={{
                          exportFileName:'Invoices',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col> 
              

               
               
               
           
                
           
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

