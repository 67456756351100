import React, { useEffect, useState } from 'react'
import Modals from '../../../../../../components/Modal';
import { useForm } from "react-hook-form";
import { Button,  Col, Form, FormGroup, Input, Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
// import { useDispatch } from 'react-redux';
import { ErrorAlert ,SuccessAlert} from '../../../../../../helpers/Alert';
import Error from '../../../../../../helpers/Error';
import axios from 'axios';
import CustomizedCheckbox from '../../../../../../components/CustomCheckBox';



function GenerateInvoice(props) {
    // const [options,setOptions] = useState([])

    // const [ids,setIDs] = useState([])
    // const [subscription,setSubscription] = useState([])
    const [value,setValue] = useState('')
    const [waitingForAxios,setwaitingForAxios] = useState(false)
    const [checked, setChecked] = useState(true);
    // const dispatch = useDispatch()

    // const [loader,setLoader] = useState(true)
    // const workstation=useSelector((state)=>state.SubscriberSlice.subscriberWorkStation)

    const {
        register,
        handleSubmit,
        reset,
        
        formState: { errors },
      } = useForm();
    
      useEffect(() => {
        
        const temp={...props.item}
        reset(temp)
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.item])
      

    const onSubmit = (data) =>{

        let formData = new FormData(); //formdata object
        formData.append("company_id", props.id);
       formData.append("end_date", data.end_date);
       formData.append("send_mail",value === 'Yes' ? true : false);
       if(checked){
        formData.append('bill_date', Number(1));
      }
      else{
        formData.append('bill_date', Number(0));
      }
  

        setwaitingForAxios(true)
        
        const url=`/workspace_app/generate_invoice`

        axios.post(url,formData)
        .then((response) =>{
            // let msg=response.data.message
            setwaitingForAxios(false)
           
            SuccessAlert('Invoice Generated Succesfully')
            props.toggle();
           // console.log(response.data)
        })
        .catch((error) => {
            console.log(error)
            setwaitingForAxios(false)
            
            let error_msg=Error(error)
            ErrorAlert(error_msg)
        })
       
    }
    const checkBoxHandleChange = (event) => {
        setChecked(event.target.checked);
      };
    

  return (
    <Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} size={props.size} >
    <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="End Date" sm={3}>
    End Date <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder=" End Date"
            type="date"
            
            // min={new Date().toISOString().split("T")[0]}
            className="form-control"
            {...register("end_date", {
                required: true,
            })}
        />
        {errors.subscription_end?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>



<FormGroup row style={{ marginBottom: "10px"}}>
    <Label for="Sendemail" sm={3}>
        Send Email ? <span style={{color:'red'}}>*</span>
    </Label>
   <Col sm={2} style={{ marginTop: "10px"}} >
   <Label check><Input 
        name="radio1"
        type="radio"
        value="Yes"
        required={true}
        onChange={(event) => setValue(event.target.value)}
      />
      {' '}
    
        Yes
      </Label></Col>
    
    <Col sm={2} style={{ marginTop: "10px"}} >
    <Label check>
    <Input
        name="radio1"
        type="radio"
        value='No'
        required={true}

        onChange={(event) => setValue(event.target.value)}

      />
      {' '}
      
        No
      </Label></Col>
   
      
    
 
</FormGroup>
<FormGroup style={{display:'flex',justifyContent:'start'}}>

                        
                          
<CustomizedCheckbox
    
  checked={checked}
  onChange={checkBoxHandleChange}
  inputProps={{ 'aria-label': 'controlled' }}
  style={{color:'gray' }}

  /> 
  

  



<span style={{ marginLeft:'15px'  ,marginTop:'3px' }}>End date as bill date ?</span>



</FormGroup>



          
  <ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>
  
  
  </ModalFooter>
      </Form>
      </Modals>



  )
}

export default GenerateInvoice