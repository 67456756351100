import React, { useState } from 'react'
import Modals from '../../../components/Modal'
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch } from 'react-redux';
import { ErrorAlert, SuccessAlert } from '../../../helpers/Alert';
import Error from '../../../helpers/Error';
import axios from 'axios';
import {  NewRfidCard } from '../../../store/rfidcard';

function AddRfidCards(props) {

    const [waitingForAxios,setwaitingForAxios] = useState(false)
    // const [designation,setDesignation] = useState([])
    // const [loader,setLoader] = useState(true)
    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
       
        formState: { errors },
      } = useForm();


    const onSubmit = (data) =>{
        setwaitingForAxios(true)
        
        const url='/workspace_app/rfid_card'

        axios.post(url,data)
        .then((response) =>{
            // let msg=response.data.message
            setwaitingForAxios(false)
            dispatch(NewRfidCard(response.data))
            SuccessAlert('New Rfid Card Added Succesfully')
            props.toggle();
        })
        .catch((error) => {
            setwaitingForAxios(false)
        if(error.response?.data?.tag_id[0]){
                ErrorAlert(error.response?.data?.tag_id[0])

            }
        else{
                let error_msg=Error(error)
                ErrorAlert(error_msg)
       }
        })
       
    }

  return (
    <div><Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Serial No" sm={3}>
        Serial No <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Serial No"
            type="text"
            className="form-control"
            {...register("tag_id", {
                required: true,
            })}
        />
        {errors.tag_id?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Friendly Name" sm={3}>
        Friendly Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Friendly Name"
            type="text"
            className="form-control"
            {...register("friendly_name", {
                required: true,
            })}
        />
        {errors.friendly_name?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>


</ModalFooter>
</Form>
        </Modals></div>
  )
}

export default AddRfidCards