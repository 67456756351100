import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container } from 'reactstrap'
import Swal from 'sweetalert2';
import Breadcrumbs from '../../../components/Common/Breadcrumb'

import { APP_NAME } from '../../../helpers/Constants';
import { Edit,FilterList } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../helpers/MaterialIcons';
import { useDispatch, useSelector } from 'react-redux';
import { AddRfidCard, DeleteRfidCard, EnableDisableRfidCard, DisallocateOrLostRfidCard} from '../../../store/rfidcard';
import axios from 'axios';

import AddRfidCards from './AddRfidCard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditRfidCards from './EditRfidCard';
import Error from '../../../helpers/Error';
import { ErrorAlert, SuccessAlert } from '../../../helpers/Alert';
import AssignRfidCards from './AssignRfidCard';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RFidCardBulkAssign from './RfidCardBulkAssign';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import Moments from '../../../helpers/Moment';

export default function ManageRfidCard () {
    const [data,setData] = useState([])
    const rfidCard=useSelector((state) => state.RfidSlice.rfidCard)
    const profile = useSelector((state) => state.authSlice.profile)
    const [addModalShow,setAddModalShow] = useState(false)
    const [editModalShow,setEditModalShow] = useState(false)
    const [assignModalShow,setAssignModalShow] = useState(false)
    const [bulkAssignModalShow,setBulkAssignModalShow] = useState(false)
    const [itemToBeEdited,setItemToBeEdited] = useState(null)
    const [loader,setLoader] = useState(true)
    const dispatch = useDispatch()
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "Manage RFID Card", link: "#" },
        ],
      });

     
       
      const addModalToggle = () => {
        setAddModalShow(!addModalShow)
      }
      const editModalToggle = () => {
        setEditModalShow(!editModalShow)
      }
      const assignModalToggle = () => {
        setAssignModalShow(!assignModalShow)
      }
      const bulkAssignModalToggle = () => {
        setBulkAssignModalShow(!bulkAssignModalShow)
      }
  
    useEffect(() => {
      
      const abortController=new AbortController();

      const url='/workspace_app/rfid_card'
      axios.get(url)
      .then((response) => {
        dispatch(AddRfidCard(response.data))
        setLoader(false)
      })


      // dispatch(AddRfidCard([{id:1,operator_id:8765,emp_id:1001,extn_no:593,first_name:"Card 1",last_name:"Noux",designation:"Officer",email:"12/12/2022",phone_no:"Akhil",User_type:"Admin",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"},{id:2,operator_id:2678,emp_id:1002,extn_no:574,first_name:"Card 2",last_name:"Element 8",designation:"Accountant",email:"12/12/2022",phone_no:"Danish",User_type:"Admin",address:"789 Fort Myres,Monreo Avenue,Florida",status:"Active"},{id:3,operator_id:3567,emp_id:1003,extn_no:567,first_name:"Card 3",last_name:"RAD",designation:"Chief Officer",email:"12/12/2022",phone_no:"Dipil",User_type:"User",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"}]))
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Manage RFID Card", link: "#" },
              ],
            }  
          )
          return()=>{
            abortController.abort();
            setData([]); 
          }
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
    if(rfidCard !== null){
      const tempData = rfidCard.map((data) => ({
        ...data,
        company_name:data?.company?.company_name ? data.company.company_name : 'No Data',
        company_address:data?.company?.address ? data.company.address : 'No Data',
        created_date:data?.created_date ? Moments(data.created_date) : 'No Data',
        staff_name:data?.staff?.full_name? data.staff.full_name:'No Data'


      }))
      setData(tempData)
    }
    }, [rfidCard])
    

    const columns =
  [
   
    { title: "Card No", field: "tag_id"},
    { title: "Card Name", field: "friendly_name" },
    { title: "Assigned Company", field: "company_name" , 
    render:rowData => <>{rowData.company_name ? rowData.company_name : "No Data"}</>
  },
    {title:'Assigned Staff' , field:'staff_name' , 
  },
  //   {title:'Address' , field:'company_address',
  //   // render:rowData => <>{rowData.company_address ? rowData.company_address : "No Data"}</>
  // },
    { title: "Issued Date", field: "created_date",
  // render:rowData => <>{rowData.created_date ? moments(rowData.created_date) : "No Data"}</>
  },
    {title:'Status' , field:'status'},

    // { title: "Contract Start", field: "contract_start"},
    // { title: "Contract End", field: "contract_end"},
  ]

  
      // for handling delete activate deactivate
 const UpdateHandler = (id,method) =>{

  if(method==="Deleted"){
  // console.log(id)
  const url=`/workspace_app/delete_rfid/${id}`
  axios.put(url)
        .then((response) =>{
          SuccessAlert("Rfid Card Deleted Successfully!")
          dispatch(DeleteRfidCard(id))
        })
        .catch((error) =>{
          if(error.response.data.message){
            ErrorAlert(error.response.data.message)  
        }
       else{
        let error_msg=Error(error)
        ErrorAlert(error_msg)
       }
          // const err_msg=Error(error)
          // ErrorAlert(err_msg)
         
        })
      }
      else{
        if(method === 'Disabled'){
        const url=`/workspace_app/disable_rfid/${id}`
        axios.put(url)
        .then((response) =>{
          
          SuccessAlert("Rfid Card Disabled Successfully!")
          dispatch(EnableDisableRfidCard({...response.data,id:id}))
        })
        .catch((error) =>{
          
          if(error.response.data.message){
            ErrorAlert(error.response.data.message)  
        }
       else{
        let error_msg=Error(error)
        ErrorAlert(error_msg)
       }         
        })
      }
      else if(method === 'Active'){
        const url=`/workspace_app/enable_rfid/${id}`
        axios.put(url)
        .then((response) =>{
          
          SuccessAlert("Rfid Card Enbled Successfully!")
          dispatch(EnableDisableRfidCard({...response.data,id:id}))
        })
        .catch((error) =>{
          
            if(error.response.data.message){
            ErrorAlert(error.response.data.message)  
        }
       else{
        let error_msg=Error(error)
        ErrorAlert(error_msg)
       }
         
        })
      }
      else if(method === 'Lost'){
        const url=`/workspace_app/lost_rfid/${id}`
        axios.put(url)
        .then((response) =>{
          
          SuccessAlert("Rfid Card Updated Successfully!")
          dispatch(DisallocateOrLostRfidCard({...response.data,id:id}))
        })
        .catch((error) =>{
          // console.log(error)
          if(error.response.data.message){
            ErrorAlert(error.response.data.message)  
        }
       else{
        let error_msg=Error(error)
        ErrorAlert(error_msg)
       }
         
        })
      }
      
      else if(method=== 'Remove'){
        const url=`/workspace_app/diallocate_rfid/${id}`
        axios.put(url)
        .then((response) =>{
          SuccessAlert("Rfid Card Disallocated Successfully!")
          dispatch(DisallocateOrLostRfidCard({...response.data,id:id}))
        })
        .catch((error) =>{

          if(error.response.data.message){
            ErrorAlert(error.response.data.message)  
        }
       else{
        let error_msg=Error(error)
        ErrorAlert(error_msg)
       }
         })
      }
      }
 }

    
    
  return (
    <React.Fragment>
       {addModalShow &&
      <AddRfidCards
      isOpen={addModalShow}
      toggle={addModalToggle}
      title={"Add Rfid Card"}
      />
      }
      {editModalShow &&
      <EditRfidCards
      isOpen={editModalShow}
      toggle={editModalToggle}
      title={"Edit Rfid Card"}
      item={itemToBeEdited}
      />
      }
      {assignModalShow &&
      <AssignRfidCards
      isOpen={assignModalShow}
      toggle={assignModalToggle}
      title={"Assign Rfid Card"}
      item={itemToBeEdited}
      />
      }
      {bulkAssignModalShow &&
      <RFidCardBulkAssign
      isOpen={bulkAssignModalShow}
      toggle={bulkAssignModalToggle}
      title={"Assign Rfid Card"}
      />
      }
          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Manage Rfid Card"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
              { profile.user_type === "Admin" ?<> 
              <div
                className="disappearing-button-container-page-top-right" style={{marginRight:"-80px"}}>
                 <Button
                 style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                 type="button"
                 color="warning"
                  
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={addModalToggle}
                >
                  Add RFID Card
                </Button>
                <Button
                 style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                 type="button"
                 color="warning"
                 
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={bulkAssignModalToggle}
                >
                  Assign RFID Card
                </Button>
                {/* <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  
                  className="w-xs waves-effect waves-light me-1"
                  
                  // onClick={addBinModalToggle}
                >
                  Import RFID Card
                </Button> */}
                  </div>
                  </>:null}
                  
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={columns}
                        data={data}
                        isLoading={loader}

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },

                          
                          // {
                          //   icon: VisibilityOutlinedIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                          // },
                          rowData => ({
                            icon: AddCircleIcon,
                            tooltip: 'Assign Rfid Card',
                            onClick: (event, rowData) => {
                              // console.log(rowData)
                              // setEditClientModalShow(true)
                              setItemToBeEdited(rowData)
                              assignModalToggle();
                            },
                            hidden: rowData.status === "Lost"  ||  profile.user_type ==="User"|| rowData.status === "Assigned" || rowData.status === "Allocated" || rowData.company !== null
                          }),
                          rowData => ({
                            icon: Edit,
                            tooltip: 'Edit Rfid',
                            onClick: (event, rowData) => {
                              // console.log(rowData)
                              // setEditClientModalShow(true)
                              setItemToBeEdited(rowData)
                              editModalToggle();
                            },
                            hidden: rowData.status === "Allocated"  ||  rowData.status === "Lost"  || profile.user_type ==="User"
                          }),
                           
                          rowData => ({
                            icon: LockOpenIcon,
                            tooltip: 'Enable Rfid',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Enable it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,"Active")
                                  // deleteClientData(rowData?.id)

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden:  rowData.status === "Lost"  || profile.user_type ==="User"|| rowData.status === "Invited"  || rowData.status === "Available" || rowData.status === 'Active' || rowData.status === 'Allocated'
                          }),
                          rowData => ({
                            icon: CreditCardOffIcon,
                            tooltip: 'Lost Rfid',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                text:'After Doing this,The Card With This No Cant Be Used Anymore',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Update it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,"Lost")
                                  // deleteClientData(rowData?.id)

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                           hidden: rowData.status === "Lost"  || profile.user_type ==="User"
                           
                          }),


                          // rowData => ({
                          //   icon: DeleteOutline,
                          //   tooltip: 'Delete Rfid',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       text: "You won't be able to revert this!",
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, delete it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,'Deleted')

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   hidden:rowData.status === "Allocated"
                          // }),
                          rowData => ({
                            icon: LockIcon,
                            tooltip: 'Disable Rfid',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Disable it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,'Disabled')

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden: rowData.status === "Lost"  || profile.user_type ==="User"|| rowData.status === "Available" || rowData.status === 'Lost' || rowData.status === 'Disabled' 

                          }),
                          rowData => ({
                            icon: DoDisturbIcon,
                            tooltip: 'Disallocate Card',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Disallocate it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,'Remove')

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden:  rowData.status === "Lost"  || profile.user_type ==="User"||rowData.status === 'Available'
                          }),
                        ]}
                        options={{
                          exportFileName:'Rfid List',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
                
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

