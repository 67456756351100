import { createSlice } from "@reduxjs/toolkit";
  

const initialState={
    scheduler:null,
}

const SchedulerSlice=createSlice({
     
     name:"scheduler",
     initialState,

     reducers:
     {

        AddScheduler(state,action){
            state.scheduler = action.payload
        },
        AddNewScheduler(state,action){
            state.scheduler = [...state.scheduler,action.payload]
        },
        EditScheduler(state,action){
            state.scheduler = state.scheduler.map((data)=>

            data.id === action.payload.id ?action.payload :data
            )

        },

        DeleteScheduler(state,action){
            state.scheduler= state.scheduler.filter((data)=>

            data.id !== action.payload
            )

        },

     }

})
export const {AddScheduler,AddNewScheduler,EditScheduler,DeleteScheduler}=SchedulerSlice.actions;
export default SchedulerSlice.reducer;