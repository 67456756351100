import React, { useEffect, useState } from 'react'
import Modals from '../../../../components/Modal'
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch } from 'react-redux';
import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
import Error from '../../../../helpers/Error';
import axios from 'axios';
import { EditSubscriptionPlans } from '../../../../store/masterdata';

function EditSubscriptionPlan(props) {

    const [waitingForAxios,setwaitingForAxios] = useState(false)
    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        reset,
        
        formState: { errors },
      } = useForm();
    
      useEffect(() => {
        
        const temp={...props.item}
        reset(temp)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.item])
      

    const onSubmit = (data) =>{

        let formData = new FormData(); //formdata object
    //   formData.append("user_type", data.user_type);
    formData.append("plan_name", data.plan_name);
    formData.append("rent_amount", data.rent_amount);
    formData.append("maintenance_amount", data.maintenance_amount);
    
    formData.append("description", data.description);
    formData.append("maintenance_hsn_code", 997221);
    formData.append("rent_hsn_code", 997212);
  
        setwaitingForAxios(true)
        
        const url=`/workspace_app/subscription_plan/${props.item.id}`

        axios.patch(url,formData)
        .then((response) =>{
            // let msg=response.data.message
            setwaitingForAxios(false)
            dispatch(EditSubscriptionPlans(response.data))
            SuccessAlert('Subscription Plan Edited Succesfully')
            props.toggle();
        })
        .catch((error) => {
            setwaitingForAxios(false)
            if(error.response.data.message){
                ErrorAlert(error.response.data.message)  
            }
           else{
            let error_msg=Error(error)
            ErrorAlert(error_msg)
           }
           
           
        })
       
    }

  return (
    <div><Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Plan Name" sm={3}>
        Plan Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Plan Name"
            type="text"
            className="form-control"
            {...register("plan_name", {
                required: true,
            })}
        />
        {errors.plan_name?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Rent " sm={3}>
       Rent  <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={4}>
        <input
            placeholder="Amount"
            type="number"
            className="form-control"
            onKeyPress={(e) => {
                if (e.key === "e" || e.key === "-" ) {
                  e.preventDefault();
                }
              }}
            {...register("rent_amount", {
                
              

              
                required: true,
              

            })}
        />
        {errors.rent_amount?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
   

       
    </Col>


    
    <Col sm={4} style={{ marginLeft: "60px"  }}>
        <input
            placeholder=" HSN Code"
            type="text"
            className="form-control"
            {...register("rent_hsn_code", {
                required: true,
                disabled:true
            })}
        />
        {errors.rent_hsn_code?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px"  }}>
    <Label for="maintenance" sm={3}>
     Maintenance  <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={4}>
        <input
            placeholder="Amount"
            type="number"
            className="form-control"
            onKeyPress={(e) => {
                if (e.key === "e" || e.key === "-" ) {
                  e.preventDefault();
                }
              }}
            {...register("maintenance_amount", {
                
              

              
                required: true,
              

            })}
        />
        {errors.maintenance_amount?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
   

       
    </Col>

    
    <Col sm={4} style={{ marginLeft: "60px"  }}>
        <input
            placeholder=" HSN Code"
            type="text"
            className="form-control"
            {...register("maintenance_hsn_code", {
                required: true,
                disabled:true
            })}
        />
        {errors.maintenance_hsn_code?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
        
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Description" sm={3}>
        Description <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Description"
            type="text"
            className="form-control"
            {...register("description", {
                required: true,
            })}
        />
        {errors.description?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
{/* <FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Tenure Days" sm={3}>
        Tenure Days
    </Label>
    <Col sm={9}>
        <input
            placeholder="Tenure Days"
            type="number"
            className="form-control"
            {...register("duration", {
                required: true,
            })}
        />
        {errors.duration?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup> */}

<ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>


</ModalFooter>
</Form>
        </Modals></div>
  )
}

export default EditSubscriptionPlan