import React, { useEffect, useState } from "react";
import { Spinner, Row, Col, Button,  Container, Label } from "reactstrap";
// Redux
import { Link } from "react-router-dom";
// import images
import logodark from "../../../assets/images/THE-WORKBOOK-LOGO.png";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import AbaciLoader from "../../../components/AbaciLoader/AbaciLoader"; 
import ExpiredLink from "../Error/ExpiredLink";
// import axios from "../../axiosInstance";
import "toastr/build/toastr.min.css";
import {Toastr} from "../../../helpers/Alert"
import Error from "../../../helpers/Error";
import { APP_NAME } from "../../../helpers/Constants";
import publicAxios from "../../../axiosInstance";
import TermsAndCondition from "./TermsAndConditions";


export default function Register() {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const string = useParams().string;
  const [DOMComponent, setDOMComponent] = useState(<AbaciLoader />);
  const [profileDetails, setprofileDetails] = useState(null);
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [allClear, setallClear] = useState(true);
  const [responseError, setResponseError] = useState(null);
  const [modalShow,setModalShow] = useState(false);
  const [checkeded,setCheckeded] =useState(false)
  useEffect(() => {
    const re = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$`);
    const isOk = re.test(newPassword);

    if (isOk) {
      setallClear(true);
      setResponseError("Passwords should match");
      if (newPassword === confirmNewPassword) {
        setResponseError(null);
        setallClear(false);
        // if (oldPassword.length > 3) {
        //   setallClear(true);
        // }
      }
    } else {
      setallClear(true);
      setResponseError(
        "The passwords should contain minimum 8 characters with a mix of alphanumeric,atleast 1 Uppercase letter and special characters"
      );
    }
  
  }, [newPassword, confirmNewPassword]);
  useEffect(() => {
    const url = "/users_api/activateuser/"+string;
    publicAxios
      .get(url)
      .then((response) => {
        setprofileDetails(response.data);
        // console.log('response registration_key to activateuser',response.data.registration_key)
      })
      .catch((error) => {
        const error_msg = Error(error)
        setDOMComponent(<ExpiredLink error={error_msg}/>);
       
        
      });
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = () => {
    // console.log('onSubmit registration_key',profileDetails.registration_key)
    setwaitingForAxios(true);
    const url = `/users_api/activateuser/${profileDetails.registration_key}`
    const data = {
      email: profileDetails.email,
      password: newPassword,
    }
    publicAxios
      .post(url, data)
      .then((response) => {
        // console.log(response);
        // Toastr("Account created successfully!.Please login with your credentials")
       

        setwaitingForAxios(false);
        Toastr("success","Account created successfully!.Please login with your credentials")
        navigate('/login')
      })
      .catch((error) => {
        setwaitingForAxios(false);
        const error_msg = Error(error)
        Toastr("error",error_msg);
        //   Toastr("error",error_msg)

      });
  };

  // useEffect(() => {
  //   return () =>{
  //     console.log("test",checkeded)
  //   }
  // }, [checkeded])
  

  const infoToggle = () =>{
    setModalShow(!modalShow)
}
  //   const onSubmit = (data) => {
  // console.log(data);
  // const url = "users_api/login";
  // axios
  //   .post(url, data)
  //   .then((response) => {
  //     dispatch(setLogin(true));
  //   })
  //   .catch((error) => {
  //     if (error.response) {
  //       // Request made and server responded

  //       console.log(error.response.data);
  //       setResponseError("*" + " " + error.response.data.detail);
  //     } else if (error.request) {
  //       // The request was made but no response was received

  //       setResponseError("*" + " " + error.message);
  //     } else {
  //       // Something happened in setting up the request that triggered an Error
  //       setResponseError("*" + " " + error.message);
  //       console.log("Error", error.message);
  //     }
  //   });
  //   };
  const DOMConentToBeRendered = (
    <React.Fragment>
      <div>
     
<TermsAndCondition
      size= "lg"
      title="Terms and Conditions"
      isOpen={modalShow}
      toggle={infoToggle}
      />
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="#" className="logo">
                              <img src={logodark} height="18" alt="logo" />
                            </Link>
                          </div>

                          <h4 className="font-size-16 mt-1 mb-3">
                            Register account
                          </h4>
                        </div>

                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          {/* register your input into the hook by invoking the "register" function */}
                          <div className="auth-form-group-custom mb-4">
                            <i className="ri-mail-line auti-custom-input-icon"></i>
                            <Label htmlFor="username">Email</Label>
                            <input
                              disabled
                              className="form-control"
                              placeholder="Email"
                              value={profileDetails?.email}
                            />
                            {errors.email?.type === "required" && (
                              <span>This field is required</span>
                            )}
                            {errors.email?.type === "pattern" && (
                              <span
                                style={{
                                  color: "#bf3d48",
                                }}
                              >
                                Please provide a valid email address
                              </span>
                            )}
                          </div>
                          <div className="auth-form-group-custom mb-4">
                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                            <Label htmlFor="username"> New Password</Label>
                            <input
                              className="form-control"
                              placeholder="Enter New password"
                              type="password"
                              onChange={(event) =>
                                setNewPassword(event.target.value)
                              }
                              value={newPassword}
                            />
                         
                            
                          </div>
                          <div className="auth-form-group-custom mb-4">
                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                            <Label htmlFor="username">Confirm Password</Label>
                            <input
                              className="form-control"
                              placeholder="Confirm password"
                              type="password"
                              onChange={(event) =>
                                setConfirmNewPassword(event.target.value)
                              }
                              value={confirmNewPassword}
                            />
                          
                          </div>
                          <p style={{ color: "red" ,fontSize:'14px'}}>{responseError}</p>
                          <div className="form-group form-check">
                        <input name="acceptTerms" type="checkbox" checked={checkeded} {...register('acceptTerms')} required={true} id="acceptTerms" className={`form-check-input ${errors.acceptTerms ? 'is-invalid' : ''}`}
                        onChange={(event) =>{
                          setCheckeded(!checkeded)
                          if(event.target.checked){
                            setModalShow(true)
                          }
                          else{
                          setModalShow(false)
                          }
                          
                        }
                        } />
                        <label htmlFor="acceptTerms" className="form-check-label">Accept Terms & Conditions</label>
                        {errors.acceptTerms?.type === "required" && (
                              <span>Please Click The CheckBox</span>
                            )}
                    </div>
                    
                          <div className="mt-4 text-center">
                            <Button
                              color="primary"
                              className="w-md waves-effect waves-light"
                              type="submit"
                              disabled={allClear}
                            >
                              {waitingForAxios ? <Spinner animation="grow" size="sm" /> : "Sign Up"}
                            </Button>
                            
                          </div>
                        </form>

                        <div className="mt-4 text-center">
                          <p>
                            Already have an account ?{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Login
                            </Link>{" "}
                          </p>
                          <p>
                            © 2022 {APP_NAME}. {" "}
                          
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );

  // return <>{DOMConentToBeRendered}</>;
  return <>{profileDetails ? DOMConentToBeRendered : DOMComponent}</>;
}
