import React, { useCallback, useEffect, useState } from 'react'
import Modals from '../../../../components/Modal'
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup,  Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch } from 'react-redux';
// import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
// import Error from '../../../../helpers/Error';
import axios from 'axios';
import Error from '../../../../helpers/Error';
import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
import {  EditWorkStation } from '../../../../store/masterdata';


function EditWorkStations(props) {

    const [waitingForAxios,setwaitingForAxios] = useState(false)
    const [cabin,setCabin] = useState([])
    const [loader,setLoader] = useState(true)
    // const [value,setValue] = useState('')
    const dispatch=useDispatch();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();

      const dataValidator = useCallback(() =>{

        if (props.item !== null) {
            // console.log(props.item)
          
          const tempdata={...props.item,
            cabin:props.item?.cabin?.id && props.item.cabin.id 
          }
          
          reset(tempdata)
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])  

      useEffect(() => {
      const url='/workspace_app/cabin'
      axios.get(url)
      .then((response) => {
      const temp=[]
        
        response.data.map((data)=>{
            if(data.status === 'Active'){
                temp.push({...data})
            }
            return temp

        })
        setCabin(temp)
        setLoader(false)
        dataValidator();
      })
      .catch((error) => {
        setLoader(false)
        dataValidator();
      })

      }, [dataValidator])
      
    const onSubmit = (data) =>{
        
        let formData = new FormData(); //formdata object
            formData.append("description", data.description);
            formData.append("workstation_location", data.workstation_location);
            formData.append("workstation_name", data.workstation_name);
            formData.append("workstation_no", data.workstation_no);
            // formData.append("is_virtual",value === 'Yes'? true:false);

            if(data.cabin !== ''){
                formData.append("cabin", data.cabin);
            }
    
            setwaitingForAxios(true)
        
        const url=`/workspace_app/workstation/${props.item.id}`

        axios.patch(url,formData)
        .then((response) =>{
            
            setwaitingForAxios(false)
            dispatch(EditWorkStation(response.data))
            SuccessAlert('WorkStation Added Succesfully')
            props.toggle();
        })
        .catch((error) => {
            
            setwaitingForAxios(false)
            if(error.response.data.message){
                ErrorAlert(error.response.data.message)  
            }
            else if(error.response?.data?.workstation_no[0]){
                ErrorAlert(error.response?.data?.workstation_no[0])

            }
           else{
            let error_msg=Error(error)
            ErrorAlert(error_msg)
           }
        })
       
    }

  return (
    <div><Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} loader={loader}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>

    
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Cabin Name" sm={3}>
        Cabin Name
    </Label>
    <Col sm={9}>
    <select className="form-control"
                  {...register("cabin", {
                    
                    
                  })}>
 <option value="">      --Select Cabin--</option>
                  {cabin.map((item,index) => {
                    return <option key={index} value={item.id}>{item.cabin_name}</option>
                  })}
    </select>
    
        {errors.cabin?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
        <FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Work Station No" sm={3}>
        Work Station No <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Work Station No"
            type="text"
            className="form-control"
            {...register("workstation_no", {
                required: true,
            })}
        />
        {errors.workstation_no?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Work Station Name" sm={3}>
        Work Station Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Work Station Name"
            type="text"
            className="form-control"
            {...register("workstation_name", {
                required: true,
            })}
        />
        {errors.workstation_name?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Location" sm={3}>
        Location <span style={{color:'red'}}>*</span>
    </Label> 
    <Col sm={9}>
        <input
            placeholder="Location"
            type="text"
            className="form-control"
            {...register("workstation_location", {
                required: true,
            })}
        />
        {errors.workstation_location?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Description" sm={3}>
        Description <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Description"
            type="text"
            className="form-control"
            {...register("description", {
                required: true,
            })}
        />
        {errors.description?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
{/* <FormGroup row style={{ marginBottom: "10px"}}>
    <Label for="Virtusl Account" sm={3}>
        Is Hotseat ? <span style={{color:'red'}}>*</span>
    </Label>
   <Col sm={2} style={{ marginTop: "10px"}} >
   <Label check><Input 
        name="radio1"
        type="radio"
        value="Yes"
        required={true}
        onChange={(event) => setValue(event.target.value)}
      />
      {' '}
    
        Yes
      </Label></Col>
    
    <Col sm={2} style={{ marginTop: "10px"}} >
    <Label check>
    <Input
        name="radio1"
        type="radio"
        value='No'
        required={true}

        onChange={(event) => setValue(event.target.value)}

      />
      {' '}
      
        No
      </Label></Col>
   
      
    
 
</FormGroup> */}


<ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>


</ModalFooter>
</Form>
        </Modals></div>
  )
}

export default EditWorkStations