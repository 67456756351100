import moment from "moment"

export const APP_NAME = "WorkBook"

export const Color=["#1cbb8c","#008FFB",

"#FF0000",
"#A58CFF",
"#5664d2",
"#260e12",
"#eeb902",
"#ABCB35",]

export const InvoiceColor=["#eeb902","#FF0000","#1cbb8c","#e28743"]
 export const WorkstationColor=["#008FFB","#A58CFF","#1cbb8c"]

 
export const ranges= {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
    'Last 365 Days':[moment().subtract(364, 'days'), moment()],
}
