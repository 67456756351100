import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, CardBody, Col, Container } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { APP_NAME, ranges } from '../../../helpers/Constants';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../helpers/MaterialIcons';
import { useSelector } from 'react-redux';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import axios from 'axios';

import { ErrorAlert, Toastr } from '../../../helpers/Alert';

import AddPayments from './AddPayment';
import fileDownload from 'js-file-download'
import DownloadIcon from '@mui/icons-material/Download';
import { pdfGenerator } from '../../../helpers/PDFReportGenerator';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { IconButton, Tooltip} from '@material-ui/core';


export default function Payment () {
    const [data,setData] = useState([])
    const tableRef=useRef();
    // const payment=useSelector((state) => state.ReportSlice.payment)
    const profile = useSelector((state) => state.authSlice.profile)
    const [addModalShow,setAddModalShow] = useState(false)
    const [totalRecordsCount, setTotalRecordsCount] = useState(0)
    const [downloadIndicator,setDownloadIndicator] = useState(false)
    const [urlBackup, setUrlBackup] = useState('')
    // const [editModalShow,setEditModalShow] = useState(false)
    const [paymentData,setPaymentData] = useState(false)
    // const [loader,setLoader] = useState(true)
    // const dispatch = useDispatch()
    // const [filterEnabled, setFilterEnabled] = useState(false);
    const [date,setDate]=useState({})
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "Payments", link: "#" },
        ],
      });

      const ManualPayments =[
        { title: "Invoice ID", field: "invoice__invoice_id"},
        { title: "Payment ID", field: "payment_id",},
        { title: 'Company Name' , field: 'company__company_name'},
        { title: 'Company Address' , field: 'company__address'},
        { title: "Amount", field: "amount",},
        { title: "Payment Date", field: "payment_date"},
        { title: "Remarks", field: "remarks" },
        // { title: "Status", field: "status"},
      ]

      const AllPayments =[
        { title: "Invoice ID", field: "invoice__invoice_id"},
        { title: "Payment ID", field: "payment_id",},
        { title: 'Company Name' , field: 'company__company_name'},
        { title: 'Company Address' , field: 'company__address'},
        { title: "Amount", field: "amount",},
        { title: "Payment Date", field: "payment_date"},
        { title: "Payment Type", field: "payment_method"},
        { title: "Remarks", field: "remarks" },
        { title: "Status", field: "status"},
      ]
  
      const addModalToggle = () => {
        setAddModalShow(!addModalShow)
      }
   
      const downloadHandler = (link) =>{

        const url = link
        const name = link.split('/')[2]
       
        axios.get(url, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, name);
        });
      }
    
  
    useEffect(() => {
      
      const abortController=new AbortController();

      // dataHandler();
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Payments", link: "#" },
              ],
            }  
          )
          return()=>{
            abortController.abort();
            setData([]); 
          }
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    
  const handleDate = (start,end) => {

    if(start=== "cancel"){
      setDate({})
      tableRef.current.onQueryChange({page: 0});
    }
    else{
    const firstdate=moment(start).format('YYYY-MM-DD')
    const enddate=moment(end).format('YYYY-MM-DD')

    setDate({start_date: firstdate, end_date: enddate})

    tableRef.current.onQueryChange({page: 0});
    }
  }


    // const dataHandler = () => {
    //   setLoader(true)
    //   const url='/workspace_app/payment_by_admin'
    //         axios.get(url)
    //         .then((response) => {
              
    //           dispatch(AddPayment(response.data))
    //           setLoader(false)
           
    //         })
    //         .catch((error) =>{
    //           const err_msg=Error(error)
    //           ErrorAlert(err_msg)
    //           setLoader(false)
      
    //         })
    // }

    // useEffect(() => {
    // if(payment !== null){
    //     const temp=payment.map((data)=>({...data,
        
    //        invoice_id:data?.invoice?.invoice_id ? data.invoice.invoice_id :'No Data',
    //        created_date:data?.created_date ? Moments(data.created_date,'datetime')  : 'No Data',
    //        remarks:data?.remarks ? data.remarks :"No Data",
    //        downloadPath:data?.invoice?.invoice_file ? data.invoice.invoice_file : '',
    //        company_name:data?.company?.company_name ? data.company.company_name : '',
    //        company_address:data?.company?.address ?  data.company.address  : '', 
          
          
    //       }))
    //       setData(temp)
       
    // }
    // }, [payment])
    

    const exportData = (downloadType) => {
      const url = `${urlBackup}&${downloadType}`
      setDownloadIndicator(true)
      if (downloadType === "pdf_download") {
        if (totalRecordsCount >= 2000) {
          ErrorAlert("The maximum records to export as PDF is 2000. Please apply filter to reduce the number of records")
          setDownloadIndicator(false)
          return
        }
     
        axios({
          url: url,
          method: 'GET',
          // responseType: 'blob', // Important
        }).then((response) => {
          setDownloadIndicator(false)
          // console.log(response.data?.body)
          const headRowData = [response.data?.header]
          const bodyRowData = response.data?.body
          const reportName = "Payment_List"
          var doc = pdfGenerator(headRowData, bodyRowData, reportName)
          doc.save("Payment_List.pdf");
          console.log('')
          // tableRef.current.onQueryChange();
         
        }).catch(error => {
          Toastr("error", "Error downloading the file !")
          setDownloadIndicator(false)
        });
      }
      else {
        if (totalRecordsCount >= 5000) {
          ErrorAlert("The maximum records to export as CSV is 5000. Please apply filter to reduce the number of records")
          setDownloadIndicator(false)
          return
        }
        axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // Important
        }).then((response) => {
          let fileName = "Payment_List.csv"
          fileDownload(response.data, fileName);
          setDownloadIndicator(false)
          // tableRef.current.onQueryChange();
        }).catch(error => {
          Toastr("error", "Error downloading the file !")
          setDownloadIndicator(false)
        });
      }
    }

   
  return (
    <React.Fragment>

    {addModalShow &&
      <AddPayments
      isOpen={addModalShow}
      toggle={addModalToggle}
      title={"Add Payment"}
      
      tableRef={tableRef}
      />
      }
      
      <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Payments"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
              {profile.user_type === "Admin" ?<>
              
              <div
                className="disappearing-button-container-page-top-right" style={{marginRight:"-80px"}}>
                 <Button
                 style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                 type="button"
                 color="warning"
                  
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={addModalToggle}
                >
                  Add Payment
                </Button>
               
                  </div>
                  </> : null}

          
              {paymentData ? 
              
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                 
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >
          <div style={{display:'flex',marginBottom:'-55px',width:'60%'}}>
            
            <Col xl={1}>
              <DateRangePicker  onCallback={(startdate,enddate) => handleDate(startdate._d,enddate._d)}
                            onCancel={(data)=> handleDate(data.type)}

                  initialSettings={{ranges:ranges,
                                      opens:'right',
                                      startDate:moment().subtract(1, 'days'),
                                      // showCustomRangeLabel:true,
                                      // showDropdowns:true,
                                      endDate: moment(),
                                      maxDate:moment()
                                    }}>
  <IconButton aria-label="delete"  color={Object.keys(date).length !== 0 ? 'primary' : 'default'} style={{zIndex:'1'}}>
    <Tooltip title='Show Calendar'>
  <CalendarMonthIcon />
  </Tooltip>
</IconButton>
  </DateRangePicker>
  
            </Col> 
            <div  style={{zIndex:'1',marginBottom:'-40px' }}>
             <p style={{marginTop:'17px',paddingRight:'20px'}}>{Object.keys(date).length !== 0 ?  `Showing Payments from ${date.start_date} to ${date.end_date}` : 'Showing All Payments'}</p>
            </div>
           
          </div>
   
                      <MaterialTable
                        title=" "
                        columns={AllPayments}
                        tableRef={tableRef}
                        data={ (query) => {
                          // console.log(query)
                          return (new Promise((resolve, reject) => {
                           
                            let orderBy = ""
                            if (query.orderBy) {
                              orderBy = query.orderDirection === "asc" ? `&ordering=-${query.orderBy?.field}` : `&ordering=${query.orderBy?.field}`
                            }
                         

                            let url = `/workspace_app/all_payment_list?limit=${query.pageSize}&offset=${query.pageSize * query.page}&search=${query.search}${orderBy}`

                            if (Object.keys(date).length !== 0){
                              url +=`&start_date=${date.start_date}&end_date=${date.end_date}`
                            }
                            setUrlBackup(url)
                            axios.get(url)
                              .then((response) => {
                                setTotalRecordsCount(response.data?.count)
                                // tableRef.current.dataManager.changePageSize(query.pageSize)
                                // console.log(response.data.results)
                                const InvoiceList = response.data?.results
                                // dispatch(addGtcc(response.data.results))
                                const TempData = InvoiceList.map((data) => ({
                                  ...data,
                                  invoice__invoice_id:data?.invoice?.invoice_id ? data.invoice.invoice_id :'No Data',
                                  // created_date:data?.created_date ? Moments(data.created_date,'datetime')  : 'No Data',
                                  remarks:data?.remarks ? data.remarks :"No Data",
                                  downloadPath:data?.invoice?.invoice_file ? data.invoice.invoice_file : '',
                                  company__company_name:data?.company?.company_name ? data.company.company_name : '',
                                  company__address:data?.company?.address ?  data.company.address  : '', 
                                 
                                  
                                }))
                                resolve({
                                  data: TempData,
                                  page: response.data?.results.length === 0 ? 0 :query.page,
                                  totalCount: response.data?.count,
                                })
                              })
                              .catch((error) => {
                                // console.log('rejected')
                                reject({
                                  data: [],
                                  page: query.page,
                                  totalCount: 0
                                })
                              })
                          }))
                        }}
                        isLoading={downloadIndicator}

                        actions={[
                          // {
                          //   icon: FilterList,
                          //   tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                          //   isFreeAction: true,
                          //   onClick: (event) => { setFilterEnabled(state => !state) }
                          // },
                          {
                            icon: VisibilityOff ,
                            tooltip:'View Manual Payments',
                            isFreeAction: true,
                            onClick: (event) => {setPaymentData(state => !state); setDate({})}
                          },
                            
                          {
                            icon: DownloadIcon,
                            tooltip: 'Download Invoice',
                           
                            onClick: (event,rowData) => {downloadHandler(rowData.downloadPath)}
                          },
                         
                        
                        
                        ]}
                        options={{
                          exportFileName:paymentData === false ?'Manual Payment Details ' : 'Payment Details',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          debounceInterval: 500,
                          exportCsv: async () => exportData("csv_download"),
                          exportPdf: async () => exportData("pdf_download"),
                          // filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
              :
              <>
             
              <Col xl={24}>
                
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                
                  <div style={{display:'flex',marginBottom:'-55px' ,width:'60%'}}>
               
            <Col xl={1}>
              <DateRangePicker  onCallback={(startdate,enddate) => handleDate(startdate._d,enddate._d)}
              onCancel={(data)=> handleDate(data.type)}
             
                  initialSettings={{ranges:ranges,
                                      opens:'right',
                                      startDate:moment().subtract(1, 'days'),
                                      // showCustomRangeLabel:true,
                                      // showDropdowns:true,
                                      endDate: moment(),
                                      maxDate:moment()
                                    }}>
  <IconButton aria-label="delete"  color={Object.keys(date).length !== 0 ? 'primary' : 'default'} style={{zIndex:'1'}}>
    <Tooltip title='Show Calendar'>
  <CalendarMonthIcon />
  </Tooltip>
</IconButton>
  </DateRangePicker>

            </Col>
            <div  style={{zIndex:'1',marginBottom:'-40px' }}>
             <p style={{marginTop:'17px',paddingRight:'20px'}}>{Object.keys(date).length !== 0 ?  `Showing Manual Payments from ${date.start_date} to ${date.end_date}` : 'Showing Manual Payments'}</p>
            </div>
          </div>
                    <div
                      style={{
                        
                        overflowY: 'auto',
                       
                      }}
                    >
                          
   
                      <MaterialTable
                        title=" "
                        columns={ManualPayments}
                        tableRef={tableRef}
                        data={ (query) => {
                          // console.log(query)
                          return (new Promise((resolve, reject) => {
                           
                            let orderBy = ""
                           
                            if (query.orderBy) {
                              orderBy = query.orderDirection === "asc" ? `&ordering=-${query.orderBy?.field}` : `&ordering=${query.orderBy?.field}`
                            }
                            
                            let url = `/workspace_app/payment_by_admin?limit=${query.pageSize}&offset=${query.pageSize * query.page}&search=${query.search}${orderBy}`

                            if (Object.keys(date).length !== 0){
                              url +=`&start_date=${date.start_date}&end_date=${date.end_date}`
                            }
                            setUrlBackup(url)
                            axios.get(url)
                              .then((response) => {
                                setTotalRecordsCount(response.data?.count)
                                // tableRef.current.dataManager.changePageSize(query.pageSize)
                                // console.log(response.data.results)
                                const InvoiceList = response.data?.results
                                // dispatch(addGtcc(response.data.results))
                                const TempData = InvoiceList.map((data) => ({
                                  ...data,
                                  invoice__invoice_id:data?.invoice?.invoice_id ? data.invoice.invoice_id :'No Data',
                                  // created_date:data?.created_date ? Moments(data.created_date,'datetime')  : 'No Data',
                                  remarks:data?.remarks ? data.remarks :"No Data",
                                  downloadPath:data?.invoice?.invoice_file ? data.invoice.invoice_file : '',
                                  company__company_name:data?.company?.company_name ? data.company.company_name : '',
                                  company__address:data?.company?.address ?  data.company.address  : '', 
                                 
                                  
                                }))
                                resolve({
                                  data: TempData,
                                  page: response.data?.results.length === 0 ? 0 :query.page,
                                  totalCount: response.data?.count,
                                })
                              })
                              .catch((error) => {
                                // console.log('rejected')
                                reject({
                                  data: [],
                                  page: query.page,
                                  totalCount: 0
                                })
                              })
                          }))
                        }}
                        isLoading={downloadIndicator}

                        actions={[
                          // {
                          //   icon: FilterList,
                          //   tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                          //   isFreeAction: true,
                          //   onClick: (event) => { setFilterEnabled(state => !state) }
                          // },
                          {
                            icon: VisibilityOutlinedIcon ,
                            tooltip:'View All Payments',
                            isFreeAction: true,
                            onClick: (event) => {setPaymentData(state => !state) ; setDate({})}
                          },
                            
                          {
                            icon: DownloadIcon,
                            tooltip: 'Download Invoice',
                           
                            onClick: (event,rowData) => {downloadHandler(rowData.downloadPath)}
                          },
                         
                        
                        
                        ]}
                        options={{
                          exportFileName:'Manual Payment Details',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          // filtering: filterEnabled
                          debounceInterval: 500,
                          exportCsv: async () => exportData("csv_download"),
                          exportPdf: async () => exportData("pdf_download"),
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
              </>
}
  </Container>
    </div>
    </React.Fragment>
  )
}

