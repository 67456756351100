import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container } from 'reactstrap'
// import Swal from 'sweetalert2';
import { APP_NAME } from '../../../../helpers/Constants';
import {  Edit,FilterList } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../helpers/MaterialIcons';
import { useDispatch, useSelector } from 'react-redux';


import axios from 'axios';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';

import Moments from '../../../../helpers/Moment';

import { AddScheduler } from '../../../../store/scheduler';
import AddSchedule from './AddScheduler';
import EditSchedule from './EditScheduler';
// import {  useNavigate } from 'react-router-dom';



export default function Scheduler () {
   
    const [data,setData] = useState([])
    // const navigate=useNavigate();
    const [addModalShow,setAddModalShow] = useState(false)
    const [editModalShow,setEditModalShow] = useState(false)
    const [itemToBeEdited,setItemToBeEdited] = useState(null)
  
    const [loader,setLoader] = useState(true)
    const scheduler=useSelector((state) => state.SchedulerSlice.scheduler)
    const dispatch = useDispatch()
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "Scheduler", link: "#" },
        ],
      });

      const addModalToggle = () => {
        setAddModalShow(!addModalShow)
      }
      const editModalToggle = () => {
        setEditModalShow(!editModalShow)
      }
  
    useEffect(() => {
      
      const abortController=new AbortController();
      const url='/workspace_app/scheduler'
      axios.get(url)
      .then((response) => {
        dispatch(AddScheduler(response.data))
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
      })

      // dispatch(AddSubscriber([{id:1,operator_id:8765,company_name:'Noux',emp_id:1001,extn_no:593,first_name:"David",last_name:"Jacobs",designation:"Officer",email:"david@email.com",phone_no:"0501231000",User_type:"Admin",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"},{id:2,operator_id:2678,emp_id:1002,company_name:'Element 8',extn_no:574,first_name:"Akhil",last_name:"Prem",designation:"Accountant",email:"Akhil@gmail.com",phone_no:"0501231001",User_type:"Admin",address:"789 Fort Myres,Monreo Avenue,Florida",status:"Active"},{id:3,operator_id:3567,emp_id:1003,company_name:'Rad',extn_no:567,first_name:"Sharon",last_name:"VArghese",designation:"Chief Officer",email:"Sharon@email.com",phone_no:"05012310021",User_type:"User",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"}]))
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Scheduler", link: "#" },
              ],
            }  
          )
          return()=>{
            abortController.abort();
          }
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
    if(scheduler !== null){
      const temp=scheduler.map((data,index) => ({...data,
        Sl_no:index+1,
        // scheduler_name:data?.scheduler?.scheduler_name ? data.scheduler.scheduler_name : "No Data",
      }))
      setData(temp)
    }
    }, [scheduler])
    

    const columns =
  [
    { title:'Sl_No', field:'Sl_no' ,},
    { title:'Scheduler', field:'scheduler_name',
  // render:rowData=><> <Tooltip arrow title={'View Detail Of' +' '+(rowData.scheduler_name)}><Link to={`/schedulerdetails/${rowData.id}`}>{rowData.scheduler_name}</Link></Tooltip>
  // </>
  },
    {title:'Created Date' , field:'created_date',
    render:rowData =><> {rowData.created_date ? Moments(rowData.created_date) : 'No Data'}</>,
  },
]
      // for handling delete activate deactivate
//  const UpdateHandler = (id,method) =>{

//   if(method==="Deleted"){
//   // console.log(id)
//   const url=`/workspace_app/scheduler/${id}`
//   axios.patch(url,{status:method})
//         .then((response) =>{
//           SuccessAlert("Scheduler Deleted Successfully!")
//           dispatch(DeleteScheduler(id))
//         })
//         .catch((error) =>{
//           const err_msg=Error(error)
//           ErrorAlert(err_msg)
         
//         })
//       }
//       // else{
//       //   if(method === 'Disabled' || method === 'Active'){
//       //   const url=`/workspace_app/door/${id}`
//       //   axios.patch(url,{status:method})
//       //   .then((response) =>{
//       //     SuccessAlert("Scheduler Updated Successfully!")
//       //     // dispatch(EditDoor(response.data))
//       //   })
//       //   .catch((error) =>{
//       //     const err_msg=Error(error)
//       //     ErrorAlert(err_msg)
         
//       //   })
//       // }
     
//       // }
//  }

    
    
  return (
    <React.Fragment>

        {addModalShow &&
        <AddSchedule
        isOpen={addModalShow}
        toggle={addModalToggle}
        title={'Add Scheduler'}
        />
        }

        {editModalShow &&
        <EditSchedule
        isOpen={editModalShow}
        toggle={editModalToggle}
        title={'Edit Scheduler'}
        item={itemToBeEdited}
        />
        }

          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Scheduler"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
               <div
                className="disappearing-button-container-page-top-right" style={{marginRight:"-80px"}}>
                 <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={addModalToggle}
                >
                  Add Schedule
                </Button>
                
                  </div>
               
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={columns}
                        data={data}
                        isLoading={loader}

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },

                          
                          // {
                          //   icon: VisibilityIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/schedulerdetails/${rowData.id}`)
                          // },
                         
                          {
                            icon: Edit,
                            tooltip: 'Edit Scheduler',
                            onClick: (event, rowData) => {
                              // console.log(rowData)
                              // setEditClientModalShow(true)
                              setItemToBeEdited(rowData)
                             editModalToggle()
                            }
                          },
                         
                          // rowData => ({
                          //   icon: Unlock,
                          //   tooltip: 'Enable Door',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                                
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, Enable it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,"Active")
                          //         // deleteClientData(rowData?.id)

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   hidden: rowData.status === "Active"
                          // }),
                          // rowData => ({
                          //   icon: LockIcon,
                          //   tooltip: 'Disable Door',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, Disable it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,"Disabled")
                          //         // deleteClientData(rowData?.id)

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   hidden: rowData.status === "Disabled"
                          // }),

                          // {
                          //   icon: DeleteOutline,
                          //   tooltip: 'Delete Schedule',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       text: "You won't be able to revert this!",
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, delete it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,'Deleted')

                                
                          //       }
                          //     })
                             
                          //   },
                           
                          // },

                        ]}
                        options={{
                          exportFileName:'user_list',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
                
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

