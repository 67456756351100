import React, { useEffect, useState } from 'react'
import Modals from '../../../../components/Modal'
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch} from 'react-redux';
// import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
// import Error from '../../../../helpers/Error';
import axios from 'axios';
import Error from '../../../../helpers/Error';
import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
// import { AddNewSubscriberStaff } from '../../../../store/subscriber';
import { AddNewAccessGroup } from '../../../../store/accessGroups';


function AddAccessGroups(props) {

    // const profile = useSelector((state) => state.authSlice.profile)

    const [waitingForAxios,setwaitingForAxios] = useState(false)
    const [scheduler,setScheduler] = useState([])
    const [loader,setLoader] = useState(true)
    const dispatch=useDispatch();
    const {
        register,
        handleSubmit,
       
        formState: { errors },
      } = useForm();

      useEffect(() => {
        const url="/workspace_app/scheduler"
        
        axios.get(url)
          .then((response) => {
            setScheduler(response.data)
            setLoader(false)
          })
          .catch((error) =>{
            setLoader(false)
          })
      }, [])
      
    const onSubmit = (data) =>{
        // console.log(data)
        setwaitingForAxios(true)
        
        const url='/workspace_app/access_group'

        axios.post(url,data)
        .then((response) =>{
            // let msg=response.data.message
            setwaitingForAxios(false)
            dispatch(AddNewAccessGroup(response.data))
            SuccessAlert('New Access Group Added Succesfully')
            props.toggle();
        })
        .catch((error) => {
            setwaitingForAxios(false)
            let error_msg=Error(error)
            ErrorAlert(error_msg)
        })
       
    }

  return (
    <div><Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} loader={loader}>
        <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>


               
<FormGroup row style={{ marginBottom: "10"}}>
    <Label for="Scheduler" sm={3}>
        Scheduler
    </Label>
    <Col sm={9}>
    <select className="form-control"
                  {...register("scheduler", {
                    // required: true,
                    
                  })}>
 <option value="">      --Select Scheduler--</option>
                  {scheduler.map((item,index) => {
                    return <option key={index} value={item.id}>{item.scheduler_name}</option>
                  })}
    </select>
    
        {errors.scheduler?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Group Name" sm={3}>
        Group Name
    </Label>
    <Col sm={9}>
        <input
            placeholder="Group Name"
            type="text"
            className="form-control"
            {...register("group_name", {
                required: true,
            })}
        />
        {errors.group_name?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>


</ModalFooter>
</Form>
        </Modals></div>
  )
}

export default AddAccessGroups