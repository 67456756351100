import React from "react";
import ErrorBoundary from "./ErrorBoundary";
import { Route, Routes as Switch } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import VerticalLayout from "./components/VerticalLayout/";
// import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import Test from "./helpers/Test"
import PrivateRoute from "./PrivateRoute";
export default function Routes() {
  return (
    <ErrorBoundary>
      <Switch>
        <Route path ="/test" element = {<Test/>}/>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            layout={NonAuthLayout}
            element={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            layout={VerticalLayout}
            element={
              <PrivateRoute allowedTo={route.allowedTo}>
            <Layout component = {route.component}/>
            </PrivateRoute>
            }
            key={idx}
            isAuthProtected={false}
          />
        ))}
      </Switch>
    </ErrorBoundary>
  );
}

const Layout = (props) => (
  <VerticalLayout>
  {props.component}
  </VerticalLayout>
  )