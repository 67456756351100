import React from 'react'

import { Card,CardBody, Container,Col } from 'reactstrap'

export const ServicePricing = () => {
  return (
    <React.Fragment>
    <div className="page-content" style={{marginTop:"-50px"}}>
      <Container fluid>
       
             <Col xl={24}>
          <Card style={{ minHeight: "575px" }}>
            <CardBody>
            <div className="text-center" style={{marginTop:"20px"}}>
                                            <h1>Service Pricing</h1></div>
                                            <div style={{fontSize:"18px",marginTop:"40px",textAlign:"justify"}}>
                                             
                                             <ul>
                                              <li>Cabin Plan 01-6,000+GST(18%) per month </li>
                                              <li>Hotseat Plan 01-5,500+GST(18%) per month </li>
                                              <li>DedicatedWorkspace-7,500+GST(18%) per month</li>
                                             </ul>


                                            </div>
                                        


              {/* </Row> */}
            </CardBody>
          </Card>
        </Col>
          
        </Container>
          </div>
          </React.Fragment>
    
  )
}
