import React from 'react'
import './GraphLoader.css';
export default function GraphLoader() {
  return (
    <div className="container">
      <div className="loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}
