import React from 'react';
// { Component }
import { Col, Card, CardBody } from "reactstrap";
import CountUp from 'react-countup'


export default function MiniWidgets(props) {
    return (
        <React.Fragment>
            {
                props.reports.map((report, key) =>
                    <Col key={key} >
                        <Card >
                            <CardBody>
                                <div className="d-flex">
                                    <div className="flex-1 overflow-hidden">
                                        <p className="text-truncate font-size-13 mb-2" style={{minHeight:'30px'}}>{report.title}</p>
                                        {report.isSubscriber ? 
                                       <h5 className="mb-0"> <CountUp end={report.value} duration={3} delay={1} /> </h5>  : <h5 className="mb-0"> <CountUp end={report.value} duration={3} delay={1} /> / <CountUp end={report.total} duration={3} delay={1} /></h5>  }
                                    </div>
                                    <div className="text-primary">
                                        <i className={report.icon + " font-size-24"}></i>
                                        
                                    </div>
                                </div>
                            </CardBody>

                            <CardBody className="border-top py-3">
                                <div className="text-truncate"style={{color:"#e4e4e4"}}>
                                   
                                   
                                    {/* <span className="badge badge-soft-success font-size-11 me-1"><i className="mdi mdi-menu-up"> </i> {report.rate}</span> */}
                                    <span className="text-muted font-size-10 " >{report.desc}</span>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )
            }
        </React.Fragment>
    );
}

