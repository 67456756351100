import React, { useEffect, useState } from 'react'

import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Spinner, Card, CardBody} from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch } from 'react-redux';
import Modals from '../../../../../components/Modal';
// import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
// import Error from '../../../../helpers/Error';
// import axios from 'axios';
// import { AddNewClientUser } from '../../../../store/user';
import { MultiSelect } from "react-multi-select-component";
import axios from 'axios';
import { ErrorAlert, SuccessAlert, Toastr } from '../../../../../helpers/Alert';
// import { AddNewWorkStation } from '../../../../../store/masterdata';
import { AddNewSubscriberWorkStation } from '../../../../../store/subscriber';

function AssignWorkstation(props) {
  const [options,setOptions] = useState([])
  const [loader,setLoader] = useState(true)
  const [workstation,setWorkstation] = useState([])
  const [waitingForAxios,setWaitingForAxios] = useState(false)
  const [ids,setIDs] = useState([])
  const [company,setCompany] = useState([])
  const [companyId,setCompanyId] = useState('')
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
   
    formState: { errors },
  } = useForm();

  useEffect(() => {

    const url='/workspace_app/limited_subscriber_list'
    axios.get(url)
    .then((response) => {
      const temp=[]
      response.data.map((data) => {
        if(data.status === "Activated" ){
            temp.push({...data,})
        }
        return(temp)
    })


      
      setCompany(temp)
      
      setLoader(false)
    })
    .catch((error) => {
      setLoader(false)
    })

    const url2='/workspace_app/workstation'
    axios.get(url2)
    .then((response) => {

      setWorkstation(response.data)
      // const temp=rfidCard.filter((data) => data.company === null)
    //   const temp=[]
    //   response.data.map((data) => {
    //     if(data.cabin === null && data.company === null  ){
    //         temp.push({...data,label:`${data.workstation_name} (${data.workstation_no})`,value:data.id,})
    //     }
    //     return(temp)
    // })
      // setOptions(temp)
    })
  }, [])


  const onSubmit = () =>{
      if(ids.length !==0){
       

      setWaitingForAxios(true)

      const url='/workspace_app/assign_workstation_company'

      axios.post(url,{company:companyId,workstations:ids})
      .then((response) =>{

          dispatch(AddNewSubscriberWorkStation(response.data))
          setWaitingForAxios(false)
          SuccessAlert("WorkStation Assigned Successfully")
          props.toggle()

      })
      .catch((error) =>{
          setWaitingForAxios(false)
          // const error_msg=Error(error.response.data[0])
          ErrorAlert(error.response.data[0])
        
      })
  
      }


   
    else{
      Toastr("warning",'Please Select An WorkStation For Assigning')
    }

  }
  
  
  const [selected, setSelected] = useState([]);
  // const [data,setData] = useState([])
  // const [name,setName] = useState('')

  // useEffect(() => {
  //   console.log(companyId)
  // }, [companyId])
  


  const valueRenderer = (selected) => {
    if (!selected.length) {
      return "Select Workstation";
    }

    return selected.length === 1
      ? `${selected[0].label} `
      : selected.map(({ label }) =>  label + ',');
  };

  const selectChange = (data) =>{
    
    const temp=data.map((data) => data.value)
    setIDs(temp)
    setSelected(data)
  }  
const companyChangeHandler=(data)=>{
  const id=data.target.value
        if(id){
          setCompanyId(id)
           
        const index = data.target.selectedIndex;
        const option = data.target.childNodes[index]
        const is_virtual_acc =  option.getAttribute('val'); 
        // console.log(name)
        const temp=[]
        if(is_virtual_acc === 'false'){
        workstation.map((data) => {
          if(data.cabin === null && data.company === null && data.is_hotseat === false){
              temp.push({...data,label:`${data.workstation_name} (${data.workstation_no})`,value:data.id,})
          }
          return(temp)
          
      })
    }
    else{
      workstation.map((data) => {
        if(data.cabin === null && data.company === null && data.is_hotseat === true){
            temp.push({...data,label:`${data.workstation_name} (${data.workstation_no})`,value:data.id,})
        }
        return(temp)
        
    })
    }
        setOptions(temp)
    

          
        
        }
        else{
          setOptions([])
        }

  

}

  
return (
  <Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} size={props.size} loader={loader}>
     <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>
     <div style={{minHeight:"400px"}}>
          
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Company Name" sm={3}>
        Company Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
    <select className="form-control"
                  {...register("company", {
                    required: true,
                    onChange:(event) =>  {companyChangeHandler(event);}

                  })}>
 <option value="">      --Select Company--</option>
                  {company.map((item,index) => {
                    return <option key={index} val={item.is_virtual_acc.toString()} value={item.id}>{`${item.company_name} ${item.is_virtual_acc === true ? '(Virtual Account)' : ''}`}</option>
                  })}
    </select>
    
        {errors.company?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>

    
    <MultiSelect
      options={options}
      value={selected}
      onChange={selectChange}
      labelledBy="Select Workstation"
      hasSelectAll={false}
      valueRenderer={valueRenderer}
    />
  
  {selected.length !== 0 ?
  <div style={{padding:"20px"}}>
  <p>Selected Workstations :</p>
 
   {selected.map((data) =>
    <div className='card__tiles' key={data.id}>
  <Card key={data.id} >
  <CardBody>
    <div style={{display:'flex',justifyContent:'space-between'}}>
  <table cellSpacing="0" cellPadding="6">
                  <tbody>
                  <tr>
                      <td>Workstation No</td>
                      <td> : </td>
                      <td>{data.workstation_no}</td>
                    </tr>
                    <tr>
                      <td>Workstation Name</td>
                      <td> : </td>
                      <td>{data.label}</td>
                    </tr>
                    </tbody>
                    </table>
                    <table cellSpacing="0" cellPadding="6">
                      <tbody>
                    <tr>
                      <td>Workstation Location</td>
                      <td> : </td>
                      <td>{data.workstation_location}</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td> : </td>
                      <td>{data.description}</td>
                    </tr>
                   
                  </tbody>
                </table>
                </div>
  </CardBody>
  </Card>
  </div>
   )}
 
  
 
  </div>
  : null }
  </div>
  
<ModalFooter>
                          <Button
                              type="submit"
                              
                              className="w-xs waves-effect waves-light me-1"
                              // onClick={() => {
                              //     onSubmit();
                              // }}
                          >
                              {waitingForAxios ? (
                                  <Spinner animation="grow" size="sm" />
                              ) : (
                                  "Submit"
                              )}
                              
                          </Button>
                          <Button
                              type="button"
                              color="danger"
                              className="w-xs waves-effect waves-light me-1"
                              onClick={props.toggle}
                          >
                              Close
                          </Button>


</ModalFooter>
</Form>
      </Modals>
)
}


export default AssignWorkstation