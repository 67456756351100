import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import MaterialTable from "material-table";
import Swal from 'sweetalert2'
import { FilterList } from '@material-ui/icons';
import { APP_NAME, ranges } from '../../../../../../helpers/Constants';

import ReplayIcon from '@mui/icons-material/Replay';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import Breadcrumbs from '../../../../../../components/Common/Breadcrumb'; 
import { useDispatch,useSelector } from 'react-redux';
// import { deleteVehicle, editVehicle } from '../../../../../../store/driver';
// import { deleteCredit } from '../../../../../../store/wallet';
import EditSubscriptionDetails from './EditSubscriptionDetails';

import Tooltip from '@mui/material/Tooltip';
import nologo from "../../../../../../assets/images/no-logo.png"
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { ErrorAlert, SuccessAlert, Toastr } from '../../../../../../helpers/Alert';
import { IconButton} from '@material-ui/core';

import LockIcon from '@material-ui/icons/Lock';

import { tableIcons } from '../../../../../../helpers/MaterialIcons';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// import EditIcon from '@mui/icons-material/Edit';

import AssignWorkstation from './Assign_Workstation';
import { AssignCabin } from './Assign_Cabin';
import AssignSubscription from './AssignSubscription';
import { AddSubscriberDetails, AddSubscriberWorkStation, AddSubscription, DeleteSubscriberWorkStation,  } from '../../../../../../store/subscriber';
import { AddRfidCard, DeleteRfidCard,  EnableDisableRfidCard } from '../../../../../../store/rfidcard';
import {  AddTransactionReport } from '../../../../../../store/report';
import RFidCard from './Assign_Rfid';
import Moments from '../../../../../../helpers/Moment';
import fileDownload from 'js-file-download'
import DownloadIcon from '@mui/icons-material/Download';

import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import GenerateInvoice from './GenerateInvoice';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { pdfGenerator } from '../../../../../../helpers/PDFReportGenerator';
import EndSubscription from './EndSubscription';




export default function SubscriberDetail() {

  const profile = useSelector((state) => state.authSlice.profile)

  const { id } = useParams();
  // const { messege } = useParams();
  const dispatch=useDispatch();


  // useEffect(() => {
  //   console.log(messege)
  // }, [messege])
  
  const [filterEnabled, setFilterEnabled] = useState(false);


  const [value,setValue] = useState(0)



  const [image, setImage] = useState(null)
  // const [isOpen, setIsOpen] = useState(false)
  // const [wasteCardModal, setWasteCardModal] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [assignModalShow,setAssignModalShow] = useState(false);
  const [assignCabinModalShow,setAssignCabinModalShow] = useState(false);
  const [assignSubscriptionModalShow,setAssignSubscriptionModalShow] = useState(false);
  const [assignRFIDModalShow,setAssignRFIDModalShow] = useState(false);

  const [workStationData,setWorkStationData]=useState([])
  const [editModalShow,setEditModalShow]=useState(false)
  const [rfidCards,setRfidCards]=useState([])
  // const [accessLogs,setAccessLogs]=useState([])
  const [itemToBeEdited,setItemToBeEdited]=useState([])
  const [subscription,setSubscription] = useState([])
  // const [loader,setLoader] = useState(true)
  const [invoiceModal,setInvoiceModal]=useState(false)
  const [transactions,setTransactions]=useState([])

  const subscriberDetails=useSelector((state)=>state.SubscriberSlice.subscriberDetails)
  const subscriberWorkStation =useSelector((state)=>state.SubscriberSlice.subscriberWorkStation)
  const  rfidCard=useSelector((state)=>state.RfidSlice.rfidCard)
  const accessLog=useSelector((state)=>state.ReportSlice.doorAccess)
  const subscrptions=useSelector((state) => state.SubscriberSlice.subscriberSubscription)
  const transaction = useSelector((state) => state.ReportSlice.transaction)
  const [totalRecordsCount, setTotalRecordsCount] = useState(0)
  const [downloadIndicator,setDownloadIndicator] = useState(false)
  const [urlBackup, setUrlBackup] = useState('')
  const[endSubscriptionModal,setEndSubscriptionModal]=useState(false)
   const tableRef=useRef();
  const [date,setDate]=useState({})
 

  const [dashboardState, setDashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: <>{ profile.user_class=== "Hilite"?"Subscriber Details":"Company Details"}</>, link: "#" },
    ],
  });

  const assignModalToggle = () =>{
    setAssignModalShow(!assignModalShow)
  };
  const editModalToggle = () =>{
    setEditModalShow(!editModalShow)
  };
  const subscriptionToggle  = () =>{
    setEndSubscriptionModal(!endSubscriptionModal)
  };

  const assignRFIDToggle = () => {
    setAssignRFIDModalShow(!assignRFIDModalShow)
  }
  const assignSubscriptionModalToggle = () =>{
    setAssignSubscriptionModalShow(!assignSubscriptionModalShow)
  };

  const assignCabinModalToggle = () =>{
    setAssignCabinModalShow(!assignCabinModalShow)
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const invoiceToggle = () => {
    setInvoiceModal(!invoiceModal)
  }



     
  const exportData = (downloadType) => {
    const url = `${urlBackup}&${downloadType}`
    setDownloadIndicator(true)
    if (downloadType === "pdf_download") {
      if (totalRecordsCount >= 2000) {
        ErrorAlert("The maximum records to export as PDF is 2000. Please apply filter to reduce the number of records")
        setDownloadIndicator(false)
        return
      }
   
      axios({
        url: url,
        method: 'GET',
        // responseType: 'blob', // Important
      }).then((response) => {
        setDownloadIndicator(false)
        // console.log(response.data?.body)
        const headRowData = [response.data?.header]
        const bodyRowData = response.data?.body
        const reportName = "Door_Access_Log"
        var doc = pdfGenerator(headRowData, bodyRowData, reportName)
        doc.save("Door_Access_Log.pdf");
        console.log('')
        // tableRef.current.onQueryChange();
       
      }).catch(error => {
        Toastr("error", "Error downloading the file !")
        setDownloadIndicator(false)
      });
    }
    else {
      if (totalRecordsCount >= 5000) {
        ErrorAlert("The maximum records to export as CSV is 5000. Please apply filter to reduce the number of records")
        setDownloadIndicator(false)
        return
      }
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // Important
      }).then((response) => {
        let fileName = "Door_Access_Log.csv"
        fileDownload(response.data, fileName);
        setDownloadIndicator(false)
        // tableRef.current.onQueryChange();
      }).catch(error => {
        Toastr("error", "Error downloading the file !")
        setDownloadIndicator(false)
      });
    }
  }


  const handleDate = (start,end) => {

    if(start=== "cancel"){
      setDate({})
      tableRef.current.onQueryChange({page: 0});
    }
    else{
    const firstdate=moment(start).format('YYYY-MM-DD')
    const enddate=moment(end).format('YYYY-MM-DD')

    setDate({start_date: firstdate, end_date: enddate})

    tableRef.current.onQueryChange({page: 0});
    }
  }

  // const wasteModalToggle = () => {
  //   setWasteCardModal(!wasteCardModal);
  // };

  //for handling deletion of logo
  // const deleteClientLogo = (event) => {

  // };

  //for handling image/logo uploads
  const onChangefile = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const uploadedFile = e.target.files[0];

    if (uploadedFile.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
      showModal();
    } else {
      Swal.fire({
        title: "Oops...",
        text: "Invalid File Format",
        icon: "error",
      });
    }
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(uploadedFile);
  };

  const downloadHandler = (link) =>{

    const url = link
    const name = link.split('/')[2]
   
    axios.get(url, {
      responseType: 'blob',
    }).then(res => {
      fileDownload(res.data, name);
    });
  }




  useEffect(() => {

    const abortController = new AbortController();

    const url=`/workspace_app/subscriber_detail/${profile.user_class ==='Subscriber' ? profile.link_id:id}`

    axios.get(url)
    .then((response) =>{
      dispatch(AddSubscriberDetails(response.data))
      dispatch(AddSubscriberWorkStation(response.data.workstation_company))
      dispatch( AddRfidCard(response.data.rfid_company))
      // dispatch(AddDoorAccessReport(response.data.accesscontrollog_operator))
      dispatch(AddSubscription(response.data.subscriber_data))
      dispatch(AddTransactionReport(response.data.payment_company))
      
    })

    .catch((error) =>{

    })

   

  
    setDashboardState(
      {
        breadcrumbItems: [
          { title: APP_NAME, link: "/dashboard" },
          { title: <>{ profile.user_class=== "Hilite"?"Subscriber Details":"Company Details"}</>, link: "#" },
        ],
      }  
    )

    return (() => {
      abortController.abort();
      dispatch(AddSubscriberDetails([]))
      dispatch(AddSubscriberWorkStation([]))
      dispatch(AddRfidCard([]))
      dispatch(AddSubscription([]))
      dispatch(AddTransactionReport([]))
    })
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    console.log(subscriberDetails)
  }, [subscrptions])
  

  // useEffect(() => {
  //   if(value === 4){
  //     const url=`/workspace_app/access_control_log_company/${id}`

  //   axios.get(url)
  //   .then((response) =>{
     
  //     dispatch(AddDoorAccessReport(response.data))
  //    setLoader(false)
      
  //   })

  //   .catch((error) =>{
  //     setLoader(false)
  //   })
  //   }


  //   return () => {
  //     setLoader(true)
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [value])



  useEffect(() => {
    if(subscriberWorkStation !== null){
      
      const temp=subscriberWorkStation.map((data)=>({...data,
        cabin_name:data?.cabin?.cabin_name ? data.cabin.cabin_name : 'No Data'
      }))
     
      setWorkStationData(temp)
    }
    
    
  }, [subscriberWorkStation])

  useEffect(()=>{

    if(rfidCard !== null){
      const temp=rfidCard.map((data)=>({...data,
      staff_name:data.staff && data.staff?.full_name ? data.staff?.full_name : 'No Staff Assigned'
      }))
      setRfidCards(temp)
    }

  },[rfidCard])
  
  useEffect(()=>{

    if(transaction !== null){
      const temp=transaction.map((data)=>({...data,
        
        invoice_id:data?.invoice?.invoice_id ? data.invoice.invoice_id :'No Data',
       created_date:data?.created_date ? Moments(data.created_date,'datetime')  : 'No Data',
       remarks:data?.remarks ? data.remarks :"No Data",
       downloadPath:data?.invoice?.invoice_file ? data.invoice.invoice_file : '',
      
      
      }))
      setTransactions(temp)
    }

  },[transaction])
  



  // useEffect(() => {
  //   if(accessLog !== null){
        

  //     const TempData=accessLog.map((data,key) =>({...data,
  //       slno:key+1,
  //       username:data?.staff && data?.staff?.first_name && data?.staff?.last_name ? data.staff.first_name  +" "+ data.staff.last_name : data?.staff?.first_name ? data.staff.first_name : "No Data",
  //       cardNo:data?.rfid_card?.tag_id? data.rfid_card.tag_id :"No Data",
  //       accessed_time:data?.accessed_time? Moments(data.accessed_time,'datetime'):"No Data",


  //     }))
  //     setAccessLogs(TempData)
  //   }
  // }, [accessLog])


  useEffect(() => {
    if(subscrptions !== null){

      // const tp=subscrptions.filter((data) => data.status === 'Active')
      // if(tp.length === 0 && subscriberDetails.subscription_status !== 'Pending'){
      //   dispatch(AddSubscriberDetails({...subscriberDetails,subscription_status:'Expired'}))
      // }

      const TempData=subscrptions.map((data) =>({...data,
        plan_name:data?.subscription?.plan_name ? data.subscription.plan_name : 'No Data',
        workstation_name:data?.workstation?.workstation_name ? data.workstation.workstation_name : 'No Data',
        workstation_no:data?.workstation?.workstation_no ? data.workstation.workstation_no : "No Data",
        discount:data?.discount? data.discount:'No Data',
        workstation_id:data?.workstation?.id?data.workstation.id:"No Data"
 
      }))
      setSubscription(TempData)
    }
  }, [subscrptions])

 
  

  

  
  const SubscriptionColumns =
  [
   
    { title: "Plan Name", field: "plan_name"},
    { title: "WorkStation Name", field: "workstation_name"},
    { title: "WorkStation No", field: "workstation_name"},
    { title: "Plan Amount", field: "plan_amount" },
    { title: "Discount", field: "discount"},
    { title: "Net Amount", field: "net_amount"},
    { title: "Subscription Start", field: "subscription_start",
    render:rowData =><> {rowData.subscription_start ? Moments(rowData.subscription_start) : 'No Data'}</>,
  
  },

    { title: "Subscription End", field: "subscription_end",
    render:rowData =><> {rowData.subscription_end ? Moments(rowData.subscription_end) : 'No Data'}</>,
  
  },

    { title: "Status", field: "status",
    

  
  
  },
   

    
  ]
  const TransactionColumns =
  [
   
    
    { title: "Invoice ID", field: "invoice_id"},

    { title: "Payment ID", field: "payment_id",
    // render:rowData =><> {rowData.payment_id ? rowData.payment_id : 'No Data'}</>,
      
  
  },
    { title: "Amount", field: "amount",
  //  render: rowData=><> {rowData.payment_company?.payment_id ? rowData.payment_company.payment_id : 'No Data'}</>,
  
  },
  { title: "Payment Date", field: "created_date"},
  
    { title: "Remarks", field: "remarks" },
  
    { title: "Status", field: "status"},
   

    
  ]

  const rfidColumns =
  [
    { title: "Card No", field: "tag_id"},
    { title: "Card Name", field: "friendly_name" },
    { title: "Staff Assigned", field: "staff_name" },

    { title: "Status", field: "status"},

   

    ]

  const WorkstationColumns =
  [
    { title: "Work Station No", field: "workstation_no"},
    { title: "Work Station Name" , field: "workstation_name"},
    { title: 'Cabin Name' , field: 'cabin_name',
  },
    
    { title: "Status", field: "status" },
    
   

 
  ]
  
  const HotseatColumns =
  [
    { title: "Hotseat No", field: "workstation_no"},
    { title: "Hotseat Name" , field: "workstation_name"},

    
    { title: "Status", field: "status" },
    
   

 
  ]

  const accessLogColumn =
  [
    { title: "SL No", field: "slno"},
    { title: "Username", field: "username"} ,
   
    { title: "Card No", field: "cardNo"},
    { title: "Attempted Date", field: "accessed_time"},
    { title: "Attempted Door", field: "accessed_door"},
    { title: "Reason", field: "reason"},
    { title: "Status", field: "status"},
   
  ]

//  const report=[
//     {
//         icon: "ri-calendar-2-line",
//         title: "Active Subscriptions",
//         value: 10,
//         rate: 10 + "%",
//         desc: "out of " + 10,
//       },
//       {
//         icon: "ri-calendar-2-line",
//         title: "Expired Subscriptions",
//         value: 10,
//         rate: 10 + "%",
//         desc: "out of " + 10,
//       },
//       {
//         icon: "ri-calendar-2-line",
//         title: "Expired Subscriptions",
//         value: 10,
//         rate: 10 + "%",
//         desc: "out of " + 10,
//       },
//   ]

        // for handling delete activate deactivate
 const UpdateHandler = (id,method) =>{

        if(method === 'Disabled'){
        const url=`/workspace_app/disable_rfid/${id}`
        axios.put(url)
        .then((response) =>{
         // console.log(response.data)
          SuccessAlert("Rfid Card Disabled Successfully!")
          dispatch(EnableDisableRfidCard({...response.data,id:id}))
        })
        .catch((error) =>{
          
          ErrorAlert(error.response.message)
         
        })
      }
      else if(method === 'Active'){
        const url=`/workspace_app/enable_rfid/${id}`
        axios.put(url)
        .then((response) =>{
          
          SuccessAlert("Rfid Card Enabled Successfully!")
          dispatch(EnableDisableRfidCard({...response.data,id:id}))
        })
        .catch((error) =>{
          
          ErrorAlert(error.response.message)
         
        })
      }
      else if(method=== 'Remove'){
        const url=`/workspace_app/diallocate_rfid/${id}`
        axios.put(url)
        .then((response) =>{
          SuccessAlert("Rfid Card Disallocated Successfully!")
          dispatch(DeleteRfidCard(id))
        })
        .catch((error) =>{
          ErrorAlert(error.response.data.message)
        })
      
      }
    
 }

  const handleChange = (event, newValue) => {
    setValue(newValue)
    // if(newValue === 1){
    //   navigate('/staffs')
    // }
    // else if(newValue === 2){
    //   navigate('/subscriberworkstations')
    // }
  };
  let tabStyle = {
    minWidth: 120,
    paddingLeft: 12,
    paddingRight: 12,
   
  };




  const ReleaseWorkstation=(id)=>{
  

    const url="/workspace_app/release_workstation"
    axios.post(url,{ws_id:id})
    .then((response)=>{
      //  setLoader(false)
      // dispatch(DeleteSubscriberWorkStation())
      dispatch(DeleteSubscriberWorkStation(id))

      SuccessAlert(response.data.message)


    })
    .catch((error) =>{
      // setLoader(false)
      ErrorAlert(error.response.data.message)
    })
  }
  

  return (
    <React.Fragment>
       {assignModalShow &&
      <AssignWorkstation
      title= { subscriberDetails?.is_virtual_acc ?  "Assign Hotseat" : 'Assign WorkStation'}
      isOpen={assignModalShow}
      toggle={assignModalToggle}
      id={id}
      
        />
      }
       {assignCabinModalShow &&
      <AssignCabin
      title={'Assign Cabin'}
      isOpen={assignCabinModalShow}
      toggle={assignCabinModalToggle}
      id={id}
        />
      }
       {assignSubscriptionModalShow &&
      <AssignSubscription 
      title={'Assign Subscription '}
      isOpen={assignSubscriptionModalShow}
      toggle={assignSubscriptionModalToggle}
      id={id}
        />
      }
       {assignRFIDModalShow &&
      <RFidCard 
      title={'Assign RFID Card '}
      isOpen={assignRFIDModalShow}
      toggle={assignRFIDToggle}
      id={id}
        />
      }
          { invoiceModal&&
      <GenerateInvoice
      title={'Generate Invoice'}
      isOpen={invoiceModal}
      toggle={invoiceToggle}
      id={id}
        />
      }

{ endSubscriptionModal&&
      <EndSubscription
      title={'End Subscription'}
      isOpen={endSubscriptionModal}
      toggle={subscriptionToggle}
      id={id}
        />
      }


{/* 
 {test &&
      <TestModal 
      title={''}
      isOpen={test}
      toggle={testToggle}
        />
      }  */}
      
       {editModalShow &&
      <EditSubscriptionDetails
      title={'Extend / End Subscription '}
      isOpen={editModalShow}
      toggle={editModalToggle}
      item={itemToBeEdited}
            // item={itemToBeEdited}

        />
      }



    
          <div className="page-content">
            <Container fluid>
             
                  <Breadcrumbs
      title= { profile.user_class=== "Hilite"?"Subscriber Details":"Company Details"}
      breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
               {/* {profile.user_class === "Hilite"&& profile.user_type === "Admin"&&  subscriberDetails?.status === 'Activated'?<></> */}
              {profile.user_class === "Hilite"&& profile.user_type === "Admin"?<>
              {value=== 0 ?
              <div
                className="disappearing-button-container-page-top-right" style={{marginRight:"-80px"}}>
                 <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={assignSubscriptionModalToggle}
                
                >
                  Assign Subscription
                </Button>

                <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={invoiceToggle}
                
                >
                  Generate Invoice
                </Button>
                <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={subscriptionToggle}
                
                >
                  End Subscription
                </Button>


                  </div>

                  


              : value===2?
              
              <div
              className="disappearing-button-container-page-top-right" style={{marginRight:"-80px"}}>
                {subscriberDetails?.is_virtual_acc && subscriberDetails?.workstation_company.length >= 1 ? null :
               <Button
                style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                type="button"
                color="warning"
                className="w-xs waves-effect waves-light me-1"
                
                onClick={assignModalToggle}
              >
               { subscriberDetails?.is_virtual_acc ?  "Assign Hotseat" : 'Assign WorkStation'}
              </Button>
              
              
              
              }
             { subscriberDetails?.is_virtual_acc ? null : <Button
                style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                type="button"
                color="warning"
                className="w-xs waves-effect waves-light me-1"
                
                onClick={assignCabinModalToggle}
              >
                Assign Cabin
              </Button>
             }
                </div>
          : null}


          
              
              {value=== 3 ?
              <div
                className="disappearing-button-container-page-top-right" style={{marginRight:"-80px"}}>
                 <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={assignRFIDToggle}
                >
                  Assign RFID Card
                </Button>
                  </div>




            : null } </> : null}
<Box >
  <Tabs value={value} onChange={handleChange}  allowScrollButtonsMobile variant='scrollable' sx={{ borderBottom: 1, borderColor: 'divider'}}>
    <Tab label="Overview" wrapped  style={tabStyle}/>
    <Tab label="Transaction Details" wrapped style={tabStyle}/>
   <Tab label={ subscriberDetails?.is_virtual_acc ?  "Hotseats" : 'WorkStations'}
 wrapped style={tabStyle} /> 

   <Tab label="Rfid Cards" wrapped style={tabStyle}/> 

    
    <Tab label=" Access Logs" wrapped style={tabStyle}/>
   

  </Tabs>
</Box>
{value === 0 ? <>

      
              {/* <div className='detail_item detail_none'> */}

      
  <Card style={{ minHeight: '180px' , overflow:'hidden'}}>
  <CardBody>                
                <div className='details_card' style={{marginTop:'20px'}}>
                  <div style={{maxWidth:'540px'}}>
                <table cellSpacing="0" cellPadding="10">
                        <tbody>
                        <tr>
                          <td>Reg No</td>
                          <td> : </td>
                          <td>{subscriberDetails && subscriberDetails?.reg_no ? subscriberDetails.reg_no : 'No Data'}</td>
                        </tr>
                        <tr>
                          <td>Company Name</td>
                          <td> : </td>
                          <td>{subscriberDetails && subscriberDetails?.company_name ? subscriberDetails.company_name : 'No Data'}</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td> : </td>
                          <td>{subscriberDetails && subscriberDetails?.address ? subscriberDetails.address : "No Data"}</td>
                        </tr>
                        <tr>
                          <td>GST IN</td>
                          <td> : </td>
                          <td>{subscriberDetails && subscriberDetails?.gst_in ? subscriberDetails.gst_in : "No Data"}</td>
                        </tr>
                        
                       
                        <tr>
                          <td> Account Type</td>
                          <td> : </td>
                          <td > {subscriberDetails && subscriberDetails?.is_virtual_acc?  "Virtual" :"Ordinary"}</td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                    <div>
                    <table cellSpacing="0" cellPadding="10">
                        <tbody>
                        
                        <tr>
                          <td>Contact Person</td>
                          <td> : </td>
                          <td>{subscriberDetails && subscriberDetails?.contact_person?.full_name ? subscriberDetails.contact_person.full_name : "No Data"}</td>
                        </tr>
                        <tr>
                          <td>Contact No</td>
                          <td> : </td>
                          <td>{subscriberDetails && subscriberDetails?.contact_person?.contact_number ? subscriberDetails.contact_person.contact_number : "No Data"}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td> : </td>
                          <td>{subscriberDetails && subscriberDetails?.contact_person?.email ? subscriberDetails.contact_person.email : "No Data"}</td>
                        </tr>
                        <tr>
                          <td>No Of Workstations</td>
                          <td> : </td>
                          <td >{subscriberDetails && subscriberDetails?.workstation_company?.length}</td>
                        </tr>
                       
                        <tr>
                          <td> Account Status</td>
                          <td> : </td>
                          <td > {subscriberDetails && subscriberDetails?.status ? subscriberDetails['status'] === 'Blocked' ? <span className='text-danger'>{subscriberDetails.status}</span> : subscriberDetails.status : "No Data"}</td>
                        </tr>
                        
                       
                      </tbody>
                    </table>
                    </div>

                    <div className='detail__card__rightLogos'>


                    {/* <div> */}
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: " repeat(2, 1fr)",
                        gridTemplateRows: "1fr",
                        marginTop: "3rem",
                      }}
                    >


                      {/* {clientDetails &&
                    clientDetails.logo !== undefined &&
                    clientDetails.logo !== null ? ( */}

                      {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}


                      {/* <div>

                    <label htmlFor="myInput">
                      <div>
                        <img
                          src={
                            logo
                              ? logo
                              : clientDetails && baseURL + clientDetails?.logo.substring(1)
                          }
                          alt=""

                          style={{ cursor: 'pointer', width: '100%', height: '100%' }}
                          id='Disabled3AutoHideExample'
                        />
                      </div>

                      <Tooltip title="Update Logo">
                        Update Logo
                      </Tooltip>
                    </label>



                    <input
                      id="myInput"
                      style={{ display: "none" }}
                      type={"file"}

                      onChange={onChangefile}
                    />
                    </div>
                    <div>   <i
                    className="fas fa-trash"
                    aria-hidden="true"
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                    onClick={() => { Swal.fire({
                      title: 'Are you sure?',
                      text: "You won't be able to revert this!",
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteClientLogo()
                      }
                    }) }}
                    ></i></div>


                    </div> */}
                      {/* ) : <> */}

                      <div>
                      <Tooltip title="Add Logo">
                        <label htmlFor="myInput">
                          {" "}
                          <i
                            id='Disabled3AutoHideExample'

                            aria-hidden="true"
                          > <img src={nologo} width="100%" alt='' /></i>
                          {/* <Tooltip title="Add Logo"> 
                        Add Logo
                      </Tooltip> */}
                        </label>
                        </Tooltip>
                        {/* <Tooltip title="Add Logo">  */}
                        <input
                          id="myInput"
                          style={{ display: "none" }}
                          type={"file"}

                          onChange={onChangefile}
                        />
                        {/* </Tooltip> */}
                      
                      </div>

                      

                    </div>
                  
                    </div>
                    
                    </div>

                      
                    </CardBody>
                      </Card>   
                   
                     
               
                    
                    <div >
                  
{/* <Row xl={3}>
             

             
    <MiniWidgets reports={report} />
    </Row> */}

    <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                  <Row>
                    <h6>Subscription List</h6>
                      </Row>
                    <div
                      style={{
                       
                        overflowY: 'auto'
                      }}
                  
                    >
                      <MaterialTable
                        title=" "
                        columns={SubscriptionColumns}
                        data={subscription}
                   
                        
                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },
                          
                          rowData => ({
                            icon: ReplayIcon,
                            isFreeAction:profile.user_class ==="Subscriber" ? true : false,
                            tooltip: 'Extend / End Subscription ',
                            onClick: (event, rowData) => {
                              
                              // console.log(rowData)
                              // setEditClientModalShow(true)
                              setItemToBeEdited(rowData)
                              editModalToggle();
                            },
                            hidden: profile.user_class ==="Subscriber"|| profile.user_type ==="User"|| rowData.status ==="Expired"|| rowData.status ==="Cancelled"

                          
                          }),
                          // rowData => ({
                          //   icon: PhonelinkOffIcon,
                          //   // isFreeAction:profile.user_class ==="Subscriber" ? true : false,
                          //   tooltip: 'Release WorkStation',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //               title: 'Are you sure?',
                          //               icon: 'warning',
                          //               text: "You won't be able to revert this!",
                          //               showCancelButton: true,
                          //               confirmButtonColor: '#3085d6',
                          //               cancelButtonColor: '#d33',
                          //               confirmButtonText: 'Yes, Release it!'
                          //             }).then((result) => {
                          //               if (result.isConfirmed) {
                          //                 // setItemToBeEdited(rowData.workstation_id)
                          //                ReleaseWorkstation(rowData.workstation_id)
                   
                          //               }
                                        
                      
                          //     })
                              



                          //   },
                          //   hidden: (rowData.status !=="Expired" && rowData?.workstation?.status !=="Subscribed" && rowData.workstation.company ===null) || rowData.status !== 'Expired' ||  (rowData.status ==="Expired" && rowData?.workstation?.status !=="Subscribed" && rowData.workstation.company ===null)||subscriberDetails?.is_virtual_acc===true

                          
                          // }),
                         
                        
                        ]}
                        options={{
                          exportFileName:'Subscription Details',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled,
                          
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
</div>
                    </> : value === 1 ? <>
                    <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                  
                    <div
                      style={{
                       
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={TransactionColumns}
                        data={transactions}

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },
                          {
                            icon: DownloadIcon,
                            tooltip: 'Download Invoice',
                           
                            onClick: (event,rowData) => {downloadHandler(rowData.downloadPath)}
                          },
                          // {
                          //   icon: DownloadIcon,
                          //   tooltip: 'Download QRcode',
                          //   onClick: (event, rowData) => {}
                          // },

                          
                          // {
                          //   icon: VisibilityOutlinedIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                          // },
                          // {
                          //   icon: Edit,
                          //   tooltip: 'Edit Subscriber',
                          //   onClick: (event, rowData) => {
                          //     // console.log(rowData)
                          //     // setEditClientModalShow(true)
                          //     setItemToBeEdited(rowData)
                              
                          //   }
                          // },
                          // rowData => ({
                          //   icon: DeleteOutline,
                          //   tooltip: 'Delete Subscriber',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       text: "You won't be able to revert this!",
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, delete it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //       //   vehicleUpdateHandler(rowData.id,"Deleted")
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   disabled: rowData.birthYear < 2000
                          // }),
                          // rowData => ({
                          //   icon: Unlock,
                          //   tooltip: 'Enable Subscriber',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                                
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, Enable it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //       //   vehicleUpdateHandler(rowData.id,"Active")
                               
                          //       }
                          //     })
                          //   },
                          //   hidden: rowData.status === "Active" 
                          // }),
                        //   rowData => ({
                        //     icon: LockIcon,
                        //     tooltip: 'Disable Subscriber',
                        //     onClick: (event, rowData) => {
                        //       Swal.fire({
                        //         title: 'Are you sure?',
                        //         icon: 'warning',
                        //         showCancelButton: true,
                        //         confirmButtonColor: '#3085d6',
                        //         cancelButtonColor: '#d33',
                        //         confirmButtonText: 'Yes, Disable it!'
                        //       }).then((result) => {
                        //         if (result.isConfirmed) {
                        //         //   vehicleUpdateHandler(rowData.id,"Disabled")
           
                        //         }
                        //       })
                        //     },
                        //     hidden: rowData.status === "Disabled" 
                        //   }),
                          
                        ]}
                        options={{
                          exportFileName:'Transactions',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
                    
                    </> : value === 2 ? <>
                    <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                  
                    <div
                      style={{
                       
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={ subscriberDetails?.is_virtual_acc ?  HotseatColumns : WorkstationColumns}
                        
                        data={workStationData}

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },
                          rowData => ({
                            icon: PhonelinkOffIcon,
                            // isFreeAction:profile.user_class ==="Subscriber" ? true : false,
                            tooltip:subscriberDetails?.is_virtual_acc ? 'Release Hotseat':'Release Workstation' ,
                            onClick: (event, rowData) => {
                              Swal.fire({
                                        title: 'Are you sure?',
                                        icon: 'warning',
                                        text: "You won't be able to revert this!",
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Yes, Release it!'
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          // setItemToBeEdited(rowData.workstation_id)
                                         ReleaseWorkstation(rowData.id)
                   
                                        }
                                        
                      
                              })
                              



                            },
                            hidden:rowData?.subscription_status !=="Expired"  && rowData.status !=="Allocated"

                          
                          }),
                          

                          
                          // {
                          //   icon: VisibilityOutlinedIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                          // },
                          // {
                          //   icon: Edit,
                          //   tooltip: 'Edit Driver',
                          //   onClick: (event, rowData) => {
                          //     // console.log(rowData)
                          //     // setEditClientModalShow(true)
                          //     setItemToBeEdited(rowData)
                              
                          //   }
                          // },
                          // rowData => ({
                          //   icon: DeleteOutline,
                          //   tooltip: 'Delete Driver',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       text: "You won't be able to revert this!",
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, delete it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         // deleteClientData(rowData?.id)

                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   disabled: rowData.birthYear < 2000
                          // })
                        ]}
                        options={{
                          exportFileName:'WorkStations',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
            </> : value === 3 ? <>
            
            <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                 
                    <div
                      style={{
                       
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={rfidColumns}
                        data={rfidCards}

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },

                          
                          // {
                          //   icon: VisibilityOutlinedIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                          // },
                          // {
                          //   icon: Edit,
                          //   tooltip: 'Edit Credit',
                          //   onClick: (event, rowData) => {
                          //     // console.log(rowData)
                          //     // setEditClientModalShow(true)
                          //     setItemToBeEdited(rowData)
                          //     editCreditModalToggle()
                          //   }
                          // },
                          
                          rowData => ({
                            icon: LockIcon,
                            tooltip: 'Disable Rfid',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Disable it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,'Disabled')

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden:profile.user_type ==="User" || rowData.status === "Available" || rowData.status === 'Lost' || rowData.status === 'Disabled' || rowData.status === 'Deleted'

                          }),
                          rowData => ({
                            icon: LockOpenIcon,
                            tooltip: 'Enable Rfid',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Enable it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,'Active')

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            // hidden: rowData.status === "Available" || rowData.status === 'Lost' || rowData.status === 'Disabled' || rowData.status === 'Deleted'
                            hidden: profile.user_type ==="User"|| rowData.status === "Allocated"
                          }),
                          rowData => ({
                            icon: DoDisturbIcon,
                            tooltip: 'Disallocate Card',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Disallocate it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,'Remove')

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden: profile.user_class ==="Subscriber"||  profile.user_type ==="User"||rowData.status === 'Available' || rowData.status === 'Deleted'
                          }),
                        ]}
                        options={{
                          exportFileName:'Rfid Details',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
            </> : value === 4 ? <> <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>

                  
                    <div
                      style={{
                       
                        overflowY: 'auto'
                      }}
                    >
                      <div style={{display:'flex',marginBottom:'-55px',width:'60%'}}>
            <Col xl={1}>
              <DateRangePicker  onCallback={(startdate,enddate) => handleDate(startdate._d,enddate._d)}
                  onCancel={(data)=> handleDate(data.type)}

                  initialSettings={{ranges:ranges,
                                      opens:'right',
                                      startDate:moment().subtract(1, 'days'),
                                      // showCustomRangeLabel:true,
                                      // showDropdowns:true,
                                      endDate: moment(),
                                      maxDate:moment()
                                    }}>
  <IconButton aria-label="delete"  color={Object.keys(date).length !== 0 ? 'primary' : 'default'} style={{zIndex:'1'}}>
    <Tooltip title='Show Calendar'>
  <CalendarMonthIcon />
  </Tooltip>
</IconButton>
  </DateRangePicker>

            </Col>
            <div  style={{zIndex:'1',marginBottom:'-40px' }}>
             <p style={{marginTop:'17px',paddingRight:'20px'}}>{Object.keys(date).length !== 0 ?  `Showing Access Logs from ${date.start_date} to ${date.end_date}` : 'Showing All Access logs'}</p>
            </div>
          </div>
                      <MaterialTable
                        title=" "
                        columns={accessLogColumn}
                        
                     
                        tableRef={tableRef}

                        data={ (query) => {
                          console.log(query)
                          return (new Promise((resolve, reject) => {
                            let orderBy = ""
                            // let search =''
                            if (query.orderBy) {
                              orderBy = query.orderDirection === "asc" ? `&ordering=-${query.orderBy?.field}` : `&ordering=${query.orderBy?.field}`
                            }
                            // if(query.search){
                            //   search=query.search === 'Discharged' ? 'Dumped' : query.search
                            // }

                            let url = `/workspace_app/access_control_log_company?limit=${query.pageSize}&offset=${query.pageSize * query.page}&search=${query.search}${orderBy}&company_id=${profile.user_class ==='Subscriber' ? profile.link_id:id}`
                            if (Object.keys(date).length !== 0){
                              url +=`&start_date=${date.start_date}&end_date=${date.end_date}`
                            }
                            
                            setUrlBackup(url)
                            axios.get(url)
                              .then((response) => {
                                setTotalRecordsCount(response.data?.count)
                                // tableRef.current.dataManager.changePageSize(query.pageSize)
                                // console.log(response.data.results)
                                const DoorAccessList = response.data?.results
                                // dispatch(addGtcc(response.data.results))
                                const TempData = DoorAccessList.map((data,key) => ({
                                  ...data,
                                  slno:key+1,
                                        username:data?.staff && data?.staff?.first_name && data?.staff?.last_name ? data.staff.first_name  +" "+ data.staff.last_name : data?.staff?.first_name ? data.staff.first_name : "No Data",
                                        cardNo:data?.rfid_card?.tag_id? data.rfid_card.tag_id :"No Data",
                                       accessed_time:data?.accessed_time? Moments(data.accessed_time,'datetime'):"No Data",
                                       accessed_door:data?.accessed_gate ? data.accessed_gate : 'No Data'
                          
                                }))
                                
                                resolve({
                                  data: TempData,
                                  page: response.data?.results.length === 0 ? 0 :query.page,
                                  totalCount: response.data?.count,
                                })
                              })
                              .catch((error) => {
                                // console.log('rejected')
                                reject({
                                  data: [],
                                  page: query.page,
                                  totalCount: 0
                                })
                              })
                          }))
                        }}
                     
                        isLoading={downloadIndicator}
                        actions={[
                          // {
                          //   icon: FilterList,
                          //   tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                          //   isFreeAction: true,
                          //   onClick: (event) => { setFilterEnabled(state => !state) }
                          // },
                        ]}
                        options={{
                          exportFileName:'Access Log Details',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          // filtering: filterEnabled,
                          debounceInterval:500,
                          exportCsv: async () => exportData("csv_download"),
                          exportPdf: async () => exportData("pdf_download"),
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col></>:null

                    
                }


        
         
                
                </Container>
                </div>
                </React.Fragment>

  )
              }
