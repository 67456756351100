import React, { useEffect, useState } from 'react'
import Modals from '../../../../components/Modal';
import { useForm } from "react-hook-form";
import { Col, Form, FormGroup, Label, Row, Spinner} from 'reactstrap';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../helpers/MaterialIcons';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IconButton } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
// import { authAxios } from '../../../../../axiosInstance';
// import GraphLoader from '../../../../../components/GraphLoader/GraphLoader';
import { Toastr } from '../../../../helpers/Alert';

// import { useDispatch } from 'react-redux';
// import { editCategory, editEntityCategory } from '../../../../../store/entity';
import axios from 'axios';
import Error from '../../../../helpers/Error';

function AddSchedule(props) {
    // const dispatch = useDispatch()
    const [datas,setData] = useState([])
    const [loader,setLoader]=useState(true)
    const [waitingForAxios,setWaitingForAxios]=useState(false)

    const columns =[{ title: "Schedule Name", field: "scheduler_name"},] 

    useEffect(() => {

      const url="/workspace_app/scheduler"
      
      axios.get(url)
        .then((response) => {
          setData(response.data)
          setLoader(false)
        })
        .catch((error) =>{
          setLoader(false)
        })
    }, [])
    

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();


    const onSubmit = (data) =>{
      setWaitingForAxios(true)
      
      const url="/workspace_app/scheduler"

      axios.post(url,data)
        .then((response) =>{
          
          Toastr("success","New Scheduler Added Succesfully")
          setData((state) => ([...state,response.data]))
          setWaitingForAxios(false)
          reset({
            scheduler_name: ''
          })
        })
        .catch((error)=>{
          const err_msg=Error(error)
          Toastr("error",err_msg)
          setWaitingForAxios(false)
        }) 
    }

  return (
    <div>
      
      <Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} size={props.size} loader={loader}>
        <Form onSubmit={handleSubmit(onSubmit)} className="form-horizontal text-center pt-4">

        <FormGroup row style={{ marginBottom: "10px" }}>
            <Row>
    <Label for="Scheduler Name" sm={3}>
        Scheduler Name
    </Label>
    <Col sm={7}>
        <input
            placeholder="Scheduler Name"
            type="text"
            className="form-control"
            {...register("scheduler_name", {
                required: true,
            })}
        /> 
        {errors.scheduler_name?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
    <Col> 
        <Tooltip title="Add Schedule">
        <IconButton type="submit" style={{marginTop:"-5px"}} color="primary">
          {waitingForAxios ? <Spinner animation="grow" style={{marginTop:"-4px"}} /> : 
  <AddCircleIcon />
          }
</IconButton >
</Tooltip>
</Col>
    </Row>
    
</FormGroup>
<div  className="table-responsive"
            style={{
              marginTop:"-20px",
              overflowY: 'auto',
             
             
            }}
          >
            <MaterialTable
              title=" "
              columns={columns}
              data={datas}


              editable={{
                // isEditable: rowData => rowData.field === 'discount', // only name(a) rows would be editable
                // isEditHidden: rowData => rowData.rate_type === 'Normal Rate'||profile.entity.entity_type === "CLIENT",
                // isDeleteHidden: rowData => rowData.rate_type === 'Normal Rate'||profile.entity.entity_type === "CLIENT",
                onRowUpdate: (newData, oldData) =>
                
                  new Promise((resolve, reject) => { 
                            
                    setTimeout(() => {
                      const dataUpdate = [...datas];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      
                      const list={scheduler_name:newData.scheduler_name}
                      // console.log('mywaste_api',list)
                      const url = `/workspace_app/scheduler/${oldData.id}`;
                      axios.patch(url, list)
                      .then(response => {
                        setData([...dataUpdate]);
                        // dispatch(editCategory(response.data))
                        Toastr("success","Schedule Edited Succesfully")
                        
                      })   
                      .catch((error) => {
                          const error_msg = Error(error)
                          Toastr("error",error_msg)
                      });
                    
                      resolve();
                    }, 1000);
                  }),
                  onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataDelete = [...datas];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        
                       
                      const url = `/workspace_app/scheduler/${oldData.id}`;
                      axios.patch(url, {status:"Deleted"})
                      .then(response => {
                        setData([...dataDelete]);
                        
                        Toastr("success","Schedule Deleted Succesfully")
                        
                      })   
                      .catch((error) => {
                          const error_msg = Error(error)
                          Toastr("error",error_msg)
                      });
                          resolve();
                      }, 1000);
                  })

              }}
           
            //   actions={[
            //     rowData => ({
            //       icon: AddBox,
            //       tooltip: 'Apply Special Rate',
            //       onClick: (event, rowData) => {  
             
            //         setEditWasteModalShow(true)
            //         setItemToBeEdited(rowData)},
            //       hidden: rowData.rate_type ==='Special Rate' ||profile.entity.entity_type === "CLIENT",
            //     }),
            //   ]}

              options={{
                actionsColumnIndex: -1,
                search: false
             
              }}
              icons={tableIcons}
            />

          </div>



</Form>
        </Modals></div>
  )
}

export default AddSchedule