import {React,useEffect,useState} from 'react'
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Spinner, Card, CardBody } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch } from 'react-redux';
import Modals from '../../../../../components/Modal';
import { ErrorAlert, SuccessAlert, Toastr } from '../../../../../helpers/Alert';
import axios from 'axios';
import { AddNewSubscriberWorkStation } from '../../../../../store/subscriber';
// import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';
// import Error from '../../../../helpers/Error';
// import axios from 'axios';
// import { AddNewClientUser } from '../../../../store/user';


export const AssignCabin = (props) => {
  const [loader,setLoader] = useState(true)
  const [waitingForAxios,setWaitingForAxios] = useState(false)
  const [selected,setSelected] = useState([])
  const [company,setCompany] = useState([])
  const [cabin,setCabin] = useState([])
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
   
    formState: { errors },
  } = useForm();

  useEffect(() => {

    const url='/workspace_app/limited_subscriber_list'
    axios.get(url)
    .then((response) => {
      

      const temp=[]
      response.data.map((data) => {
        if(data.status === "Activated" && data.is_virtual_acc === false){
            temp.push({...data})
        }
        return(temp)

      })
      setCompany(temp)
      setLoader(false)
    })
    .catch((error) => {
      setLoader(false)
    })

    const url2='/workspace_app/assign_workstation_cabin'
    axios.get(url2)
    .then((response) => {
       const temp=[]
     response.data.map((data) => {
        if(data.workstation_cabin.length !==0){
        temp.push({...data,no_of_Workstations:data.workstation_cabin.length})
        }
        return(temp)
      })
     setCabin(temp)
    })
  }, [])


  const onSubmit = (data) =>{
    
      if(selected.length !== 0){

        let formData = new FormData(); //formdata object
        formData.append("company", data.company);
        formData.append("cabin", data.cabin);
       
      setWaitingForAxios(true)

      const url='/workspace_app/assign_workstation_cabin'

      axios.post(url,formData)
      .then((response) =>{

          dispatch(AddNewSubscriberWorkStation(response.data))
          setWaitingForAxios(false)
          SuccessAlert("Cabin Assigned Successfully")
          props.toggle()

      })
      .catch((error) =>{
          setWaitingForAxios(false)
          // const error_msg=Error(error.response.data[0])
          ErrorAlert(error.response.data[0])
      })
    }
    else{
      Toastr("warning",'Please Select An Cabin For Assigning')
    }

  }
  

  const selectChange = (id) =>{
    if(id !== ''){
      const temp=cabin.filter((cabin) =>  Number(cabin.id) === Number(id))
      setSelected(temp[0].workstation_cabin)
    }
    else{
      setSelected([])
    }
    
  }  


  
return (
  <Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} size={props.size} loader={loader}>
  <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}> 
     <div style={{minHeight:"400px"}}>
          
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Company Name" sm={3}>
        Company Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
    <select className="form-control"
                  {...register("company", {
                    required: true,
                    // onChange:(event) =>  {setCompanyId(event.target.value);}

                  })}>
 <option value="">      --Select Company--</option>
                  {company.map((item,index) => {
                    return <option key={index} value={item.id}>{item.company_name}</option>
                  })}
    </select>
    
        {errors.company?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Cabin Name" sm={3}>
        Cabin Name <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
    <select className="form-control"
                  {...register("cabin", {
                    required: true,
                    onChange:(event) =>  {selectChange(event.target.value);}

                  })}>
 <option value="">      --Select Cabin--</option>
                  {cabin.map((item,index) => {
                    return <option key={index} value={item.id}>{item.cabin_name} (Consist of {item.no_of_Workstations} WorkStations)</option>
                  })}
    </select>
    
        {errors.cabin?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
    
  {selected.length !== 0 ?
  <div style={{padding:"20px"}}>
  <p>Selected Workstations :</p>
 
   {selected.map((data) =>
    <div className='card__tiles' key={data.id}>
  <Card key={data.id} >
  <CardBody>
    <div style={{display:'flex',justifyContent:'space-between'}}>
  <table cellSpacing="0" cellPadding="6">
                  <tbody>
                  <tr>
                      <td>Workstation No</td>
                      <td> : </td>
                      <td>{data.workstation_no}</td>
                    </tr>
                    <tr>
                      <td>Workstation Name</td>
                      <td> : </td>
                      <td>{data.workstation_name}</td>
                    </tr>
                    </tbody>
                    </table>
                    <table cellSpacing="0" cellPadding="6">
                      <tbody>
                    <tr>
                      <td>Workstation Location</td>
                      <td> : </td>
                      <td>{data.workstation_location}</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td> : </td>
                      <td>{data.description}</td>
                    </tr>
                   
                  </tbody>
                </table>
                </div>
  </CardBody>
  </Card>
  </div>
   )}
 
  
 
  </div>
  : null }
  </div>
  
<ModalFooter>
                          <Button
                              type="submit"
                              
                              className="w-xs waves-effect waves-light me-1"
                              // onClick={() => {
                              //     onSubmit();
                              // }}
                          >
                              {waitingForAxios ? (
                                  <Spinner animation="grow" size="sm" />
                              ) : (
                                  "Submit"
                              )}
                              
                          </Button>
                          <Button
                              type="button"
                              color="danger"
                              className="w-xs waves-effect waves-light me-1"
                              onClick={props.toggle}
                          >
                              Close
                          </Button>


</ModalFooter>
</Form>
      </Modals>
)
}
