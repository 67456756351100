import React from 'react';
import { Container, Card, CardBody,Button,Label } from "reactstrap";
import { useForm } from "react-hook-form";
import "./contactus.css"
import { FaMapMarkerAlt} from "react-icons/fa";
import { MdFeedback } from "react-icons/md";


function ContactUs() {
    const {
        register,
        
        formState: { errors },
      } = useForm();
   
    return (
        <React.Fragment>
            <div style={{margin:"20px 8px 8px 10px"}}>
                <Container fluid>

                  
                    
                        
                        <Card style={{ minHeight: "250px",overflow:'hidden'  }}>
                  <CardBody

                  >
                     <h1 className="text-center" style={{marginLeft:"-20px"}}>Contact Us</h1>
                    {/* <div className='details_card'> */}
                      
                        {/* <div className='details_card_child '> */}
                          <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",justifyContent:"space-around",alignItems:"stretch",alignContent:"stretch",marginTop:"60px",padding:'40px'}}>
                          <div style={{display:"inline", fontSize:"17px"}}>
                        

                                                        <p style={{marginTop:"10px",marginBottom:"10px"}}><FaMapMarkerAlt />  Company Address</p>
                                                        <table cellPadding="11" style={{width:"100%"}}>
                                                          <tbody>
                                                        <tr>
                                                        <td>Company Name </td>
                                                        <td>The Workbook </td>
                                                        </tr>
                                                        <tr>
                                                        <td>Office Address </td>
                                                        <td>G 1001, Ground,HiLITE City,HiLITE Business Park<br/> Thondayad bypass, Poovangal,<br /> Kozhikode, Kerala 673014 </td>
                                                        </tr>
                                                        <tr>
                                                        <td>Contact Number </td>
                                                        <td> +91 7736 37 77 50 </td>
                                                       
                                                        </tr>
                                                        <tr>
                                                        <td>Email Address </td>
                                                        <td><a href = "mailto:hello@workbook.com">hello@workbook.com</a> </td>
                                                        </tr>
                                                        <tr>
                                                        <td>Website </td>
                                                        <td><a href="https://workbook.ooo" target="_blank" rel="noreferrer noopener"> www.workbook.ooo
                                                        </a> </td>
                                                        </tr>
                                                        
                                                       
                                                        <tr>
                                                        <td>Country </td>
                                                        
                                                        <td>India</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    
                       
                       
                        </div>
                        <div style={{border:"solid 1px",color:"grey",height:"500px",position:"relative",right:"3%",marginTop:"-25px"}} className="cd"></div>
                        
                        <div style={{position:"relative" ,marginTop:"60px", top:"50%"}} className="formstyle">
                        <p style={{marginTop:"10px",marginBottom:"30px",marginLeft:"-30px",fontSize:"18px"}} className="da"><MdFeedback style={{fontSize:"20px"}}/>  Feedback</p>
                                                <form
                              className="form-horizontal"
                              
                            >
                              {/* register your input into the hook by invoking the "register" function */}
                              <div>
                                <Label htmlFor="username">Name</Label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Name"
                                  type="password"
                                  {...register("name", {
                                    required: true,
                                  })}
                                />
                                {errors.name && (
                                  <span
                                    style={{
                                      color: "#bf3d48",
                                    }}
                                  >
                                    Please provide your Name
                                  </span>
                                )}
                              </div>

                              <div>
                                
                                <Label htmlFor="email">Email</Label>
                                <input
                                  className="form-control"
                                  size="50"
                                  placeholder="Enter Email"
                                  {...register("email", {
                                    required: true,
                                    pattern:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  })}
                                />

                                {errors.email?.type === "required" && (
                                  <span
                                    style={{
                                      color: "#bf3d48",
                                    }}
                                  >
                                    This field is required
                                  </span>
                                )}
                                {errors.email?.type === "pattern" && (
                                  <span
                                    style={{
                                      color: "#bf3d48",
                                    }}
                                  >
                                    Please provide a valid email address
                                  </span>
                                )}
                              </div>
                              <div>
                                <Label htmlFor="feedback">Feedback</Label>
                                <textarea
          type="text"
          placeholder="Write a comment..."
          className="form-control"
          name="content"
          {...register("email", {
            required: true,
            pattern:
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          })}
        />
                                {errors.password && (
                                  <span
                                    style={{
                                      color: "#bf3d48",
                                    }}
                                  >
                                    Please provide your password
                                  </span>
                                )}
                              </div>

                              <div className="mt-4 text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  {/* {waitingForAxis ? (
                                    <Spinner animation="grow" size="sm" />
                                  ) : (
                                    "Log In"
                                  )} */}Submit
                                </Button>
                                {/* <p style={{ color: "red" }}>{responseError}</p> */}
                              </div>
                              
                            </form>
                            </div>

                        </div>
                       
                        
                      
                      
                        <div style={{display:"flex",marginBottom:"20px",marginTop:"30px", marginRight:"10px"}}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d70427.5814491217!2d76.95828503143494!3d8.543103296534545!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65b13a510c4c5%3A0xe2318248c75f213!2sThe%20Workbook!5e0!3m2!1sen!2sus!4v1669804622549!5m2!1sen!2sus" width="1850px" height="800px" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='map' />                                      </div> 
                                      </CardBody>
                                      </Card>
                                      
                                      
                                      </Container>
                                      
                                      </div>
                                      
                                      </React.Fragment>
                                      
    );
}

export default ContactUs

