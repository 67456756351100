// _____for usermanagment_____
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subscriber: null,
    subscriberStaff: null,
    subscriberWorkStation : null,
    subscriberDetails:null,
    subscriberSubscription:null,
};

const SubscriberSlice = createSlice({
    name: 'subscriber',
    initialState,
    reducers:{
        AddSubscriber(state,action) {
            state.subscriber=action.payload
        },
        AddNewSubscriber(state,action){
            state.subscriber=[...state.subscriber,action.payload]
        },
        EditSubscriber(state,action){
            state.subscriber=state.subscriber.map((data) =>
                data.id === action.payload.id ? action.payload : data
            )
        },
        ActivateDeactivateBlockSubscriber(state,action){
            state.subscriber=state.subscriber.map((data) =>
                data.id === action.payload.id ? {...data,status:action.payload.status} : data
            )
        },
        DeleteSubscriber(state,action){
            state.subscriber=state.subscriber.filter((data)=>
                data.id !== action.payload
            )
        },
        AddSubscriberStaff(state,action) {
            state.subscriberStaff=action.payload
        },
        AddNewSubscriberStaff(state,action){
            state.subscriberStaff=[...state.subscriberStaff,action.payload]
        },
        EditSubscriberStaff(state,action){
            state.subscriberStaff=state.subscriberStaff.map((data) =>
                data.id === action.payload.id ? action.payload : data
            )
        },
        DeleteSubscriberStaff(state,action){
            state.subscriberStaff=state.subscriberStaff.filter((data)=>
                data.id !== action.payload
            )
        },
        //for disallocating rfidcard assigned to an staff in company login
        DisallocateRfidCard(state,action){
            state.subscriberStaff=state.subscriberStaff.map((data) =>
                data.id === action.payload.id ? {...data,rfid_staff:null} : data
            )
        },

        AddSubscriberWorkStation(state,action) {
            state.subscriberWorkStation=action.payload
        },
        AddNewSubscriberWorkStation(state,action){
            const temp=action.payload
            state.subscriberWorkStation=[...state.subscriberWorkStation,...temp]
        },
        EditSubscriberWorkStation(state,action){
            state.subscriberWorkStation=state.subscriberWorkStation.map((data) =>
                data.id === action.payload.id ? action.payload : data
            )
        },
        UpdateSubscriberWorkStation(state,action){
            const data=action.payload
            const temp=data.map((data) => data.workstation.id)
            state.subscriberWorkStation=state.subscriberWorkStation.map(data => temp.includes(data.id) ? {...data,status:'Subscribed'} : data)
            
        },
       
        DeleteSubscriberWorkStation(state,action){
            state.subscriberWorkStation=state.subscriberWorkStation.filter((data)=>
                data.id !== action.payload
            )
        },
        //reducer functions for manipulating data inside subscriberdetail page
        AddSubscriberDetails(state,action) {
            state.subscriberDetails=action.payload
        },
        EditSubscriberDetails(state,action){
            state.subscriberDetails={...state.subscriberDetails,workstation_company:action.payload}
        },

        //for handling subscription plans assigned to an company
        AddSubscription(state,action) {
            state.subscriberSubscription=action.payload
        },
        AddNewSubscription(state,action){
            const temp=action.payload
            state.subscriberSubscription=[...state.subscriberSubscription,...temp]
        },
        EditSubscription(state,action){
            state.subscriberSubscription=state.subscriberSubscription.map((data) =>
                data.id === action.payload.id ? action.payload : data
            )
        },
        DeleteSubscription(state,action){
            state.subscriberSubscription=state.subscriberSubscription.filter((data)=>
                data.id !== action.payload
            )
        },
        EndSubscriberSubscription(state,action){
            state.subscriberSubscription=state.subscriberSubscription.map((data)=>
         data.status==="Active"?{...data,status:"Cancelled",subscription_end:action.payload.end_date}:data
            )
           
        },

    }
})

export const {  AddSubscriber , AddNewSubscriber , EditSubscriber , DeleteSubscriber ,AddSubscriberDetails,UpdateSubscriberWorkStation,
    AddSubscription,AddNewSubscription, EditSubscription, DeleteSubscription,ActivateDeactivateBlockSubscriber,
    AddSubscriberStaff , AddNewSubscriberStaff , EditSubscriberStaff , DeleteSubscriberStaff ,EditSubscriberDetails,
    DeleteSubscriberWorkStation , EditSubscriberWorkStation , AddNewSubscriberWorkStation  ,AddSubscriberWorkStation , DisallocateRfidCard, EndSubscriberSubscription
} = SubscriberSlice.actions;
export default SubscriberSlice.reducer;