import React, { useEffect, useState } from 'react'
import Modals from '../../../../../../components/Modal'
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, ModalFooter, Spinner } from 'reactstrap';
// import { authAxios } from '../../../../axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorAlert, SuccessAlert ,Toastr} from '../../../../../../helpers/Alert';
import Error from '../../../../../../helpers/Error';
import axios from 'axios';
import { MultiSelect } from 'react-multi-select-component';
import {Card,CardBody}from 'reactstrap'
import { AddNewSubscription, UpdateSubscriberWorkStation } from '../../../../../../store/subscriber';




function AssignSubscription(props) {
    const [options,setOptions] = useState([])
    const [loader,setLoader] = useState(true)
    const [waitingForAxios,setWaitingForAxios] = useState(false)
    const [ids,setIDs] = useState([])
    const [subscription,setSubscription] = useState([])
    const dispatch = useDispatch();
    const workstation=useSelector((state)=>state.SubscriberSlice.subscriberWorkStation)
    const {
      register,
      handleSubmit,
     
      formState: { errors },
    } = useForm();
  
    useEffect(() => {
  
      const url='/workspace_app/subscription_plan'
      axios.get(url)
      .then((response) => {
        setSubscription(response.data)
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
      })
    }, [])


    useEffect(() => {
      if(workstation !== null){
        const temp=[]
        workstation.map((data) =>{
          if(data.status === 'Allocated'){
            temp.push({...data,value:data.id,label:`${data.workstation_name} (${data.workstation_no})`})

          }
          return (temp)

        }
        )
        setOptions(temp)

      }
     
    }, [workstation])
    
  
  
    const onSubmit = (data) =>{
        if(ids.length !==0){
         
          // let formData = new FormData(); //formdata object
          // formData.append("company",props.id );
          // formData.append("workstations", [ids]);
          // formData.append("subscription", data.subscription);
          // formData.append("discount", data.discount);
          // formData.append("subscription_start", data.subscription_start);


          
        setWaitingForAxios(true)
  
        const url='/workspace_app/subscriber_detail'
  
        axios.post(url,{...data,company:props.id,workstations:ids})
        .then((response) =>{
            dispatch(AddNewSubscription(response.data))
            dispatch(UpdateSubscriberWorkStation(response.data))
            setWaitingForAxios(false)
            SuccessAlert("Subscription Assigned Successfully")
            props.toggle()
  
        })
        .catch((error) =>{
          console.log(error)
          if(error?.response?.data[0]){
            ErrorAlert(error?.response?.data[0])
            }
            else{
              const error_msg=Error(error)
              ErrorAlert(error_msg)
            }
            setWaitingForAxios(false)
            // if(error.response.data){
            //   ErrorAlert(error.response.data[0])
            // }
            // else{
             
            // }
           
        })
    
        }
  
  
     
      else{
        Toastr("warning",'Please Select An WorkStation For Assigning')
      }
  
    }
    
    // useEffect(() => {
    //   console.log(new Date().toISOString().split("T")[0])
    // }, [])
    
    const [selected, setSelected] = useState([]);
  
    // useEffect(() => {
    //   console.log(companyId)
    // }, [companyId])
    
  
  
    const valueRenderer = (selected) => {
      if (!selected.length) {
        return "Select Workstation";
      }
  
      return selected.length === 1
        ? `${selected[0].label} `
        : selected.map(({ label }) =>  label + ',');
    };
  
    const selectChange = (data) =>{
      
      const temp=data.map((data) => data.value)
      setIDs(temp)
      setSelected(data)
    }  

    useEffect(() => {
      // console.log(ids)
    }, [ids])
    
  
  
    
  return (
    <Modals isOpen={props.isOpen} toggle={props.toggle}  title={props.title} size={props.size} loader={loader}>
       <Form style={{ margin: "30px" }} onSubmit={handleSubmit(onSubmit)}>
       <div style={{minHeight:"400px"}}>
   <div style={{marginBottom:"10px"}}>
       <MultiSelect
        options={options}
        value={selected}
        onChange={selectChange}
        labelledBy="Select Workstation"
        hasSelectAll={true}
        valueRenderer={valueRenderer}
        
      /></div>
            
  <FormGroup row style={{ marginBottom: "10px" }}>
      <Label for="ubscription Plan" sm={3}>
          Subscription Plan <span style={{color:'red'}}>*</span>
      </Label>
      <Col sm={9}>
      <select className="form-control"
                    {...register("subscription", {
                      required: true,
                     
  
                    })}>
   <option value="">      --Select Plan--</option>
                    {subscription.map((item,index) => {
                      return <option key={index} value={item.id}>{item.plan_name}</option>
                    })}
      </select>
      
          {errors.subscription?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
          )}
      </Col> 
   </FormGroup>
   
   <FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Start Date" sm={3}>
        Start Date <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Start Date"
            type="date"
            format="dd-mm-yyyy"
            // max={new Date().toISOString().split("T")[0]}
            className="form-control"
            {...register("subscription_start", {
                required: true,
            })}
        />
        {errors.subscription_start?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="End Date" sm={3}>
        End Date <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="End Date"
            type="date"
            min={new Date().toISOString().split("T")[0]}
            className="form-control"
            {...register("subscription_end", {
                required: true,
            })}
        />
        {errors.subscription_end?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
<FormGroup row style={{ marginBottom: "10px" }}>
    <Label for="Discount" sm={3}>
        Discount <span style={{color:'red'}}>*</span>
    </Label>
    <Col sm={9}>
        <input
            placeholder="Discount"
            type="number"
            min={0}
            className="form-control"
            defaultValue={0}
            onWheel={(e) => e.target.blur()}
            onKeyPress={(e) => {
              if (e.key === "e" || e.key === "-" ) {
                e.preventDefault();
              }
            }}
            {...register("discount", {
                required: true,
            })}
        />
        {errors.discount?.type === "required" && (
            <span style={{ color: "red" }}>*This field is required</span>
        )}
    </Col>
</FormGroup>
  
      
     
    
    {selected.length !== 0 ?
    <div style={{padding:"20px"}}>
    <p>Selected Workstations : </p>
   
     {selected.map((data) =>
      <div className='card__tiles' key={data.id}>
    <Card key={data.id} >
    <CardBody>
      <div style={{display:'flex',justifyContent:'space-between'}}>
    <table cellSpacing="0" cellPadding="6">
                    <tbody>
                    <tr>
                        <td>Workstation No</td>
                        <td> : </td>
                        <td>{data.workstation_no}</td>
                      </tr>
                      <tr>
                        <td>Workstation Name</td>
                        <td> : </td>
                        <td>{data.label}</td>
                      </tr>
                      </tbody>
                      </table>
                      <table cellSpacing="0" cellPadding="6">
                        <tbody>
                      <tr>
                        <td>Workstation Location</td>
                        <td> : </td>
                        <td>{data.workstation_location}</td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td> : </td>
                        <td>{data.description}</td>
                      </tr>
                     
                    </tbody>
                  </table>
                  </div>
    </CardBody>
    </Card>
    </div>
     )}
   
    
   
    </div>
    : null }
    </div>
    
  <ModalFooter>
                            <Button
                                type="submit"
                                
                                className="w-xs waves-effect waves-light me-1"
                                // onClick={() => {
                                //     onSubmit();
                                // }}
                            >
                                {waitingForAxios ? (
                                    <Spinner animation="grow" size="sm" />
                                ) : (
                                    "Submit"
                                )}
                                
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-xs waves-effect waves-light me-1"
                                onClick={props.toggle}
                            >
                                Close
                            </Button>
  
  
  </ModalFooter>
  </Form>
        </Modals>
  )
  }
  
  
  export default AssignSubscription