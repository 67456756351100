import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container } from 'reactstrap'
import Swal from 'sweetalert2';
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import { APP_NAME } from '../../../../helpers/Constants';
import { Edit,FilterList } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../helpers/MaterialIcons';
import { useDispatch, useSelector } from 'react-redux';
import { AddRoom, DeleteRoom, EditRoom } from '../../../../store/masterdata';
import axios from 'axios';
import AddCabin from './AddCabin';
import EditCabin from './EditCabin';

import LockIcon from '@material-ui/icons/Lock';
import Unlock from "@material-ui/icons/LockOpen"
import Error from '../../../../helpers/Error';
import { ErrorAlert, SuccessAlert } from '../../../../helpers/Alert';

export default function Cabin () {
    const [data,setData] = useState([])
    const [modalShow,setModalShow] = useState(false)
    const [itemToBeEdited,setItemToBeEdited] = useState(null)
    const [editModalShow,setEditModalShow] = useState(false)
    const [loader,setLoader] = useState(true)
    const rooms=useSelector((state) => state.MasterSlice.rooms)
    const profile = useSelector((state) => state.authSlice.profile)
    const dispatch = useDispatch()
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [dashboardState, setDashboardState] = useState({
        breadcrumbItems: [
          { title: APP_NAME, link: "/" },
          { title: "Cabin", link: "#" },
        ],
      });

      const addModalToggle = () => {
        setModalShow(!modalShow)
      }
      const editModalToggle = () => {
        setEditModalShow(!editModalShow)
      }
  
    useEffect(() => {

      
      const abortController=new AbortController();
      const url='/workspace_app/cabin'
      axios.get(url)
      .then((response) => {
        dispatch(AddRoom(response.data))
        setLoader(false)
      })

      // dispatch(AddRoom([{id:1,operator_id:8765,emp_id:1001,extn_no:593,first_name:"WRK002",last_name:" Center Circle,Entrance",designation:"Officer",email:"david@email.com",phone_no:"Consist of 2 WorkStations",User_type:"Admin",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"},{id:2,operator_id:2678,emp_id:1002,extn_no:574,first_name:"WRK0012",last_name:"Near Entrance,BackSide",designation:"Accountant",email:"Akhil@gmail.com",phone_no:"Consist of 2 WorkStations",User_type:"Admin",address:"789 Fort Myres,Monreo Avenue,Florida",status:"Active"},{id:3,operator_id:3567,emp_id:1003,extn_no:567,first_name:"WRK001",last_name:"BackSide,Entrance,Center Circle",designation:"Chief Officer",email:"Sharon@email.com",phone_no:"Consist of 3 WorkStations",User_type:"User",address:"789 Monreo Avenue,Fort Myres,Florida",status:"Active"}]))
      
        setDashboardState(
            {
              breadcrumbItems: [
                { title: APP_NAME, link: "/" },
                { title: "Cabin", link: "#" },
              ],
            }  
          )
          return (() => {
            abortController.abort();
            setData([]); 
          })
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
    if(rooms !== null){
      const tempData = rooms.map((data) => ({
        ...data
      }))
      setData(tempData)
    }
    }, [rooms])
    

    const columns =
  [
   
    
    { title: "Cabin No", field: "cabin_no"},
    { title: "Cabin Name", field: "cabin_name" },
    // { title: "WorkStations", field: "last_name"},
    {title:'Description' , field:'description'},
    {title:'Status' , field:'status'},

    // { title: "Contract Start", field: "contract_start"},
    // { title: "Contract End", field: "contract_end"},
  ]

    // for handling update delete activate etc....
    const UpdateHandler = (id,method) =>{

      if(method==="Deleted"){
      // console.log(id)
      const url=`/workspace_app/cabin/${id}`
      axios.patch(url,{status:method})
            .then((response) =>{
              SuccessAlert("Cabin Deleted Successfully!")
              dispatch(DeleteRoom(id))
            })
            .catch((error) =>{
              const err_msg=Error(error)
              ErrorAlert(err_msg)
             
            })
          }
          else{
            

            const url=`/workspace_app/cabin/${id}`
            axios.patch(url,{status:method})
            .then((response) =>{
              SuccessAlert("Cabin Updated Successfully!")
              dispatch(EditRoom(response.data))
            })
            .catch((error) =>{
              const err_msg=Error(error)
              ErrorAlert(err_msg)
             
            })
          }
         
          
     }
    

    
    
  return (
    <React.Fragment>

      {modalShow &&
      <AddCabin
      title={'Add Cabin'}
      isOpen={modalShow}
      toggle={addModalToggle}
        />
      }
      {editModalShow &&
      <EditCabin
      title={'Edit Cabin'}
      isOpen={editModalShow}
      toggle={editModalToggle}
      item={itemToBeEdited}
        />
      }
          <div className="page-content">
            <Container fluid>
            <Breadcrumbs
                title="Cabin"
                breadcrumbItems={dashboardState.breadcrumbItems}
              ></Breadcrumbs>
              {profile.user_type === "Admin"?<>
              
              <div
                className="disappearing-button-container-page-top-right" style={{marginRight:"-160px"}}>
                 <Button
                  style={{ marginLeft: "10px",backgroundColor:'#BF9731'}}
                  type="button"
                  color="warning"
                  
                  className="w-xs waves-effect waves-light me-1"
                  
                  onClick={addModalToggle}
                >
                  Add Cabin
                </Button>
                  </div>
                  </>:null}
               <Col xl={24}>
                <Card style={{ minHeight: "525px" }}>
                  <CardBody>
                    <div
                      style={{
                        
                        overflowY: 'auto'
                      }}
                    >
                      <MaterialTable
                        title=" "
                        columns={columns}
                        data={data}
                        isLoading={loader}

                        actions={[
                          {
                            icon: FilterList,
                            tooltip: filterEnabled ? 'Disable Filter' : 'Enable Filter',
                            isFreeAction: true,
                            onClick: (event) => { setFilterEnabled(state => !state) }
                          },

                          
                          // {
                          //   icon: VisibilityOutlinedIcon,
                          //   tooltip: 'View Client',
                          //   onClick: (event, rowData) => navigate(`/clientdetails/${rowData.id}`)
                          // },
                          rowData => ({
                            icon: Edit,
                            tooltip: 'Edit Cabin',
                            onClick: (event, rowData) => {
                              // console.log(rowData)
                              // setEditClientModalShow(true)
                              setItemToBeEdited(rowData)
                              editModalToggle();
                            },
                            hidden:profile.user_type ==="User"|| rowData.status==='Allocated'

                          }),
                          // rowData => ({
                          //   icon: DeleteOutline,
                          //   tooltip: 'Delete Cabin',
                          //   onClick: (event, rowData) => {
                          //     Swal.fire({
                          //       title: 'Are you sure?',
                          //       text: "You won't be able to revert this!",
                          //       icon: 'warning',
                          //       showCancelButton: true,
                          //       confirmButtonColor: '#3085d6',
                          //       cancelButtonColor: '#d33',
                          //       confirmButtonText: 'Yes, delete it!'
                          //     }).then((result) => {
                          //       if (result.isConfirmed) {
                          //         UpdateHandler(rowData.id,"Deleted")
                                
                          //       }
                          //     })
                          //     // setDeleteModal({ id: rowData?.id, toggle: true });
                          //   },
                          //   disabled: rowData.birthYear < 2000
                          // }),
                          rowData => ({
                            icon: Unlock,
                            tooltip: 'Enable Cabin ',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Enable it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,"Active")
                                  // deleteClientData(rowData?.id)

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden:profile.user_type ==="User"|| rowData.status === "Active" || rowData.status==='Allocated'	

                          }),
                          rowData => ({
                            icon: LockIcon,
                            tooltip: 'Disable Cabin',
                            onClick: (event, rowData) => {
                              Swal.fire({
                                title: 'Are you sure?',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, Disable it!'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  UpdateHandler(rowData.id,"Disabled")
                                  // deleteClientData(rowData?.id)

                                
                                }
                              })
                              // setDeleteModal({ id: rowData?.id, toggle: true });
                            },
                            hidden: profile.user_type ==="User"|| rowData.status === "Disabled" 
                          })
                        ]}
                        options={{
                          exportFileName:'Cabins',
                          exportAllData: true,
                          actionsColumnIndex: -1,
                          exportButton: true,
                          filtering: filterEnabled
                        }}
                        icons={tableIcons}
                      />

                    </div>

                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
                
             

  
        </Container>
    </div>
    
    </React.Fragment>
  )
}

