
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    workStations : null,
    rooms : null,
    subscriptionPlans : null,
    
};

const MasterSlice = createSlice({
    name: 'masterdata',
    initialState,
    reducers:{
        AddWorkStation(state,action) {
            state.workStations=action.payload
        },
        AddNewWorkStation(state,action){
            state.workStations=[...state.workStations,action.payload]
        },
        EditWorkStation(state,action){
            state.workStations=state.workStations.map((data) =>
                data.id === action.payload.id ? action.payload : data
            )
        },
        DeleteWorkStation(state,action){
            state.workStations=state.workStations.filter((data)=>
                data.id !== action.payload
            )
        },
        AddRoom(state,action) {
            state.rooms=action.payload
        },
        AddNewRoom(state,action){
            state.rooms=[...state.rooms,action.payload]
        },
        EditRoom(state,action){
            state.rooms=state.rooms.map((data) =>
                data.id === action.payload.id ? action.payload : data
            )
        },
        DeleteRoom(state,action){
            state.rooms=state.rooms.filter((data)=>
                data.id !== action.payload
            )
        },
        AddSubscriptionPlans(state,action) {
            state.subscriptionPlans=action.payload
        },
        AddNewSubscriptionPlans(state,action){
            state.subscriptionPlans=[...state.subscriptionPlans,action.payload]
        },
        EditSubscriptionPlans(state,action){
            state.subscriptionPlans=state.subscriptionPlans.map((data) =>
                data.id === action.payload.id ? action.payload : data
            )
        },
        DeleteSubscriptionPlans(state,action){
            state.subscriptionPlans=state.subscriptionPlans.filter((data)=>
                data.id !== action.payload
            )
        },


    }
})

export const {AddWorkStation , AddNewWorkStation , EditWorkStation , DeleteWorkStation ,
            AddRoom , AddNewRoom , EditRoom , DeleteRoom ,
            AddSubscriptionPlans , AddNewSubscriptionPlans , EditSubscriptionPlans , DeleteSubscriptionPlans} = MasterSlice.actions;
export default MasterSlice.reducer;